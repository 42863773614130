import BaseListingComponent from "./BaseListingComponent";

class MagazineListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "MagazineListingComponent";
        super(element, parentComponent, params);

        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");
        this.page = 1;
        this.category = $('.search-result').attr('data-category');

        this.$el = $(element);

        this.filterValues = {
            campus: '',
            course: ''
        };

        this.$courseInput = $('#course-input');
        this.init();

    }

    onModalConfirm($modal, filterName, filterValue, filterLabel) {
        if (filterValue == '') {
            if (filterName == 'campus') {
                this.filterValues.campus = '';
                this.filterValues.course = '';
            } else {
                this.filterValues.course = '';
            }
        } else if (filterName == 'campus') {
            this.filterCampus = `&campus=${filterValue}`;
            this.filterValues.course = '';
            let $courseTrigger = this.$el.find('#course-input .modal-trigger');
            $courseTrigger.text($courseTrigger.data('defaultText'));
        } else {
            this.filterCourse = `&course=${filterValue}`
        }

        if (filterName == 'campus') {
            this.updateAreaCourses(filterValue);
        }
    }

    updateAreaCourses(filterValue) {
        if (filterValue !== '') {
            this.$courseInput.removeClass('disabled');
            $('#modal_course .item:not(.default-item)').addClass('hidden');
            $(`#modal_course .item[data-area="${filterValue}"]`).removeClass('hidden');
        } else {
            this.$courseInput.addClass('disabled');
        }
    }

    onBeforeEraseFilters() {
        this.$courseInput.addClass('disabled');
        this.filterValues = {
            campus: '',
            course: ''
        };

        this.$el.find('#article').val('');
    }

    createResultItem(item) {
        if (!item.thumbImage) {
            item.thumbImage = '';
        }
        if (!item.intro) {
            item.intro = '';
        }

        let itemUrl = item.url + '?itm_campaign=Maze35_ALL_FDA_220700_other_EN_ALL';
        let urlTag = false;
        if (!!item.urlTag) {
            urlTag = item.urlTag + '?itm_campaign=Maze35_ALL_FDA_220700_other_EN_ALL';
        }

        let cardTag = (item.titleTag != undefined)
            ? (urlTag)
                ? `<a class="titleTag" href="${urlTag}">${item.titleTag}</a>`
                : `<div class="titleTag">${item.titleTag}</div>`
            : '';

        let signature = item.signature != undefined ? item.signature : '';

        return `
            <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                        <div class="search-result-item--corso">
                            <a href="${itemUrl}">
                                <img src="${item.thumbImage}" />
                            </a>
                            <div class="content">
                                ${cardTag}
                                <a href="${itemUrl}">
                                    <div class="title">
                                        ${item.title}
                                    </div>
                                </a>
                                <div class="description">
                                    ${item.intro}
                                </div>
                                <div class="signature">${signature}</div>
                                ${!item.date ? '' : `<div class="date">${item.date}</div>`}
                                <div class="cta-wrapper">
                                    <div class="campus">${item.campus}</div>
                                    <a href="${itemUrl}" class="action btn-mrng">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>
                                </div>
                            </div>
                        </div>
                    </div>`;
    }

    doAjaxCall() {

        let qs = `category=${this.category}&site=${this.siteId}&locale=${this.locale}&page=${this.page}`;

        if (this.filterValues.campus !== '') {
            qs += this.filterCampus;
        }

        if (this.filterValues.course !== '') {
            qs += this.filterCourse;
        }

        if ($('#article').val() !== '') {
            let keywordFilter = $('#article').val();
            qs += `&keyword=${keywordFilter}`;
        }

        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/magazine/article/search?${qs}`, {
            method: 'GET',
        });
    }
}

export default MagazineListingComponent;