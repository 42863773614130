import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class CustomTabComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CustomTabComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$tabIndex = this.$el.find('.tab-index');
        this.$tabWrapper = this.$el.find('.tab-content-wrapper');

        this.init();
    }

    init() {
        this.$el.find('.tab-title').click((e) => {
            this.$tabIndex.find('.tab-title.active').removeClass('active');
            let tabId = $(e.currentTarget).addClass('active').data('id');

            this.$tabWrapper.find('.tab-content.active').removeClass('active');
            this.$tabWrapper.find('.tab-content[data-id="' + tabId + '"]').addClass('active');
        });
        
        if (this.$el.find('.tab-index .tab-title.active').length < 1) {
            this.$el.find('.tab-title:eq(0)').addClass('active');
        }
        this.$el.find('.tab-index .tab-title.active').click();
    }
}

export default CustomTabComponent;