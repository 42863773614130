import {SmartComponent} from "smart-component-js";

class SlidingArrowsComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "SlidingArrowsComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$next = this.$el.find('.next');
        this.$prev = this.$el.find('.prev');

        this.$target = this.$el.parent();
        if (this.$el.data('siblingSelector')) {
            this.$target = this.$el.siblings(this.$el.data('siblingSelector'));
        } else if (this.$el.data('selector')) {
            this.$target = $(this.$el.data('selector'));
        }

        this.init();
    }

    init() {
        if (this.$target[0].scrollWidth <= this.$target.innerWidth()) {
            this.$el.remove();
        } else {
            this.$next.click(() => {
                this.slide(true);
            });
            this.$prev.click(() => {
                this.slide(false);
            });
        }
    }

    slide(next) {
        let offset = ($(window).width() * .75);
        if (!next) {
            offset *= -1;
        }

        this.$target.scrollLeft(this.$target.scrollLeft() + offset);

        this.$prev.toggleClass('disabled', this.$target.scrollLeft() <= 0);
        this.$next.toggleClass('disabled', this.$target.innerWidth() + this.$target.scrollLeft() >= this.$target[0].scrollWidth);
    }
}

export default SlidingArrowsComponent;