import {SmartComponent} from "smart-component-js";
import TrackerManager from "../business/TrackerManager";

/*
            <div
                component="TrackingComponent"
                data-event-action=""
                data-event-category=""
                data-entity-name=""
                data-entity-id=""
            ></div>
*/

/*
            <div
                component="TrackingComponent"
                data-tracking-type="["LOGIN", "LOGOUT", "INSERT", "UPDATE", "DELETE", "COPY", "PAGE_VIEW", "APP_SCREEN_VIEW", "EVENT", "LOG", "ERROR"]"
                data-event-action=""
                data-event-category=""
                data-entity-name=""
                data-entity-id=""
                data-trigger-selector=""
                data-value-selector=""
            ></div>
*/

class TrackingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "TrackingComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }

    init() {
        let trackingType = "PAGE_VIEW";
        let eventAction = null;
        let eventCategory = null;
        let entityName = null;
        let entityId = null;

        if (!!this.$el.data('trackingType')) {
            trackingType = this.$el.data('trackingType');
        }
        if (!!this.$el.data('eventAction')) {
            eventAction = this.$el.data('eventAction');
        }
        if (!!this.$el.data('eventCategory')) {
            eventCategory = this.$el.data('eventCategory');
        }
        if (!!this.$el.data('entityName')) {
            entityName = this.$el.data('entityName');
        }
        if (!!this.$el.data('entityId')) {
            entityId = this.$el.data('entityId');
        }

        let params = {
            eventAction: eventAction,
            eventCategory: eventCategory,
            entityName: entityName,
            entityId: entityId
        };

        if (!!this.$el.data('triggerSelector')) {
            this.trackingTrigger = this.$el.data('triggerSelector');
        }
        if (!!this.$el.data('valueSelector')) {
            this.trackingValue = this.$el.data('valueSelector');
        }
        if (!!this.$el.data('attrSelector')) {
            this.trackingAttr = this.$el.data('attrSelector');
        }

        if (!this.trackingTrigger) {
            TrackerManager.track(trackingType, params);
        } else {
            $(this.trackingTrigger).on('click submit change', () => {
                let entityId = [];

                $(this.trackingValue).each((i, el) => {
                    if (!this.trackingAttr) {
                        entityId.push($(el).val());
                    } else {
                        entityId.push($(el).attr(this.trackingAttr));
                    }
                })


                if (!!entityId && entityId.length > 0) {
                    for (let i in entityId) {
                        if (!!entityId[i]) {
                            params.entityId = entityId[i];
                            TrackerManager.track(trackingType, params);
                        }
                    }
                }
            });
        }
    }
}

export default TrackingComponent;