import {SmartComponent} from "smart-component-js";

class MobileSliderComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CallMeBackInnerComponent";
        super(element, parentComponent, params);
        this.$carousel = $(this.element).find('.owl-carousel');
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.init();
    }

    campusCarousel() {
        this.$carousel.owlCarousel({
            rtl: this.isRtl,
            items: 1,
            loop: true,
            nav: true
        });
    }

    checkCarouselInit() {
        if ($(window).width() <= 768) {
            this.campusCarousel();
        } else {
            this.$carousel.trigger('destroy.owl.carousel');
        }
    }

    init() {
        this.checkCarouselInit();

        $(window).on('resize', ()=> {
            this.checkCarouselInit();
        });
    }
}

export default MobileSliderComponent;