import Cookies from "js-cookie";
import RSVP from "rsvp";

import TrackerManager from "./TrackerManager";

class AiManager {

    constructor() {
        this.statuses = {
            PROCESSING: 'PROCESSING',
            COMPLETED: 'COMPLETED',
            ERROR: 'ERROR'
        };
        this.elements = {};

        this.cookieClusterName = 'kd_cookie_cluster';

        this.defaultEndpoint = '/api/1.0/rec';
        this.cookieEndpoint = '/api/1.0/recommendation/custom/search';
        this.endpoint = '';

        this.forcedClusters = [];

        $(document).ready(() => {
            this.init();
        });
    }

    init() {
        this.elements = {};
        $('[data-ai]').each((i, el) => {
            let $el = $(el);
            let entityName = $el.data('aiEntity');

            if (!!entityName && !$el.data('aiStatus')) {
                if (!this.elements[entityName]) {
                    this.elements[entityName] = [];
                }
                $el.data('aiStatus', this.statuses.PROCESSING);
                this.elements[entityName].push($el);
            }
        });

        this.doCalls();
    }

    doCalls() {
        if (!window.aiPickaxePromise) {
            window.aiPickaxePromise = new RSVP.Promise((resolve, reject) => {
                let likeAVirgin = localStorage.getItem("aiManagerInitialized");
                if (!!likeAVirgin) {
                    resolve(true);
                } else {
                    setTimeout(() => {
                        localStorage.setItem("aiManagerInitialized", 'true');
                        resolve(true);
                    }, 1500);
                }
            });
        }

        window.aiPickaxePromise.then(() => {
            TrackerManager.getClientId().then((clientId) => {
                for (let entityName in this.elements) {

                    let params = this.calculateEnpointAndGetParams(entityName, clientId);

                    $.ajax({
                        url: this.endpoint,
                        type: "POST",
                        dataType: "json",
                        cache: false,
                        data: JSON.stringify(params),
                        contentType: "application/json",
                    }).then((data) => {
                        if (!!data && !!data.body && !!data.body.items) {
                            this.updateElements(data.body.items, entityName);
                        } else {
                            this.updateElements([], entityName);
                        }
                    }, (error) => {
                        this.updateElements([], entityName);
                    });
                }
            });
        });
    }

    updateElements(res, entityName) {
        //aggiorno gli elementi con la risposta della chiamata
        for (let i in res) {
            let record = res[i];
            if (!!this.elements[entityName]) {
                let $el = this.elements[entityName].shift();
                $el.data('aiData', record);
                $el.data('aiStatus', this.statuses.COMPLETED);
                $el.trigger('ai-ready');
            }
        }

        //Se all'interno di this.elements è rimasto qualcosa lancio l'evento di errore, sarà compito del componente specifico decidere il da farsi.
        if (this.elements[entityName].length > 0) {
            for (let j in this.elements[entityName]) {
                let $el = this.elements[entityName][j];
                $el.data('aiStatus', this.statuses.ERROR);
                $el.trigger('ai-error');
            }
        }
    }

    calculateEnpointAndGetParams(entityName, clientId) {
        this.forcedClusters = [];

        let href = document.location.href;
        if (href.indexOf('utm_campaign=') > 0) {
            href = href.split('utm_campaign=')[1];
            href = href.split('&')[0];

            if (href.indexOf('KCL_') >= 0) {
                href = href.split('KCL_')[1];

                Cookies.set(this.cookieClusterName, href);
            }
        }

        let params = {};
        if (Cookies.get(this.cookieClusterName)) {
            this.endpoint = this.cookieEndpoint;
            this.forcedClusters = Cookies.get(this.cookieClusterName).split('_');

            params = {
                "idSite": KD_COMMON_CONSTANTS.SITE,
                "locale": KD_COMMON_CONSTANTS.LOCALE,
                "clusters": this.forcedClusters,
                "entityName": (entityName.indexOf(',') >= 0 ? entityName.split(',') : [entityName]),
                "pageSize": this.elements[entityName].length
            };
        } else {
            this.endpoint = this.defaultEndpoint;

            let siteLocale = "site=" + KD_COMMON_CONSTANTS.SITE + ",locale=" + KD_COMMON_CONSTANTS.LOCALE;

            params = {
                "itemsNumber": this.elements[entityName].length,
                "userContext": {
                    "sites": siteLocale
                },
                "userKey": clientId,
                "entityNamesFilter": (entityName.indexOf(',') >= 0 ? entityName.split(',') : [entityName])
            };
        }

        return params;
    }
}

export default AiManager;
