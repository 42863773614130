import {SmartComponent} from "smart-component-js";

class GalleryListingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "GalleryListingComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.$resContainer = this.$el.find('.search-result-container');
        this.$loadMore = this.$el.find('.load-more');

        this.init();
    }

    init() {
        if (this.$loadMore.length > 0) {
            this.$loadMore.click(() => {
                let prevPosition = $('html, body').scrollTop();
                this.$resContainer.find('.search-result-item--wrapper:hidden:lt(9)').show();
                $('html, body').scrollTop(prevPosition);
                if (this.$resContainer.find('.search-result-item--wrapper:hidden').length < 1) {
                    this.$loadMore.hide();
                }
            });
        }
    }
}

export default GalleryListingComponent;