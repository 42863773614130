import BaseListingComponent from "./BaseListingComponent";

import RSVP from "rsvp";
import $ from "jquery";

class ScholarshipListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/mentor/list';

        this.$el = $(element);
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.localeMap = {
            'it_IT': 'ita',
            'en_GB': 'eng',
            'fr_FR': 'fra',
            'es_ES': 'esp',
            'pt_BR': 'prt',
            'zh_CN': 'chi'
        }

        this.serviceLocale = this.localeMap[this.locale];

        this.page = 1;
        this.allScholarships = {};
        this.campusValues = {};
        this.coursesLabels = {};

        //this.filterMap[intake][campus][degree][course]
        this.filterMap = {};
        this.filterValues = {
            intake: '',
            campus: '',
            degree: '',
            course: ''
        };

        this.createFilters();

        this.init();
    }

    createFilters() {
        if (!!scholarships) {
            let scholarshipArray = JSON.parse(scholarships);
            for (let s in scholarshipArray) {
                let scholarship = scholarshipArray[s];

                if (scholarship.configurationMap && scholarship.configurationMap.details) {
                    let details = scholarship.configurationMap.details;
                    this.allScholarships[scholarship.externalId] = scholarship;
                    this.allScholarships[scholarship.externalId].relatedCourses = [];

                    if (details.courses) {
                        for (let c in details.courses) {
                            let course = details.courses[c];
                            if (!!course.intake && !!course['school_id']) {
                                let intake = course.intake;
                                let campus = course['school_id'];
                                let degree = course['groupname_name_' + this.serviceLocale];

                                if (!this.filterMap[intake]) {
                                    this.filterMap[intake] = {};
                                }
                                if (!this.filterMap[intake][campus]) {
                                    this.filterMap[intake][campus] = {};
                                }
                                if (!this.filterMap[intake][campus][degree]) {
                                    this.filterMap[intake][campus][degree] = [];
                                }

                                this.filterMap[intake][campus][degree].push(course.courseid);
                                this.coursesLabels[course.courseid] = course.title;
                                this.allScholarships[scholarship.externalId].relatedCourses.push(course.courseid);
                            }
                        }
                    }
                    if (details.schools) {
                        for (let c in details.schools) {
                            let campus = details.schools[c];
                            let cIds = campus['branch_id_arr'].split(',');
                            for (let i in cIds) {
                                this.campusValues[cIds[i]] = campus.name;
                                if (cIds.length > 1 && i > 0) {
                                    // Siccome esistono più campus con lo stesso id, per semplificare uso sempre il
                                    // primo id di ogni campus in modo da evitare duplicati nella lista dei filtri.
                                    for (let intake in this.filterMap) {
                                        if (!!this.filterMap[intake][cIds[i]]) {
                                            for (let degree in this.filterMap[intake][cIds[i]]) {
                                                //Itero ogni degree del campus da eliminare, copiandolo nei degree dei campus da mantenere
                                                if (!this.filterMap[intake][cIds[0]]) {
                                                    this.filterMap[intake][cIds[0]] = {};
                                                }
                                                if (!this.filterMap[intake][cIds[0]][degree]) {
                                                    this.filterMap[intake][cIds[0]][degree] = [];
                                                }
                                                this.filterMap[intake][cIds[0]][degree] = this.filterMap[intake][cIds[0]][degree].concat(this.filterMap[intake][cIds[i]][degree])
                                            }
                                            delete this.filterMap[intake][cIds[i]];
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        this.placeIntakeFilters();
    }

    onModalConfirm($modal, filterName, filterValue, filterLabel) {
        if (filterName == 'intake') {
            this.filterValues.campus = '';
            this.updateIntakeFilters();
        }
        if (filterName == 'intake' || filterName == 'campus') {
            this.filterValues.degree = '';
            this.updateCampusFilters();
        }
        if (filterName == 'intake' || filterName == 'campus' || filterName == 'degree') {
            this.filterValues.course = '';
            this.updateDegreeFilters();
        }
    }

    placeIntakeFilters() {
        let $fakeOptionWrp = this.$el.find('#modal_intake .hoax-input-filter-options');
        let $optionWrp = this.$el.find('#modal_intake select');

        let opts = this.filterMap;
        for (let i in opts) {
            $fakeOptionWrp.append(`<div class="item" data-value="${i}">${i}</div>`);
            $optionWrp.append(`<option value="${i}">${i}</option>`);
        }

        this.$el.find('#intake-input').removeClass('disabled');
    }

    updateIntakeFilters() {
        let $trigger = this.$el.find('#campus-input .modal-trigger');
        $trigger.text($trigger.data('defaultText'));
        if (!this.filterValues.intake || !this.filterMap[this.filterValues.intake]) {
            this.$el.find('#campus-input').addClass('disabled');
        } else {
            let $fakeOptionWrp = this.$el.find('#modal_campus .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_campus select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            let opts = this.filterMap[this.filterValues.intake];
            for (let i in opts) {
                $fakeOptionWrp.append(`<div class="item" data-value="${i}">${this.campusValues[i]}</div>`);
                $optionWrp.append(`<option value="${i}">${this.campusValues[i]}</option>`);
            }

            this.$el.find('#campus-input').removeClass('disabled');
        }
    }

    updateCampusFilters() {
        let $trigger = this.$el.find('#degree-input .modal-trigger');
        $trigger.text($trigger.data('defaultText'));
        if (!this.filterValues.campus || !this.filterMap[this.filterValues.intake][this.filterValues.campus]) {
            this.$el.find('#degree-input').addClass('disabled');
        } else {
            let $fakeOptionWrp = this.$el.find('#modal_degree .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_degree select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            let opts = this.filterMap[this.filterValues.intake][this.filterValues.campus];
            for (let i in opts) {
                $fakeOptionWrp.append(`<div class="item" data-value="${i}">${i}</div>`);
                $optionWrp.append(`<option value="${i}">${i}</option>`);
            }

            this.$el.find('#degree-input').removeClass('disabled');
        }
    }

    updateDegreeFilters() {
        let $trigger = this.$el.find('#course-input .modal-trigger');
        $trigger.text($trigger.data('defaultText'));
        if (!this.filterValues.degree || !this.filterMap[this.filterValues.intake][this.filterValues.campus][this.filterValues.degree]) {
            this.$el.find('#course-input').addClass('disabled');
        } else {
            let $fakeOptionWrp = this.$el.find('#modal_course .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_course select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            let opts = this.filterMap[this.filterValues.intake][this.filterValues.campus][this.filterValues.degree];
            for (let i in opts) {
                $fakeOptionWrp.append(`<div class="item" data-value="${opts[i]}">${this.coursesLabels[opts[i]]}</div>`);
                $optionWrp.append(`<option value="${opts[i]}">${this.coursesLabels[opts[i]]}</option>`);
            }

            this.$el.find('#course-input').removeClass('disabled');
        }
    }

    doAjaxCall() {
        return new RSVP.Promise((resolve, reject) => {
            console.log(this.filterValues);

            let scholarships = this.extractScholarshipFromCourses(this.getFilteredCourses());

            resolve({body: {result: scholarships}});
        });
    }

    getFilteredCourses() {
        let filteredCourses = [];

        for (let i in this.filterMap) {
            //Se non ho scelto un intake vanno bene tutti
            if (!this.filterValues.intake || i == this.filterValues.intake) {
                for (let c in this.filterMap[i]) {
                    //Se non ho scelto un campus vanno bene tutti
                    if (!this.filterValues.campus || c == this.filterValues.campus) {
                        for (let d in this.filterMap[i][c]) {
                            //Se non ho scelto un degree vanno bene tutti
                            if (!this.filterValues.degree || d == this.filterValues.degree) {
                                if (!!this.filterValues.course) {
                                    //Quando arrivo a questo livello ho tutti i filtri popolati quindi ho 1 solo corso come risultato.
                                    filteredCourses = filteredCourses.concat(this.filterMap[i][c][d].filter(co => co == this.filterValues.course));
                                } else {
                                    //Quando arrivo qui aggiungo tutti i corsi per questa combinazione di intake/campus/degree al risultato.
                                    //Se non ho scelto filtri aggiungerò quindi tutti i corsi di tutti i degree di tutti i campus di tutti gli intake.
                                    filteredCourses = filteredCourses.concat(this.filterMap[i][c][d]);
                                }
                            }
                        }
                    }
                }
            }
        }

        return filteredCourses;
    }

    extractScholarshipFromCourses(coursesIds) {
        let result = {};

        if (!this.filterValues.intake) {
            result = this.allScholarships;
        } else {
            for (let id in coursesIds) {
                for (let s in this.allScholarships) {
                    if (this.allScholarships[s].relatedCourses.indexOf(coursesIds[id]) >= 0) {
                        result[this.allScholarships[s].id] = (this.allScholarships[s]);
                    }
                }
                // result = result.concat(this.allScholarships.filter(s => s.relatedCourses.indexOf(coursesIds[id]) >= 0));
            }
        }

        return Object.values(result).sort(function (a, b) {
            return a.position - b.position;
        });
    }

    createResultItem(item) {
        item.configurationMap.details.schools.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        let schools = this.printTagElements(item.configurationMap.details.schools.map(s => s.sfname));
        let brandItem = item.brand ? `<img src="${item.brand.logo}" class="brand_img">` : "";
        return `
            <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                <div class="search-result-item--corso">
                    <a href="${item.url}"><img src="${item.desktopImage}" /></a>
                    <div class="content">
                        <div class="item_info">
                            <div class="type">
                                ${item.type}
                            </div>
                            <div class="brand">
                                ${brandItem}
                            </div>
                        </div>
                        <div class="title">
                            <a href="${item.url}">${item.title}</a>
                        </div>
                        <div class="description">
                            ${item.intro}
                        </div>
                        <div class="note">
                            ${item.courseTitle ? `<strong>${item.courseTitle}</strong>` : ''}
                            ${item.courseText ? `${item.courseText}` : ''}
                        </div>
                        ${schools}
                        <div class="expire">
                            <div class="expire_title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.expire"]}</div>
                            <div class="expire_date">${item.expirationDate}</div>
                        </div>
                        <a href="${item.url}" class="action btn-mrng" target="_self">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.candidate"]}</a>
                    </div>
                </div>
            </div>
        `;
    }

    printTagElements(labels) {
        let html = '';
        if (labels && labels.length > 0) {
            let unique = [...new Set(labels.map(l => l.toUpperCase().replace(/DESIGN/gi, '').replace(/FASHION/gi, '').trim()))]
            html += `<div class="tags">`;
            for (let i in unique) {
                html += `<div class="tag">${unique[i]}</div>`;
            }
            html += `</div>`;
        }

        return html;
    }
}

export default ScholarshipListingComponent;