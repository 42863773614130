import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class MentorListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "MentorListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/mentor/list';

        this.$el = $(element);
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.page = 1;
        this.filterValues = {
            macroarea: '',
            tag: ''
        };

        this.init();
    }

    createResultItem(item) {
        let nameSurname = '';
        if (!!item.name) {
            nameSurname += item.name + ' ';
        }
        if (!!item.surname) {
            nameSurname += item.surname;
        }
        let tag = item.tag;
        if (!!tag && tag.length > 40) {
            tag = tag.substr(0, 40) + '...';
        }

        return `
                <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                    <div class="mentor-result-item">
                        <div class="text-wrapper">
                            ${!nameSurname ? '' : `<div class="title">${nameSurname}</div>`}
                            ${!item.jobTitle ? '' : `<div class="subtitle">${item.jobTitle}</div>`}
                            ${!tag ? '' : `<div class="type">${tag}</div>`}
                        </div>
                        <a href="${item.url}" class="bordered-cta black">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>
                        ${!item.thumbImage ? '<img src="/fe-web/img/marangoni/placeholder/newsthumb.png" class="news-item-image"/>' : `<img src="${item.thumbImage}" class="news-item-image"/>`}
                    </div>
                </div>
        `;
    }
}

export default MentorListingComponent;