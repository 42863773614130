class PopupManager {
    constructor() {
    }

    showPopup(template, onClose) {
        this.removePopup();
        $('body').append(this.getTemplateHtml(template));

        $('#gge-popup [data-popup-close]').click(() => {
            if (typeof onClose == 'function') {
                onClose();
            } else if (onClose == false) {
                return false;
            } else {
                this.removePopup();
            }
        });
    }

    removePopup() {
        if ($('#gge-popup').length > 0) {
            $('#gge-popup').remove();
        }
    }

    getTemplateHtml(template) {
        let html = `
<div class="popup-modal" id="gge-popup" data-template="${template}">
    <div class="popup-body">
        <div class="close" data-popup-close></div>
        `;

        if ("optin" == template) {
            html += `
            
        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optin.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optin.subtitle"]}</div>        
        <button class="btn-mrng" data-popup-optin="">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optin.btn"]}</button>
        
            `;
        } else if ("optinemail" == template) {
            html += `
            
        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optinemail.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optinemail.subtitle"]}</div>
        <div class="form-wrp" style="margin: 25px 0 20px;">
            <div class="input input-12 no-margin">
                <label for="username">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.username"]}</label>
                <input type="text" name="username" class="required" aria-required="true">
            </div>
        </div>
        <button class="btn-mrng" data-popup-optin="">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optin.btn"]}</button>
        
            `;
        } else if ("resetPassword" == template) {
            html += `

        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.subtitle"]}</div>
        <button class="btn-mrng" data-popup-reset-pwd="">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.btn"]}</button>
        
            `;
        } else if ("resetPasswordDone" == template) {
            html += `

        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.resetpwddone.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.resetpwddone.subtitle"]}</div>
        
            `;
        } else if ("alreadyRegistered" == template) {
            html += `

        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.alreadyregistered.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.alreadyregistered.subtitle"]}</div>
        
            `;
        } else if ("wrongPassword" == template) {
            html += `

        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.wrongpwd.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.wrongpwd.subtitle"]}</div>
        
            `;
        } else if ("genericError" == template) {
            html += `

        <span class="title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.genericerror.title"]}</span>
        <div class="subtitle">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.genericerror.subtitle"]}</div>
        
            `;
        }

        html += `
    </div>
</div>
        `;

        return html;
    }
}

export default new PopupManager();
