import {SmartComponent} from "smart-component-js";
import ExternalTrackingManager from "../business/ExternalTrackingManager";

class BackgroundVideoComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "BackgroundVideoComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.playLabel = KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.playVideo"];
        this.pauseLabel = KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.pauseVideo"];
        this.$owlWrapper = false;
        this.playing = false;

        this.videoUrl = this.$el.data('video');
        this.videoTitle = this.$el.data('videoTitle') || this.videoUrl;

        this.init();
    }

    init() {
        //observer contacttoolcomponent addChatAgentStatusObserver
        if (!!this.videoUrl) {
            this.$owlWrapper = this.$el.parents('.owl-carousel').length > 0 ? this.$el.parents('.owl-carousel') : false;
            this.$video = $(`<video webkit-playsinline="true" playsinline="true"><source src="${this.videoUrl}" type="video/mp4"></video>`);
            this.$button = $(`<a class="play-pause bordered-cta">${this.playLabel}</a>`);
            this.$el.append(this.$video);
            this.$el.append(this.$button);

            this.$video[0].onended = () => {
                this.playPause();
            };

            this.$button.click(() => {
                this.playPause();
            });
        }
    }

    playPause() {
        this.$video.toggleClass('playing', !this.playing);
        if (this.playing) {
            this.$video[0].pause();
            this.$video[0].currentTime = 0;
            this.$button.text(this.playLabel);
            if (this.$owlWrapper) {
                this.$owlWrapper.trigger('play.owl.autoplay');
            }
            if(this.$video.parents('.hero-page-title').length > 0 && this.$video.parents('.hero-page-title').find('.title-container').length > 0) {
                this.$video.parents('.hero-page-title').find('.title-container').show();
            }
        } else {
            ExternalTrackingManager.trackVideoPlay(this.videoTitle);
            if (this.$owlWrapper) {
                this.$owlWrapper.trigger('stop.owl.autoplay');
            }
            this.$video[0].play();
            this.$button.text(this.pauseLabel);
            if(this.$video.parents('.hero-page-title').length > 0 && this.$video.parents('.hero-page-title').find('.title-container').length > 0) {
                this.$video.parents('.hero-page-title').find('.title-container').hide();
            }
        }

        this.playing = !this.playing;
    }
}

export default BackgroundVideoComponent;