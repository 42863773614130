class Utils {
    constructor() {
    }

    isMarangoniReferrer() {
        return (!!document.referrer && document.referrer.indexOf(location.host) >= 0);
    }

    goBack() {
        if (this.isMarangoniReferrer()) {
            document.location.href = document.referrer;
        }
    }

    cutText(text, length) {
        if (typeof text == 'string' && length > 0 && text.length > length) {
            return text.substring(0, length - 3) + '...';
        }

        return text;
    }

    printDate(date) {
        let res = '';
        if (date != null) {
            res = ("0" + date.getDate()).slice(-2) + '/' + ("0" + date.getMonth()).slice(-2) + '/' + date.getFullYear();
            res += ' ' + ("0" + date.getHours()).slice(-2) + '.' + ("0" + date.getMinutes()).slice(-2);
        }
        return res;
    }

    getParameter(url, target) {
        let _innerUtils = {
            checkSingleParam: function (string, target) {
                if (string.indexOf("=") > -1 && string.split('=')[0] == target) {
                    return string.split('=')[1];
                } else if (string == target) {
                    return 'empty-param';
                }

                return null;
            }
        }
        if (!!url && url.indexOf('?') > -1) {
            let params = url.split('?')[1];

            if (params.indexOf('&') > -1) {
                let p = params.split('&');
                for (let k in p) {
                    let res = _innerUtils.checkSingleParam(p[k], target);
                    if (res != null) {
                        return res;
                    }
                }
            } else {
                return _innerUtils.checkSingleParam(params, target);
            }
        }

        return null;
    }

    appendParameter(url, paramName, paramValue) {
        if (!url) {
            url = document.location.href;
        }
        if (!paramName) {
            paramName = 'param';
        }
        if (!paramValue) {
            paramValue = '1';
        }

        let split = url.split('?')
        let params = {};

        if (split.length > 1) {
            let splitSecond = split[1].split('&');
            for (let i in splitSecond) {
                let s = splitSecond[i].split('=');
                params[s[0]] = s[1];
            }
        }

        params[paramName] = paramValue;
        let res = split[0] + '?';
        for (let name in params) {
            res += name + '=' + params[name];
            res += '&';
        }

        res = res.slice(0, -1);
        return res;
    }

    removeSpaces(input) {
        if (input && input.length > 0) {
            return input.replace(/ /g, "%20");
        }

        return input;
    }

    isMobile() {
        return window.innerWidth <= 768;
    }

    numberToMonth(month) {
        if (!isNaN(month)) {
            let lang = KD_COMMON_CONSTANTS.LOCALE.split('_')[0];
            if ("it" == lang) {
                return ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'][month - 1];
            } else if ("en" == lang) {
                return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month - 1];
            } else if ("de" == lang) {
                return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'][month - 1];
            } else if ("fr" == lang) {
                return ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'][month - 1];
            } else if ("es" == lang) {
                return ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'][month - 1];
            } else if ("pt" == lang) {
                return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][month - 1];
            }
        }

        return month;
    }
}

export default Utils;
