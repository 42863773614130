import {SmartComponent} from "smart-component-js";
import CookieManager from "../business/ImCookieManager";

class GalleryDetailComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "GalleryDetailComponent";

        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }

    init() {
        let backActive = window.history.length > 1;
        let galleryCookie = CookieManager.getGalleryCookie();
        CookieManager.eraseGalleryCookie();

        if (backActive) {
            this.$el.find('.close-cnt').attr('href', 'javascript:history.back()');
        } else if (!!galleryCookie) {
            this.$el.find('.close-cnt').attr('href', galleryCookie);
        }

        this.loadChildComponents();
    }
}

export default GalleryDetailComponent;