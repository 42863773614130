import {SmartComponent} from "smart-component-js";

class DefaultCtrl extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.init();
    }

    init() {
    	this.loadChildComponents();
    }
}

export default DefaultCtrl;
