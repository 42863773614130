import {SmartComponent} from "smart-component-js";

class ShareComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ShareComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.isOpen = false;

        this.init();
    }

    init() {
        let shareMessage = encodeURI(document.title);
        let shareLink = encodeURI(document.location.href);
        this.$el.append(`
<div class="options-wrapper" style="display: none;">
    <div class="share-item">
        <a href="https://api.whatsapp.com/send?text=${shareLink}" data-action="share/whatsapp/share" target="_blank">
            <img class="ico" src="/fe-web/img/marangoni/icons/whatsapp.svg"/>
        </a>
    </div>
    <div class="share-item">
        <a href="http://twitter.com/share?text=${shareMessage}&url=${shareLink}" target="_blank">
            <img class="ico" src="/fe-web/img/marangoni/icons/twitter.svg"/>
        </a>
    </div>
    <div class="share-item">
        <a href="https://www.facebook.com/sharer/sharer.php?u=${shareLink}" target="_blank">
            <img class="ico" src="/fe-web/img/marangoni/icons/facebook.svg"/>
        </a>
    </div>
</div>
        `);

        this.$el.click((e) => {
            e.stopPropagation();
            this.toggleModal();
        });

        $(document).on('click', 'body', () => {
            if (this.isOpen) {
                this.toggleModal();
            }
        });

        this.$el.find('.share-item > a').click((e) => {
            e.stopPropagation();
        })
    }

    toggleModal() {
        this.$el.find('.options-wrapper').toggle();
        this.isOpen = this.$el.find('.options-wrapper').is(':visible');
        if (this.isOpen && this.$el.find('.options-wrapper').offset().left < 0) {
            this.$el.find('.options-wrapper').css('margin-left', (parseInt(this.$el.find('.options-wrapper').css('margin-left')) - this.$el.find('.options-wrapper').offset().left) + 'px');
        }
    }
}

export default ShareComponent;