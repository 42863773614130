//TODO - questo metodo a tendere dovrà essere tolto per essere spostato dentro la action.

class LandingUrlBuilder {
    constructor() {
    }

    updateUrl($form, $input) {
        console.log("form");
        console.log($form);
        console.log("input");
        console.log($input);
        if (!!$input && $input.length > 0) {
            let lingua = 'it';
            let webformname = '';
            let programme = 'generic';
            let campus = 'generic';
            let ringraziamento = '';

            if (KD_COMMON_CONSTANTS.LOCALE == 'en_GB') {
                ringraziamento = 'thank-you';
                lingua = 'en';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'zh_CN') {
                ringraziamento = 'thank-you-zh';
                lingua = 'zh';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'fr_FR') {
                ringraziamento = 'merci';
                lingua = 'fr';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'it_IT') {
                ringraziamento = 'grazie';
                lingua = 'it';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'es_ES') {
                ringraziamento = 'gracias';
                lingua = 'es';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'de_DE') {
                ringraziamento = 'danke';
                lingua = 'de';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'pt_BR') {
                ringraziamento = 'obrigado';
                lingua = 'pt-br';
            } else if (KD_COMMON_CONSTANTS.LOCALE == 'ar_AE') {
                ringraziamento = 'thank-you';
                lingua = 'ar';
            }
            if (!!KD_MARANGONI_SITE_FORM_CONSTANTS.webformname) {
                if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'CONTACT_US') {
                    webformname = 'contactusmainsite';
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'OPENDAY') {
                    webformname = 'openday';
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'EVENT') {
                    webformname = 'genericevent';
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'CALL_ME_BACK') {
                    webformname = 'callmeback';
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'OPEN_LESSON') {
                    webformname = 'openlesson';
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'SCHOLARSHIP_REGISTRATION') {
                    webformname = 'calloftalentscholarship';
                    ringraziamento = 'registration/' + ringraziamento
                } else if (KD_MARANGONI_SITE_FORM_CONSTANTS.webformname == 'SCHOLARSHIP_SUBSCRIBE') {
                    webformname = 'calloftalentscholarship';
                    ringraziamento = 'apply/' + ringraziamento
                }
            }
            if (!!$form) {
                if ($form.find('[name="programme_code"]').length > 0 && !!$form.find('[name="business_unit_code"]').val()) {
                    programme = $form.find('[name="programme_code"]').val();
                    if ($form.find('[name="programme_code"]').is("[data-custom-ty-programme]")) {
                        programme = $form.find('[name="programme_code"]').data('customTyProgramme');
                    }
                }
                if ($form.find('[name="business_unit_code"]').length > 0 && !!$form.find('[name="programme_code"]').val()) {
                    campus = $form.find('[name="business_unit_code"]').val().replace(' ', '-');
                }
            }

            let protocol = 'https';
            if (KD_COMMON_CONSTANTS.LOCALE == 'zh_CN') {
                protocol = 'http';
            }

            let retUrl = `${protocol}://${document.location.hostname}/${lingua}/${webformname}/${programme}/${campus}/${ringraziamento}`;
            retUrl = retUrl.toLocaleLowerCase();
            $input.val(retUrl.split(" ").join("-").replace(/[^a-zA-Z0-9-/:.]/g, ""));
        }
    }
}

export default new LandingUrlBuilder();
