import {SmartComponent} from "smart-component-js";

class AiCourseCardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiCourseCardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        this.$el.on('ai-error', () => {
            this.$el.remove();
            // this.$el.parents('.owl-carousel').trigger('reinit.owl.carousel');
        });
        this.$el.on('ai-ready', () => {
            let aiData = this.$el.data('aiData');
            if (aiData != null) {
                let html = '';
                if (!!aiData.image) {
                    html = `
                        <a href="${aiData.url}"><img src="${aiData.image}"></a>
                    `;
                }

                html += `
                    <div class="content">
                        <div class="title">${!aiData.title ? '' : `${aiData.title}`}</div>
                        <div class="description">${!aiData.subtitle ? '' : `${aiData.subtitle}`}</div>
                        ${!aiData.description1 && !aiData.description2 ? '' : `<div class="tags"><div class="tag">${aiData.description2}${(aiData.description2 && aiData.description1) ? ' | ' : ''}${aiData.description1}</div></div>`}
                        ${!aiData.description3 ? '' : `<div class="tags"><div class="tag">${aiData.description3}</div></div>`}
                    </div>
                `;

                if (!!aiData.url) {
                    html += `
                        <a href="${aiData.url}" class="action btn-mrng">
                            ${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.findMore"]}
                        </a>
                    `;
                }
                this.$el.html(html);
            }
        });
    }
}

export default AiCourseCardComponent;