import {SmartComponent} from "smart-component-js";

class DesktopSliderComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CallMeBackInnerComponent";
        super(element, parentComponent, params);
        this.$carousel = $(this.element).find('.owl-carousel');
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.init();
    }

    highlightCarousel() {
        this.$carousel.owlCarousel({
            rtl: this.isRtl,
            items: 1,
            margin: 25,
            loop: true,
            nav: true
        });
    }

    init() {
        this.highlightCarousel();

    }
}

export default DesktopSliderComponent;