import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class AmbassadorListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AmbassadorListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/ambassador/list';

        this.$el = $(element);
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.page = 1;
        this.filterValues = {};

        this.init();
    }

    createResultItem(item) {
        let nameSurname = '';
        if (!!item.name) {
            nameSurname += item.name + ' ';
        }
        if (!!item.surname) {
            nameSurname += item.surname;
        }

        return `
                <div class="col-12 col-lg-4 col-md-6 px-0 search-result-item--wrapper mb-0">
                    <div class="ambassador-result-item" ${item.thumbImage ? `style="background-image: url('${item.thumbImage}')"` : ''}>
                        <div class="text-wrapper">
                            ${!nameSurname ? '' : `<div class="name">${nameSurname}</div>`}
                            ${!item.area ? '' : `<div class="area">${item.area}</div>`}
                            ${!item.role ? '' : `<div class="type">${item.role}</div>`}
                             <a href="${item.url}" class="bordered-cta white text-uppercase">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>     
                        </div>                                        
                    </div>
                </div>
        `;
    }
}

export default AmbassadorListingComponent;