import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class ApplyPopupComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ApplyPopupComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.$popup = this.$el.siblings('.apply-options');
        this.init();
    }

    init() {
        this.$el.click(()=>{
            if(this.$popup.is(':hidden')) {
                $('.apply-options').toggle(false);
            }
            this.$popup.toggle();
        })
    }
}

export default ApplyPopupComponent;