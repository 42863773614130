import Cookies from "js-cookie";

class CookieManager {
    constructor() {
        this.REPLACE_PAGE_ID_COOKIE_NAME = "KD_RPI";
    }

    setReplacePageCookie(id) {
        Cookies.set(this.REPLACE_PAGE_ID_COOKIE_NAME, id);
    }
}

export default CookieManager;
