import {SmartComponent} from "smart-component-js";
import $ from "jquery";
import IframeManager from "../business/IframeManager";
import IMUtils from "../business/IMUtils";

class ContactToolComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ContactToolComponent";
        super(element, parentComponent, params);
        this.siteId = element.dataset.siteId;
        this.locale = element.dataset.locale;

        this.chatStatus = {
            preChatFormShowed: true
        }


        this.init();
    }

    init() {
        this.doAjaxCall().then((resp) => {
            this.buildHtml(resp.body);
        }, () => {
            //NOOP
        })
        this.addMenuEventListener();
    }

    buildHtml(items) {

        let html = '';

        for (var i = 0; i < items.length; i++) {

            if (i == 2) {
                html += `<div class="contact-tool-collapse">
                            <div class="contact-tool-collapse-trigger">&times;</div>
                                <div class="contact-tool-collapse-actions">`
            }

            html += this.buildContactModeHtml(items[i]);

            if (items[i].data == 'chat') {

                $(document).on('click', '#mrng_ctc_chat', () => {
                    let $chatHiddenButton = $('.embeddedServiceHelpButton .uiButton');
                    $chatHiddenButton.click();
                    //$(this.element).hide();
                });

                if ($('.embeddedServiceHelpButton').length > 0) {
                    this.onChatStatusChange();
                    this.addChatAgentStatusObserver();
                } else {
                    this.addChatLoadingMutationObserver();
                }

                window.document.addEventListener("kd-ctc-after-minimize", () => {
                    if (this.chatStatus.preChatFormShowed) {
                        window.embedded_svc.liveAgentAPI.endChat();
                        $(this.element).show();
                        this.chatStatus.preChatFormShowed = true;
                    } else {
                        //NOOP
                    }
                })

                window.document.addEventListener("kd-ctc-after-destroy", () => {
                    $(this.element).show();
                    this.chatStatus.preChatFormShowed = true;
                })

                window.document.addEventListener("kd-ctc-chat-request-success", () => {
                    this.chatStatus.preChatFormShowed = false;
                    $(this.element).hide();
                })

                window.document.addEventListener("kd-ctc-chat-established", () => {
                    $(this.element).hide();
                })

                window.document.addEventListener("kd-ctc-chat-ended", () => {
                    $(this.element).show();
                    this.chatStatus.preChatFormShowed = true;
                })
            }


        }

        if (items.length >= 2) {
            html += `</div></div></div>`
        }

        $(this.element).html(html);
        $('header .contact-tool').html(html);

        this.grantClick = false;

        $('.contact-tool-collapse-trigger, .collapse-trigger').on('click', (e) => {
            let $collapse = $(e.currentTarget).parents('.contact-tool-collapse');
            this.toggleCollapsible(e, $collapse);
        });
        $('.collapse-hover-trigger').hover((e) => {
            let $collapse = $(e.currentTarget);
            this.toggleCollapsible(e, $collapse);
        }, (e) => {
            let $collapse = $(e.currentTarget);
            this.toggleCollapsible(e, $collapse);
        });
        $('.contact-tool-collapse-actions').click((e) => {
            this.grantClick = true;
        });

        this.addCallMeBack();
    }

    toggleCollapsible(e, $collapse) {
        if ($(window).width() <= 768) {
            if ($collapse.find('.only-desk').length > 0) {
                return -1;
            }
        } else {
            if (!this.grantClick) {
                this.grantClick = false;
                e.preventDefault();
            }
        }

        $collapse.toggleClass('open');
    }

    buildContactModeHtml(item) {
        switch (item.data) {
            case 'telephone':
                let label = item.value;
                label = label.split(':')[1];
                return `
                    <a href="${item.value}" id="mrng_ctc_${item.data}" title="${item.label}" class="contact-tool-collapse collapse-hover-trigger">
                        <img class="" src="/fe-web/img/marangoni/bcf/phone.svg"/><span>${item.label}</span>
                        <div class="contact-tool-collapse-actions red wide"><div class="no-overflow only-desk" style="direction: ltr;">${label}</div></div>
                    </a>
                `;
            case 'whatsapp':
                return `<a href="${item.value}" id="mrng_ctc_${item.data}" target="_blank" title="${item.label}"><img class="wa-image" src="/fe-web/img/marangoni/icons/whatsapp.png"/><span>${item.label}</span></a>`;
            case 'wechat':
                return `<a href="${item.value}" id="mrng_ctc_${item.data}" title="${item.label}">
                            <span class="img-baloon"><img class="collapse-trigger" src="/fe-web/img/marangoni/wecat_qr.jpeg"/></span>
                            <img src="/fe-web/img/marangoni/bcf/wechat.svg"/>
                            <span>${item.label}</span>
                        </a>`;
            case 'chat':
                return `<a href="javascript:void(0);" id="mrng_ctc_${item.data}" style="display: none;" title="${item.label}">
                            <span class="baloon">${item.label}</span>
                            <img src="/fe-web/img/marangoni/bcf/chat.svg"/>
                            <span>${item.label}</span>
                        </a>`;
            case 'bookOrientation':
                return `
                        <a href="${item.value}" id="mrng_ctc_${item.data}">
                            <span class="baloon">${item.label}</span>
                            <img src="/fe-web/img/marangoni/bcf/calendar.svg"/>
                            <span>${item.label}</span>
                        </a>
                    `;
            case 'contactForm':
                return `
                        <a href="${item.value}" id="mrng_ctc_${item.data}">
                            <span class="baloon">${item.label}</span>
                            <img src="/fe-web/img/marangoni/bcf/mail.svg"/>
                            <span>${item.label}</span>
                        </a>
                    `;
            case 'callMeBack':
                return `<a href="${item.value}" id="mrng_ctc_${item.data}" title="${item.label}"><img src="/fe-web/img/marangoni/bcf/callmeback.svg"/><span>${item.label}</span></a>`;
            default:
                return '';
        }

    }

    addMenuEventListener() {
        document.addEventListener("mrng-menu-open", (e) => {
            $(this.element).hide();
        });

        document.addEventListener("mrng-menu-close", (e) => {
            $(this.element).show();
        });
    }

    addChatLoadingMutationObserver() {
        let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        var obs = new MutationObserver((mutations, observer) => {
            for (var i = 0; i < mutations.length; ++i) {
                for (var j = 0; j < mutations[i].addedNodes.length; ++j) {
                    let classList = mutations[i].addedNodes[j].classList;
                    if (classList && classList.contains('embeddedServiceHelpButton')) {
                        console.log(" Contact Tool KD - embeddedServiceHelpButton was added!");
                        this.onChatStatusChange();
                        this.addChatAgentStatusObserver();
                        obs.disconnect();
                    }
                }
            }
        });

        obs.observe($('body')[0], {childList: true, subtree: true})
    }


    onChatStatusChange() {
        let $chatHiddenButton = $('.embeddedServiceHelpButton button');
        if ($chatHiddenButton.hasClass("helpButtonEnabled")) {
            console.log("Contact Tool KD - Agent is online, enabling chat button");
            $('#mrng_ctc_chat').show();
        } else {
            $('#mrng_ctc_chat').hide();
            console.log("Contact Tool KD - Agent is offline");
        }
    }

    addChatAgentStatusObserver() {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const {target} = mutation;

                if (mutation.attributeName === 'class') {
                    this.onChatStatusChange();
                }
            });
        });
        observer.observe($('.embeddedServiceHelpButton button')[0], {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class']
        })
    }


    doAjaxCall() {

        let countryCode = this.getParameterByName("mrng_ip_country_code");

        let qsCountryCode = countryCode ? `&countryCode=${countryCode}` : '';

        let qs = `site=${this.siteId}&locale=${this.locale}${qsCountryCode}`;
        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/contacttool/config?${qs}`, {
            method: 'GET',
        });
    }


    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    addCallMeBack() {
        if (KD_MARANGONI_SITE_CONSTANTS.IS_FROM_INDIA_COUNTRIES != 'true' && KD_COMMON_CONSTANTS.LOCALE != 'zh_CN') {
            $(document).on('click', '.detail-corso-apply .the-flying-cta, .contact-tool-collapse a', () => {
                sessionStorage.setItem("callMeBackStatus", 'avoid');
            });

            if (!this.callMeBackLogic()) {
                let interval = setInterval(() => {
                    let status = parseInt(sessionStorage.getItem("callMeBackStatus"));
                    if (!status) {
                        status = 1;
                    }
                    status += 1;
                    sessionStorage.setItem("callMeBackStatus", status);

                    if (this.callMeBackLogic()) {
                        clearInterval(interval);
                    }
                }, 500);
            }
        }
    }

    callMeBackLogic() {
        let status = sessionStorage.getItem("callMeBackStatus");
        if (!!status) {
            if (status == '300') {
                //Il valore è 300 perchè il ciclo è ogni mezzo secondo, quindi 300 sono 150 secondi.
                status = 'ready';
                sessionStorage.setItem("callMeBackStatus", status);
            }
            if (status == 'ready') {
                let $tel = $(this.element).find('#mrng_ctc_telephone');
                if ($tel.length > 0) {
                    $tel.find('.contact-tool-collapse-actions').addClass('has-content').append(`
                            <div class="call-me-back">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.callMeBack"]}<span class="call-me-close"></span></div>
                        `);
                    $tel.find('.call-me-close').click((e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.removeCallMeBack();
                        sessionStorage.setItem("callMeBackStatus", 'avoid');
                        this.callMeBackLogic();
                    });
                    $tel.find('.call-me-back').click((e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.showCallMeBackPopup();
                    });
                }

                //Ho mostrato il callmeback, non serve contare
                return true;
            } else if (status == 'avoid') {
                //Il callmeback va evitato, non serve contare
                return true;
            }
        }

        //E' il primo giro o sono in corsa, quindi devo contare
        return false;
    }

    removeCallMeBack() {
        $(this.element).find('#mrng_ctc_telephone').removeClass('open').find('.call-me-back').remove();
        $(this.element).find('#mrng_ctc_telephone .contact-tool-collapse-actions').removeClass('has-content');
    }

    showCallMeBackPopup() {
        this.removeCallMeBack();
        sessionStorage.setItem("callMeBackStatus", 'avoid');
        let $cmb = $('#call-me-back-popup');
        if ($cmb.length > 0) {
            $cmb.removeClass('done');
            $cmb.show();
            $cmb.find('[data-close]').click(() => {
                $cmb.hide();
            });
        }
    }
}

export default ContactToolComponent;