import {SmartComponent} from "smart-component-js";

class MagazineFrontpageComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "MagazineFrontpageComponent";
        super(element, parentComponent, params);
        this.siteId = element.dataset.siteId;
        this.locale = element.dataset.locale;
        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/magazine/article/search';
        this.resultsLimit = ($(window).width() <= 768) ? 2 : 4;

        this.$el = $(element);
        this.filters = {};

        this.init();
    }

    doAjaxCall(id, keyword) {
        let qs = `site=${this.siteId}&locale=${this.locale}&keyword=${keyword}&category=${id}`;

        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/magazine/article/search?${qs}`, {
            method: 'GET',
        });
    }

    createResultItem(item) {
        let readMoreLabel = KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"];
        let itemUrl = item.url + '?itm_campaign=Maze35_ALL_FDA_220700_other_EN_ALL';
        let urlTag = item.urlTag + '?itm_campaign=Maze35_ALL_FDA_220700_other_EN_ALL';
        let searchItem = `
            <div class="search-result-item--wrapper mrng-carousel-item">
                <div class="search-result-item--corso magazine-item">
                    <a href="${itemUrl}">
                        <img src="${item.thumbImage}" />
                    </a>
                    <div class="content">
                        <a class="titleTag" href="${urlTag}">${item.titleTag}</a>
                        <a href="${itemUrl}">
                            <div class="title">
                                    ${item.title}
                            </div>
                        </a>
                        <div class="description">
                                ${item.intro}
                        </div>
                        <div class="signature">${item.signature}</div>
                        <div class="cta-wrapper">
                        <div class="campus">${item.campus}</div>
                            <a href="${itemUrl}" class="action btn-mrng">${readMoreLabel}</a>
                        </div>
                    </div>
                </div>
            </div>
        `;

        return '' + searchItem;
    }

    search(id, keyword, resultsLimit) {
        let $thisContainer = $(`.magazine-category[data-id="${id}"] .mrng-carousel-container`);
        $thisContainer.addClass('loading');
        this.doAjaxCall(id, keyword).then((resp) => {
            let html = "";
            if (resp.body.result.length < 1) {
                let text = KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.filterNoResults"];

                html += `
                <div class="no-result">${text}</div>
                `;
                $(`.magazine-category[data-id="${id}"] .mrng-carousel-container`).html(html);

                $thisContainer.removeClass('loading');
            } else {
                for (var i = 0; i < resp.body.result.length; i++) {
                    html += this.createResultItem(resp.body.result[i]);
                }

                $thisContainer
                    .html('')
                    .html(html)
                    .removeClass('loading');

                (resp.body.result.length < resultsLimit) ? $thisContainer.next().hide() : $thisContainer.next().show()
            }

            this.reinitSlider($thisContainer);
        }, () => {
            $thisContainer.removeClass('loading');
        });
    }

    reinitSlider(container) {
        $(container).trigger('reinit.owl.carousel');

        setTimeout(() => {
            $(container).trigger('reinit.owl.carousel');
        })
    }

    init() {
        let resultsLimit = ($(window).width() <= 768) ? 2 : 4;

        $('.category-search-btn').on('click', (e) => {
            let $btnClicked = $(e.currentTarget);
            let id = $btnClicked.attr('data-id');
            let keyword = $btnClicked.prev().val();
            this.search(id, keyword, resultsLimit);
        });

        $('.mrng-carousel-container').each(function () {

            if ($(this).find('.search-result-item--wrapper').length < resultsLimit) {
                $(this).next().hide();
            }
        });

        this.checkSliderInitialized();
    }

    checkSliderInitialized() {
        setTimeout(() => {
            try {
                var ua = navigator.userAgent || navigator.vendor || window.opera;
                var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
                if (isInstagram) {
                    $('.category-search-btn').click();
                }
            } catch (e) {

            }
        }, 2000);
    }
}

export default MagazineFrontpageComponent;