import {SmartComponent} from "smart-component-js";
import ScholarshipFormManager from "../business/ScholarshipFormManager";
import PopupManager from "../business/PopupManager";
import FormUtils from "../common/FormUtils";

class ScholarshipRegisteredCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipRegisteredCtrl";
        super(element, parentComponent, params);

        this.init();
    }

    init() {
        $('body').on('click', '#gge-popup [data-popup-optin]', () => {
            if (!this.invalidUsername()) {
                let formData = {
                    username: $('#gge-popup [name="username"]').val()
                }
                
                ScholarshipFormManager.sendOptin(formData).then(() => {
                    PopupManager.removePopup();
                });
            }
        });
        $('[data-resend-optin]').click(() => {
            PopupManager.showPopup("optinemail");
        });
        $('body').on('change, keyup', '#gge-popup [name="username"]', (e) => {
            this.invalidUsername();
        });
    }

    invalidUsername() {
        let isError = !FormUtils.validateEmail($('#gge-popup [name="username"]').val());
        $('#gge-popup [name="username"]').parents('.input').toggleClass('error', isError);
        return isError;
    }
}

export default ScholarshipRegisteredCtrl;