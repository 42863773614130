import {SmartComponent} from "smart-component-js";
import ScholarshipFormManager from "../business/ScholarshipFormManager";
import SalesforceManager from "../business/SalesforceManager";
import PopupManager from "../business/PopupManager";
import FormUtils from "../common/FormUtils";
import IframeManager from "../business/IframeManager";
import IMUtils from "../business/IMUtils";
import Utils from "../../common/business/Utils";

class ScholarshipFormCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipFormCtrl";
        super(element, parentComponent, params);

        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.localeMap = {
            'it_IT': 'ita',
            'en_GB': 'eng',
            'fr_FR': 'fra',
            'es_ES': 'esp',
            'pt_BR': 'prt',
            'zh_CN': 'chi',
            'de_DE': 'deu',
            'ar_AE': 'ara'
        }
        this.serviceLocale = this.localeMap[this.locale];

        this.$el = $(element);
        this.$formWrp = this.$el.find('#scoolarship-form-wrp');

        this.postponedAolRegistration = false;
        this.scholarshipUserKey = false;
        this.userSubscriptions = false;
        this.coursesValues = {};
        this.campusValues = {};
        this.degreeValues = {};
        this.allDocs = {};
        this.docValidationTriggered = false;

        this.filterMap = {};
        this.formData = {};

        this.currentTemplate = 'username';
        this.prevTemplate = [];
        this.templateSequence = [
            'username',
            'login',
            'createuser',
            'register',
            'fillinfo',
            'subscribe',
            'documents',
            'skilltest'
        ];
        this.templateStepName = {
            'username': 'registration-email',
            'login': 'application-password',
            'createuser': 'registration-password',
            'register': 'registration-personal-data',
            'fillinfo': 'registration-date-of-birth',
            'subscribe': 'application-choose-your-course',
            'documents': 'application-upload-documents',
            'skilltest': 'application-skill-test'
        };

        this.init();
    }

    init() {
        this.toggleLoader(true);
        this.parseScholarship();

        this.$formWrp.on('click', '[data-go-away]', () => {
            window.location = KD_MARANGONI_PAGE_CONSTANTS.scholarshipUrl;
        });
        this.$formWrp.on('click', '[data-go-back]', () => {
            this.printBack();
        });
        this.$formWrp.on('click', '[data-go-next]:not(.disabled)', () => {
            this.printNext();
        });
        this.$formWrp.on('keyup', '[name="username"],[name="password"],[name="repeatpassword"]', (e) => {
            if (e.keyCode === 13) {
                this.printNext();
            }
        });
        this.$formWrp.on('keyup change', '[name="phone_number"]', (e) => {
            $(e.currentTarget).val($(e.currentTarget).val().replace(/\D/g, ''));
            if ($(e.currentTarget).val().length > 11) {
                $(e.currentTarget).val($(e.currentTarget).val().substr(0, 11));
            }
        });
        this.$formWrp.on('change, keyup', '.required', (e) => {
            this.validate($(e.currentTarget));
        });
        this.$formWrp.on('change, keyup', '[name="password"]', (e) => {
            this.validateCreatePassword(true);
        });

        this.$formWrp.on('change', '#intake', (e) => {
            this.formData.intake = this.$formWrp.find('#intake').val();
            this.printSchoolOptions();
            this.$formWrp.find('#school').change();
        });
        this.$formWrp.on('change', '#school', (e) => {
            this.formData.school = this.$formWrp.find('#school').val();
            //Siccome nella tendina scuola potrei avere il doppio valore che viene giù dal servizio, i dati della scuola
            //li popolerò al submit.
            this.printDegreeOptions();
            this.$formWrp.find('#degree').change();
        });
        this.$formWrp.on('change', '#degree', (e) => {
            this.formData.degree = this.$formWrp.find('#degree').val()
            this.formData.degreeSfname = this.degreeValues[this.formData.degree];
            this.printCourseOptions();
            this.$formWrp.find('#course').change();
        });
        this.$formWrp.on('change', '#course', (e) => {
            this.formData.course = this.$formWrp.find('#course').val();
            if (!!this.formData.course && this.formData.course != 'null') {
                this.formData.subArea = this.coursesValues[this.formData.course]['subarea_sfname'];
                this.formData.courseName = this.coursesValues[this.formData.course]['title'];
                this.formData.courseId = this.coursesValues[this.formData.course]['identifier'];

                //Siccome nella tendina scuola potrei avere il doppio valore che viene giù dal servizio, quando scelgo il corso salvo i dati della scuola corretta.
                let singleSchoolId = this.coursesValues[this.formData.course]['school_id'];
                this.formData.schoolLabel = this.campusValues[singleSchoolId].name || '';
                this.formData.schoolSfname = this.campusValues[singleSchoolId].sfname || '';
            } else {
                delete this.formData.subArea;

                this.formData.schoolLabel = '';
                this.formData.schoolSfname = '';
            }
            if (this.currentTemplate == 'subscribe') {
                this.printCourseDocs();
            }
        });
        this.$formWrp.on('change', '.sch-doc-el input[type="file"]', (e) => {
            let $file = $(e.currentTarget);
            if ($file[0].files.length >= 0) {
                $file.parents('.sch-doc-el').addClass('file-uploading');
                ScholarshipFormManager.setDocument(this.scholarshipUserKey, $file.attr('id'), $file).then((res) => {
                    if (!res) {
                        this.eraseDoc($file);
                        this.showPopup("genericError");
                    } else {
                        this.formData.userDocs[$file.parents('.sch-doc-el').data('idDoc')] = res['dropbox_link'];
                        $file.parents('.sch-doc-el')
                            .addClass('file-selected')
                            .removeClass('error')
                            .removeClass('file-uploading')
                            .find('.filename')
                            .html($file[0].files[0].name);
                    }

                    if (this.docValidationTriggered) {
                        this.validateDocuments();
                    }
                });
            } else {
                this.eraseDoc($file);
                if (this.docValidationTriggered) {
                    this.validateDocuments();
                }
            }
        });
        this.$formWrp.on('click', '.sch-doc-el .new', (e) => {
            this.eraseDoc($(e.currentTarget).parents('.sch-doc-el').find('input[type="file"]'));
        });
        this.$formWrp.on('countrychange', '#phone', (e) => {
            this.updatePrefixDependencies();
        });
        $('body').on('click', '#gge-popup [data-popup-optin]', () => {
            ScholarshipFormManager.sendOptin(this.formData).then(() => {
                PopupManager.removePopup();
            });
        });
        this.$formWrp.on('click', '[data-reset-password]', () => {
            this.showPopup("resetPassword");
        });
        $('body').on('click', '#gge-popup [data-popup-reset-pwd]', () => {
            ScholarshipFormManager.sendForgotPassword(this.formData).then(() => {
                this.showPopup("resetPasswordDone");
            });
        });

        // Sender
        // parent.postMessage("iframe-skilltest-completed", "*");
        IframeManager.waitForIframeEvent(IframeManager.eventNames.skilltest, () => {
            if (this.currentTemplate == 'skilltest') {
                this.$formWrp.find('.btn-mrng.disabled').removeClass('disabled');
            }
        });

        ScholarshipFormManager.getLoggedUser().then((userdata) => {
            this.doLoggedThings(userdata);
        }, () => {
            this.printTemplate();
        })
    }

    showPopup(template) {
        PopupManager.showPopup(template);
        this.toggleLoader(false);
    }

    doLoggedThings(userdata) {
        KD_MARANGONI_SITE_FORM_CONSTANTS.webformname = 'SCHOLARSHIP_SUBSCRIBE';
        this.formData.userData = userdata;

        if ('user_exists_aoldirect_created' == userdata.msg) {
            //Se è un utente AOL aggiorno il flag e mi preparo ad eseguire la chiamata in fase di subscribe.
            this.postponedAolRegistration = true;
        }
        this.doLoggedThingsPart2(userdata);
    }

    doLoggedThingsPart2(userdata) {
        if (!!userdata && !!userdata.otherdata && !!userdata.otherdata.birthday) {
            this.jump('subscribe');
        } else {
            this.jump('fillinfo');
        }
    }

    parseScholarship() {
        let scholarship = JSON.parse(KD_MARANGONI_PAGE_CONSTANTS.scholarship);

        if (scholarship.configurationMap && scholarship.configurationMap.details) {
            let details = scholarship.configurationMap.details;
            this.scholarshipDetails = details;

            if (details.courses) {
                for (let c in details.courses) {
                    let course = details.courses[c];
                    this.coursesValues[course.courseid] = course;
                    if (!!course.intake && !!course['school_id']) {
                        let intake = course.intake;
                        let campus = course['school_id'];
                        let degree = course['groupname_name_' + this.serviceLocale];
                        this.degreeValues[course['groupname_name_' + this.serviceLocale]] = course['groupname_sfname'];

                        if (!this.filterMap[intake]) {
                            this.filterMap[intake] = {};
                        }
                        if (!this.filterMap[intake][campus]) {
                            this.filterMap[intake][campus] = {};
                        }
                        if (!this.filterMap[intake][campus][degree]) {
                            this.filterMap[intake][campus][degree] = [];
                        }

                        this.filterMap[intake][campus][degree].push(course.courseid);
                    }
                }
            }
            if (details.schools) {
                for (let c in details.schools) {
                    let campus = details.schools[c];
                    let cIds = campus['branch_id_arr'].split(',');
                    for (let i in cIds) {
                        this.campusValues[cIds[i]] = {
                            name: campus.name,
                            sfname: campus.sfname
                        };
                    }
                }
            }
            if (details.docs) {
                for (let d in details.docs) {
                    if (!!details.docs[d]) {
                        this.allDocs[details.docs[d].short_name] = details.docs[d];
                    }
                }
            }
        }
    }

    jump(template) {
        this.prevTemplate.push(this.currentTemplate);
        this.currentTemplate = template;
        this.printTemplate();
    }

    updateFormData() {
        let stepData = this.$formWrp.find(':input').serializeArray();
        for (let i in stepData) {
            this.formData[stepData[i].name] = stepData[i].value;
        }
    }

    printNext(forceNext) {
        this.toggleLoader(true);
        this.updateFormData();

        if (!forceNext && this.currentTemplate == 'username') {
            if (this.invalidUsername()) {
                this.toggleLoader(false);
                return false;
            }
            ScholarshipFormManager.checkUsername(this.formData).then((data) => {
                if (data == 1) {
                    this.printNext(true);
                } else if (data == 2) {
                    this.showPopup("optin");
                }
            }, () => {
                this.jump('createuser');
            });
        } else if (!forceNext && this.currentTemplate == 'login') {
            ScholarshipFormManager.login(this.formData).then((userdata) => {
                this.doLoggedThings(userdata);
            }, () => {
                this.showPopup("wrongPassword");
            });
        } else if (!forceNext && this.currentTemplate == 'createuser') {
            let validPassword = this.validateCreatePassword();
            if (this.invalidUsername()) {
                this.toggleLoader(false);
                return false;
            }
            if (validPassword) {
                this.printNext(true);
            }
        } else if (!forceNext && this.currentTemplate == 'register') {
            if (this.validateRegister()) {
                //A questo punto, dato che ormai ho scelto e non devo più preservare il funzionamento delle tendine
                //setto il valore della scuola corretto.
                this.formData.school = this.coursesValues[this.formData.course]['school_id'];

                this.formData.campaign_id = $('#salesforce-form-register').find('[data-name="REGISTRATION SCHOLARSHIP ID"]').val();
                this.formData.retail_id = $('#salesforce-form-register').find('[data-name="ID RETAIL AGENZIA"]').val();

                ScholarshipFormManager.register(this.formData).then(() => {
                    SalesforceManager.traceScoolarshipRegistration(this.formData).then(() => {
                        window.location = KD_MARANGONI_PAGE_CONSTANTS.registrationComplete;
                    });
                }, () => {
                    this.showPopup("genericError");
                });
            } else {
                this.toggleLoader(false);
            }
        } else if (!forceNext && this.currentTemplate == 'fillinfo') {
            if (this.validateRegister()) {
                this.formData.userData.otherdata.birthday = this.formData.date;

                ScholarshipFormManager.setData(this.formData).then(() => {
                    this.printNext(true);
                }, () => {
                    this.showPopup("genericError");
                });
            } else {
                this.toggleLoader(false);
            }
        } else if (!forceNext && this.currentTemplate == 'subscribe') {
            if (this.postponedAolRegistration) {
                //Se è un utente AOL (prima volta) eseguo la chiamata dedicata
                SalesforceManager.traceScoolarshipRegistrationAol(this.formData).then(() => {}, () => {
                    //TODO - se la trace va in errore nessuno se ne accorge e si procede come niente fosse. Servirebbe un log fatto come si deve a tal proposito.
                });

                //Non è necessario aspettare la risposta in quanto se anche andasse in errore non saprei cosa fare, tolgo il flag e vado oltre.
                this.postponedAolRegistration = false;
            }
            let subscriptionStatus = 'edit';
            if (this.userSubscriptions) {
                for (let i in this.userSubscriptions) {
                    if (this.userSubscriptions[i].courseid == this.formData.course) {
                        this.scholarshipUserKey = this.userSubscriptions[i].uniqid;
                        subscriptionStatus = this.userSubscriptions[i].status;
                        break;
                    }
                }
            }
            if (!this.scholarshipUserKey) {
                ScholarshipFormManager.subscribe(this.scholarshipDetails.id, this.formData.course).then((scholarshipUserKey) => {
                    this.scholarshipUserKey = scholarshipUserKey;
                    this.printNext(true);
                }, () => {
                    this.showPopup("genericError");
                });
            } else if (subscriptionStatus == 'edit') {
                this.printNext(true);
            } else {
                this.showPopup("alreadyRegistered");
            }
        } else if (!forceNext && this.currentTemplate == 'skilltest') {
            ScholarshipFormManager.closeSubscription(this.scholarshipUserKey).then(() => {
                SalesforceManager.traceScoolarshipSubscription(this.formData);
            }, () => {
                this.showPopup("genericError");
            });
        } else if (!forceNext && this.currentTemplate == 'documents') {
            if (this.validateDocuments()) {
                this.printNext(true);
            } else {
                $('html, body').stop().animate({scrollTop: 0}, 300, 'swing');
                this.toggleLoader(false);
            }
        } else {
            this.prevTemplate.push(this.currentTemplate);
            this.currentTemplate = this.templateSequence[this.templateSequence.indexOf(this.currentTemplate) + 1];
            this.printTemplate();
        }
    }

    printBack() {
        this.currentTemplate = this.prevTemplate.pop();
        this.printTemplate();
    }

    validateRegister() {
        this.$formWrp.find(':input:visible.required').each((i, el) => {
            let $el = $(el);
            if ($el.is('[type="checkbox"]')) {
                $el.parents('.ctm-checkbox').toggleClass('error', !$el.is(':checked'));
            } else if ($el.is('select')) {
                $el.parents('.input').toggleClass('error', $el.val().length <= 0 || $el.val() == 'null');
            } else {
                $el.parents('.input').toggleClass('error', $el.val().length <= 0);
            }
        });

        return this.$formWrp.find('.error').length <= 0;
    }

    invalidUsername() {
        let isError = !FormUtils.validateEmail(this.$formWrp.find('[name="username"]').val());
        this.$formWrp.find('[name="username"]').parents('.input').toggleClass('error', isError);
        return isError;
    }

    printTemplate() {
        if (this.currentTemplate == 'createuser') {
            this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
            if (!!this.formData.username) {
                this.$formWrp.find('[name="username"]').val(this.formData.username);
            }
            this.toggleLoader(false);
        } else if (this.currentTemplate == 'register') {
            this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
            let preferredCountries = ["it", "es", "gb", "us", "fr"];
            this.prefixField = intlTelInput(this.$formWrp.find('#phone')[0], {
                preferredCountries: preferredCountries,
                initialCountry: preferredCountries.indexOf(FormUtils.readConstantsIpCountry()) < 0 ? 'it' : FormUtils.readConstantsIpCountry(),
                separateDialCode: true
            });

            this.updatePrefixDependencies();

            this.printIntakeOptions();
            this.loadChildComponents(this);
            this.toggleLoader(false);
        } else if (this.currentTemplate == 'subscribe') {
            ScholarshipFormManager.checkSubscription(this.scholarshipDetails.id).then((subscriptions) => {
                if (!!subscriptions) {
                    this.userSubscriptions = subscriptions;
                    this.prefillCourse();
                }
                this.scholarshipUserKey = false;
                this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
                this.printIntakeOptions();
                this.toggleLoader(false);
            });
        } else if (this.currentTemplate == 'documents') {
            //TODO - per prova controllo l'url dello skilltest una volta in più... Sembra contare...
            ScholarshipFormManager.skilltest(this.scholarshipUserKey).then((url) => {
                if (url !== true && !!url) {
                    let html = '<iframe id="sdrabatempframe" src="' + url + '" style="height: 1px; width: 1px; visibility: hidden;"></iframe>';
                    $('body').append(html).find('#sdrabatempframe').on("load", (e) => {
                        $(e.currentTarget).remove();
                    });
                }
            });

            ScholarshipFormManager.getRelationDocs(this.scholarshipUserKey).then((resp) => {
                let docList = this.processUserDocs(resp);
                this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
                this.formData.userDocs = {};
                let html = '';
                for (let i in docList) {
                    let doc = docList[i];
                    this.formData.userDocs[doc['short_name']] = false;
                    let uploadedClass = '';
                    if (!!doc['dropbox_link']) {
                        uploadedClass = 'file-selected';
                        this.formData.userDocs[doc['short_name']] = doc['dropbox_link'];
                    }
                    if (!!doc.status && doc.status.toLowerCase() == 'approved') {
                        uploadedClass += ' locked';
                    }
                    let htmlMoreInfo = ''; // Assuming this variable is defined elsewhere in your code
                    if (doc['desc_' + this.serviceLocale] && doc['desc_' + this.serviceLocale].length > 0) {
                        htmlMoreInfo = `
                            <div class="collapse-item">
                                <div class="collapse-item-trigger">
                                    <div class="collapse-item-title">
                                        <div class="more-info">
                                            <div>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.moreInfo"]}</div>
                                        </div>
                                    </div>
                                    <img src="/fe-web/img/marangoni/icons/angle-right-black.svg" aria-hidden="true" class="collapse-item-arrow" />
                                </div>                                
                                <div class="collapse-item-content">
                                    <div class="collapse-item-content-price">
                                        <div class="desc">
                                            ${doc['desc_' + this.serviceLocale]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `;
                    }
                    html += `<div class="sch-doc-el ${uploadedClass}" data-id-doc="${doc['short_name']}">
                        <div class="collapse-container">
                            <div class="name">
                                <div>${doc['name_' + this.serviceLocale]}</div>
                            </div>
                            ${htmlMoreInfo}
                        </div>
                        <div class="actions">
                            <span class="filename">${doc['name']}</span>
                            <span class="new">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.newFile"]}</span>
                            <input type="file" id="${doc['short_name']}">
                            <label for="${doc['short_name']}" class="upload">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.upload"]}</label>
                        </div>
                    </div>`;
                }
                this.docValidationTriggered = false;
                this.$formWrp.find('#document-wrapper').append(html);
                this.toggleLoader(false);
                this.initCollapse();
            }, () => {
            });
        } else if (this.currentTemplate == 'skilltest') {
            if (this.coursesValues[this.formData.course].docs.indexOf('skill_test') >= 0) {
                ScholarshipFormManager.skilltest(this.scholarshipUserKey).then((url) => {
                    if (url === true) {
                        //Se ho già fatto lo skilltest vado oltre.
                        this.printNext();
                    } else if (!!url) {
                        this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
                        this.$formWrp.find('#skilltest').attr('src', url);
                        this.toggleLoader(false);
                    } else {
                        //Errore, qui non dovresti arrivarci
                        this.showPopup("genericError");
                    }
                }, () => {
                    //Errore, qui non dovresti arrivarci
                    this.showPopup("genericError");
                });
            } else {
                //Se non c'è uno skilltest da fare passo oltre.
                this.printNext();
            }
        } else {
            this.$formWrp.html(ScholarshipFormManager.printTemplate(this.currentTemplate));
            this.loadChildComponents(this);
            this.toggleLoader(false);
        }

        this.updateUrlStepParam();
        $('html, body').stop().animate({scrollTop: 0}, 300, 'swing');
    }

    validate($el) {
        if (!$el) {
            $el = this.$formWrp.find('.required');
        }
        $el.each((i, el) => {
            let $input = $(el);
            if (!$input.val()) {
                $input.parents('.input:eq(0)').addClass('error');
            } else {
                $input.parents('.input:eq(0)').removeClass('error');
                $('.password-rule').removeClass('error');
            }
        });
    }

    validateDocuments() {
        this.docValidationTriggered = true;
        let res = true;
        for (let i in this.formData.userDocs) {
            if (!this.formData.userDocs[i]) {
                this.$formWrp.find('.sch-doc-el[data-id-doc="' + i + '"]').addClass('error');
                res = false;
            }
        }

        if (res) {
            this.$formWrp.find('#document-wrapper .sch-doc-error-message').remove();
        } else {
            this.$formWrp.find('#document-wrapper').append(`
<div class="sch-doc-error-message">
    <span class="desc">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.checkDocs"]}</span>
</div>
            `);
        }

        return res;
    }

    toggleLoader(toggle) {
        this.$formWrp.toggleClass('loading', toggle);
    }

    printIntakeOptions() {
        let html = '';
        let printed = {};
        for (let i in this.filterMap) {
            if (!printed[i]) {
                let label = i;
                if (!!i && i.indexOf("-") >= 0) {
                    label = IMUtils.numberToMonth(i.split('-')[1]) + " " + i.split('-')[0];
                }
                html += `<option value="${i}">${label}</option>`;
                printed[i] = true;
            }
        }
        this.checkSingleOption(this.$formWrp.find('#intake').append(html));
    }

    printSchoolOptions() {
        this.$formWrp.find('#school option:not(.default-option)').remove();
        this.$formWrp.find('#school').parents('.select-container').removeClass('single-option').removeClass('error');
        if (!this.formData.intake || this.formData.intake == 'null') {
            this.$formWrp.find('#school option:not(.default-option)').remove();
            this.$formWrp.find('#school .default-option').prop('selected', true);
        } else {
            let html = '';
            let realOptions = {};
            for (let i in this.filterMap[this.formData.intake]) {
                if (this.campusValues[i]) {
                    if (!realOptions[this.campusValues[i].name]) {
                        realOptions[this.campusValues[i].name] = i;
                    } else {
                        realOptions[this.campusValues[i].name] += " " + i;
                    }
                }
            }

            let printed = {};
            for (let i in realOptions) {
                if (!printed[realOptions[i]]) {
                    html += `<option value="${realOptions[i]}">${i}</option>`;
                    printed[realOptions[i]] = true;
                }
            }
            this.checkSingleOption(this.$formWrp.find('#school').append(html));
        }
    }

    printDegreeOptions() {
        this.$formWrp.find('#degree option:not(.default-option)').remove();
        this.$formWrp.find('#degree').parents('.select-container').removeClass('single-option').removeClass('error');
        if (!this.formData.school || this.formData.school == 'null') {
            this.$formWrp.find('#degree option:not(.default-option)').remove();
            this.$formWrp.find('#degree .default-option').prop('selected', true);
        } else {
            let html = '';
            //Il doppio for serve perchè in questo punto potrei avere il valore multiplo del servizio per la scuola.
            let realOptions = [];
            for (let j in this.formData.school.split(" ")) {
                let schoolId = this.formData.school.split(" ")[j];
                for (let i in this.filterMap[this.formData.intake][schoolId]) {
                    if (realOptions.indexOf(i) <= 0) {
                        realOptions.push(i);
                    }
                }
            }

            let printed = {};
            for (let i in realOptions) {
                if (!printed[realOptions[i]]) {
                    html += `<option value="${realOptions[i]}">${realOptions[i]}</option>`;
                    printed[realOptions[i]] = true;
                }
            }
            this.checkSingleOption(this.$formWrp.find('#degree').append(html));
        }
    }

    printCourseOptions() {
        this.$formWrp.find('#course option:not(.default-option)').remove();
        this.$formWrp.find('#course').parents('.select-container').removeClass('single-option').removeClass('error');
        if (!this.formData.degree || this.formData.degree == 'null') {
            this.$formWrp.find('#course option:not(.default-option)').remove();
            this.$formWrp.find('#course .default-option').prop('selected', true);
        } else {
            let html = '';
            //Il doppio for serve perchè in questo punto potrei avere il valore multiplo del servizio per la scuola.
            let realOptions = [];
            for (let j in this.formData.school.split(" ")) {
                let schoolId = this.formData.school.split(" ")[j];
                for (let i in this.filterMap[this.formData.intake][schoolId][this.formData.degree]) {
                    let id = this.filterMap[this.formData.intake][schoolId][this.formData.degree][i];
                    realOptions[id] = this.coursesValues[id].title;
                }
            }
            let printed = {};
            for (let j in realOptions) {
                if (!printed[j]) {
                    html += `<option value="${j}">${realOptions[j]}</option>`;
                    printed[j] = true;
                }
            }
            this.checkSingleOption(this.$formWrp.find('#course').append(html));
        }
    }

    printCourseDocs() {
        if (this.$formWrp.find('.course-doc-wrapper').length > 0) {
            this.$formWrp.find('.course-doc-wrapper').remove();
        }
        if (!!this.formData.course && this.formData.course != 'null') {
            this.toggleLoader(true);
            let courseDocs = this.coursesValues[this.formData.course].docs;
            let html = '<div class="row course-doc-wrapper"><div class="cols-12"><ul class="doc-wrapper" id="course-docs">';
            for (let k in courseDocs) {
                let doc = this.allDocs[courseDocs[k]];
                if (!!doc && !!doc['name_' + this.serviceLocale]) {
                    let desc = '';
                    if (!!doc['desc_' + this.serviceLocale]) {
                        desc = ' - ' + doc['desc_' + this.serviceLocale];
                    }
                    html += `<li class="document"><span class="name">${doc['name_' + this.serviceLocale]}</span>${desc}</li>`
                }
            }
            html += '</ul></div></div>';
            this.$formWrp.find('.row').last().prepend(html);
            this.toggleLoader(false);
        }
    }

    checkSingleOption($select) {
        console.log('check single option');
        if ($select.find('option:not(.default-option)').length == 1) {
            console.log('has single option');
            $select.val($select.find('option:not(.default-option)').attr('value')).change();
            $select.siblings('.fake-single-option').html($select.find('option:not(.default-option)').html());
            $select.parents('.select-container').addClass('single-option');
        }
    }

    prefillCourse() {
        let prefillWithMe = false;
        for (let i in this.userSubscriptions) {
            if (typeof this.coursesValues[this.userSubscriptions[i]['courseid']] != 'undefined') {
                prefillWithMe = this.coursesValues[this.userSubscriptions[i]['courseid']];
                break;
            }
        }

        if (prefillWithMe) {
            let intake = prefillWithMe['intake'];
            let school = prefillWithMe['school_id'];
            let degree = prefillWithMe['groupname_name_' + this.serviceLocale];
            let course = prefillWithMe['courseid'];

            if (this.filterMap[intake][school][degree].indexOf(course) >= 0) {
                setTimeout(() => {
                    this.$formWrp.find('#intake').val(intake).change();
                    setTimeout(() => {
                        let realValue = this.$formWrp.find('#school option[value~="' + school + '"]').attr('value');
                        this.$formWrp.find('#school').val(realValue).change();
                        setTimeout(() => {
                            this.$formWrp.find('#degree').val(degree).change();
                            setTimeout(() => {
                                this.$formWrp.find('#course').val(course).change();
                            }, 100);
                        }, 100);
                    }, 100);
                }, 100);
            }
        }
    }

    processUserDocs(serviceDocs) {
        let courseDetails = this.coursesValues[this.formData.course];
        let courseDocs = [];
        for (let i in this.allDocs) {
            if (this.allDocs[i]['short_name'] != 'skill_test' && courseDetails.docs.indexOf(this.allDocs[i]['short_name']) >= 0) {
                let serviceDoc = {};
                if (!!serviceDocs[this.allDocs[i]['short_name']]) {
                    serviceDoc = serviceDocs[this.allDocs[i]['short_name']];
                }
                courseDocs.push(Object.assign({}, this.allDocs[i], serviceDoc));
            }
        }

        return courseDocs;
    }


    eraseDoc($file) {
        $file[0].type = "text";
        $file[0].type = "file";
        $file.parents('.sch-doc-el').removeClass('file-selected').removeClass('file-uploading');
        this.formData.userDocs[$file.parents('.sch-doc-el').data('idDoc')] = false;
    }

    updatePrefixDependencies() {
        if (!!this.prefixField) {
            this.formData.country = this.prefixField.getSelectedCountryData().iso2.toUpperCase()
            this.formData.prefix = this.prefixField.getSelectedCountryData().dialCode;
        }
    }

    validatePasswordRules(pwdStrength) {
        let pwdStrengthLabel = (pwdStrength == 0)
            ? `${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.pwdVeryWeak"]}`
            : (pwdStrength == 1)
                ? `${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.pwdBitWeak"]}`
                : (pwdStrength == 2)
                    ? `${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.pwdWeak"]}`
                    : `${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.pwdStrong"]}`;

        $('.strength-value').html('').html(pwdStrengthLabel);
        $('.password-title').attr('data-strength', pwdStrength);
    }

    validateCreatePassword(avoidRepeat) {
        let $repeat = this.$formWrp.find('[name="repeatpassword"]');
        let $pwd = this.$formWrp.find('[name="password"]');
        let pwd = $pwd.val();
        let $checkLength = this.$formWrp.find('.check-length');
        let $checkChar = this.$formWrp.find('.check-char');
        let $checkNum = this.$formWrp.find('.check-num');
        let pwdStrength = 3;

        if (pwd.length < 8) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkLength.addClass('error');
            pwdStrength--;
        } else {
            $checkLength.removeClass('error');
        }
        if (!/\d/.test(pwd)) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkNum.addClass('error');
            pwdStrength--;
        } else {
            $checkNum.removeClass('error');
        }
        if (!/[a-z]/.test(pwd) || !/[A-Z]/.test(pwd)) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkChar.addClass('error');
            pwdStrength--;
        } else {
            $checkChar.removeClass('error');
        }
        if (!avoidRepeat && $repeat.val() != $pwd.val()) {
            $repeat.parents('.input:eq(0)').addClass('error');
        }

        this.validatePasswordRules(pwdStrength);

        if ((pwd.length < 8) || (!/\d/.test(pwd)) || (!/[a-z]/.test(pwd) || !/[A-Z]/.test(pwd)) || ($repeat.val() != $pwd.val())) {
            this.toggleLoader(false);
            return false;
        }

        return true;
    }

    updateUrlStepParam() {
        let currentStep = this.templateStepName[this.currentTemplate];
        let newUrl = IMUtils.appendParameter(location.href, 'step', currentStep);
        let obj = {Url: newUrl};
        history.pushState(obj, '', newUrl);

        if (!!window._uxa) {
            window._uxa.push(['trackPageview', document.location.pathname]);
        }
    }

    initCollapse() {
        $(".collapse-item .collapse-item-trigger").click(function () {
            var $parent = $(this).parents('.collapse-item:first');
            $parent.toggleClass('open');
            $('.collapse-item-content', $parent).stop().slideToggle(300);
        });
    }
}

export default ScholarshipFormCtrl;