import RSVP from "rsvp";
import $ from "jquery";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class FormUtils {
    constructor() {
    }

    doCaptcha(form, $urlBackField, customCallback) {
        try {
            if (typeof window.grecaptcha !== 'undefined') {
                grecaptcha.ready(() => {
                    grecaptcha.execute(KD_MARANGONI_SITE_CONSTANTS.GOOGLE_RECAPTCHA_SECRET_CLIENT, {action: 'submit'}).then((token) => {
                        form.find('[name="recaptchaToken"]').val(token);
                        if (!customCallback) {
                            this._captchaCallback(form, $urlBackField);
                        } else {
                            customCallback();
                        }
                    });
                });
            } else if (typeof window.TencentCaptcha !== 'undefined') {
                let captcha = new TencentCaptcha(KD_COMMON_CONSTANTS.TENCENT_APP_ID, (res) => {
                    console.log('callback:', res);
                    if (res.ret === 0) {
                        form.find('[name="recaptchaToken"]').val(res.ticket);
                        form.find('[name="recaptchaRandom"]').val(res.randstr);
                        if (!customCallback) {
                            this._captchaCallback(form, $urlBackField);
                        } else {
                            customCallback();
                        }
                    }
                }, {});
                captcha.show();
            } else {
                //Errore, il recaptcha non va, il form non potrà essere submittato.
            }
        } catch (error) {
            // Load error. Please call the  load error handling function
            console.log("Captcha error!");
            console.log(error);
        }
    }

    _captchaCallback(form, $urlBackField) {
        if ($('[name="phone"]', form).length > 0) {
            let phone = $('[name="phone-code"]', form).val() + $('[name="phone_number"]', form).val();
            $('[name="phone"]', form).val(phone);
        }

        LandingUrlBuilder.updateUrl(form, $urlBackField);
        form.submit();
    }

    loadProgramme(school, timeContainer, checkPlaces) {
        return new RSVP.Promise((resolve, reject) => {
            let tc = '';
            if (!!timeContainer) {
                tc = '&tc=' + timeContainer;
            }
            let url = `/api/1.0/form/programme/${school}?site=${KD_COMMON_CONSTANTS.SITE}&locale=${KD_COMMON_CONSTANTS.LOCALE}${tc}`;
            if (checkPlaces) {
                url = `/api/1.0/form/programme/available/${school}?site=${KD_COMMON_CONSTANTS.SITE}&locale=${KD_COMMON_CONSTANTS.LOCALE}${tc}`;
            }

            $.ajax({
                url: url,
                method: "GET",
                contentType: "application/json",
                dataType: "json"
            }).then((data) => {
                resolve(data.body);
            });
        });
    }

    showLoader() {
        $('.kd-loader').addClass('show-me');
    }

    hideLoader() {
        $('.kd-loader').removeClass('show-me');
    }

    addInputErrorClass(input) {
        (input.parents('.input:first')).addClass('error');
        (input.parents('.ctm-checkbox:first')).addClass('error');
    }

    removeInputErrorClass(input) {
        (input.parents('.input:first')).removeClass('error');
        (input.parents('.ctm-checkbox:first')).removeClass('error');
    }

    addCheckboxErrorClass(input) {
        (input.parents('.ctm-checkbox:first')).addClass('error');
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    scrollToError($form) {
        let top = $form.find('.error:eq(0)').offset().top;
        top -= $('header').height();
        top -= 40;
        $('html, body').scrollTop(top);
    }

    resetSelect($select) {
        let emptyOpt = $select.find("option[value='null']").detach();
        $select.html('');
        $select.append(emptyOpt);
    }

    updatePrefixDependencies($form, $prefixField) {
        $form.find('[name="phone-code"]').val('+' + $prefixField.getSelectedCountryData().dialCode);
        $form.find('#country').val($prefixField.getSelectedCountryData().iso2.toUpperCase());
    }

    readFormIpCountry($form) {
        let ipCountry = $form.data('ipCountry');
        if (!ipCountry || ipCountry.indexOf('null') >= 0) {
            console.error("IPCOUNTRY NULL RILEVATA")
            ipCountry = 'it';
        }

        return ipCountry;
    }

    readConstantsIpCountry() {
        let ipCountry = KD_MARANGONI_PAGE_CONSTANTS.ipCountry;
        if (!ipCountry || ipCountry.indexOf('null') >= 0) {
            console.error("IPCOUNTRY NULL RILEVATA")
            ipCountry = 'it';
        }

        return ipCountry;
    }

    addFormListeners($form) {
        if ($form.find('#phone').length > 0) {
            let preferredCountries = ["it", "es", "gb", "us", "fr"];
            let ipCountry = this.readFormIpCountry($form);
            if (!ipCountry || preferredCountries.indexOf(ipCountry) < 0) {
                ipCountry = 'it';
            }

            let $prefixField = intlTelInput($form.find('#phone')[0], {
                preferredCountries: preferredCountries,
                initialCountry: ipCountry,
                separateDialCode: true
            });

            this.updatePrefixDependencies($form, $prefixField);
            $form.find('#phone').on("countrychange", () => {
                this.updatePrefixDependencies($form, $prefixField);
            });

            $form.find('#phone').on('keyup change', (e) => {
                $(e.currentTarget).val($(e.currentTarget).val().replace(/\D/g, ''));
                if ($(e.currentTarget).val().length > 11) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 11));
                }
            });
        }
        if ($form.find('#name').length > 0) {
            $form.find('#name').keyup((e) => {
                if ($(e.currentTarget).val().length > 40) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 40));
                }
            });
        }
        if ($form.find('#lastname').length > 0) {
            $form.find('#lastname').keyup((e) => {
                if ($(e.currentTarget).val().length > 40) {
                    $(e.currentTarget).val($(e.currentTarget).val().substr(0, 40));
                }
            });
        }
        $form.on('change', 'select[data-child-valorization]', (e) => {
            let $opt = $(e.currentTarget).find('option:selected');
            $opt.parents('form').find($opt.data('valorizeChild')).val($opt.data('childValue')).change();
        });

        if ($form.find("input[type='checkbox'][name='privacy']").length > 0 && $form.find(".hiddenPrivacy").length > 0) {
            $form.find("input[type='checkbox'][name='privacy']").change((e) => {
                $form.find(".hiddenPrivacy").val($(e.currentTarget).is(':checked') ? '0' : '1');
            });
        }

        if ($form.find("input[type='checkbox'][name='privacyProfiling']").length > 0 && $form.find(".hiddenPrivacyProfiling").length > 0) {
            $form.find("input[type='checkbox'][name='privacyProfiling']").change((e) => {
                $form.find(".hiddenPrivacyProfiling").val($(e.currentTarget).is(':checked') ? '1' : '0');
            });
        }

        if ($form.find("input[type='checkbox'][name='privacy-whatsapp']").length > 0 && $form.find(".hiddenWhatsapp").length > 0) {
            $form.find("input[type='checkbox'][name='privacy-whatsapp']").change((e) => {
                $form.find(".hiddenWhatsapp").val($(e.currentTarget).is(':checked') ? '1' : '0');
            });
        }

        $form.find("input[type=email]").keyup((event) => {
            if (this.validateEmail($(event.target).val())) {
                $(event.target).parents('.input:first').addClass('email-ok');
            } else {
                $(event.target).parents('.input:first').removeClass('email-ok');
            }
        });

        $form.find("input, textarea, select").keyup((event) => {
            this.removeInputErrorClass($(event.target));
        });

        $form.find("input, textarea, select").change((event) => {
            this.removeInputErrorClass($(event.target));
        });
    }

    validateForm(ev, $form) {
        let hasError = false;

        $("input, textarea, select", $form).each((index, element) => {
            if ($(element).hasClass('required') || $(element).is('.required-if-visible:visible')) {
                var tag = $(element).prop('tagName').toLowerCase();

                if (tag == "input") {
                    var type = $(element).attr('type');

                    if (type == "text" || type == "number") {
                        if ($(element).val().length <= 0) {
                            ev.preventDefault();
                            this.addInputErrorClass($(element));
                            hasError = true;
                        }
                    } else if (type == "email") {
                        if (!this.validateEmail($(element).val())) {
                            ev.preventDefault();
                            this.addInputErrorClass($(element));
                            hasError = true;
                        }
                    } else if (type == "checkbox") {
                        if (!$(element).is(':checked')) {
                            ev.preventDefault();
                            this.addInputErrorClass($(element));
                            hasError = true;
                        }
                    } else if (type == "tel") {
                        if ($(element).val().length <= 4) {
                            ev.preventDefault();
                            this.addInputErrorClass($(element));
                            hasError = true;
                        }
                    }
                } else if (tag == "textarea") {
                    if ($(element).val().length <= 0) {
                        this.addInputErrorClass($(element));
                        ev.preventDefault();
                        hasError = true;
                    }
                } else if (tag == "select") {
                    if ($(element).val() == 'null') {
                        this.addInputErrorClass($(element));
                        ev.preventDefault();
                        hasError = true;
                    }
                }
            } else {

            }
        });
        return hasError;
    }

    updateSchoolDependantFields(val, $form) {
        $form.find('.schoolDependant.location').val(val);

        val = val.toUpperCase();
        let area = 'Fashion';
        if (val == 'MID' || val == 'LOD') {
            area = 'Design';
        } else if (val == 'FIR') {
            area = 'Fashion;Art';
        } else if (val == 'MUF') {
            area = 'Fashion,Design';
        } else if (val == 'SHS' || val == 'SHT' || val == 'MMF') {
            //TODO - il fatto che l'area of study sia vuota implica una gestione ad hoc lato validazione BE
            area = '';
        }
        $form.find('.schoolDependant.areaOfStudy').val(area);
    }
}

export default new FormUtils();
