import {SmartComponent} from "smart-component-js";
import CookieManager from "../business/ImCookieManager";

class CrossPopupComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CrossPopupComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        if (CookieManager.isCrossPopupEnabled()) {
            this.$el.find('.close-icon').click(() => {
                CookieManager.disableCrossPopup();
                this.$el.remove();
            });
            this.$el.find('.btn-mrng').click(() => {
                CookieManager.disableCrossPopup();
            });
            this.$el.show();
        } else {
            this.$el.remove();
        }
    }
}

export default CrossPopupComponent;