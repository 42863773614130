import Utils from "../../common/business/Utils";
import Swiper from 'swiper';
import RSVP from "rsvp";

class IMUtils extends Utils {
    initCourseTable() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            $('.course-table').each((i, el) => {
                let $ct = $(el);
                let $titles = $ct.find('.ct-header .ct-cell');

                let html = '<div class="ct-swiper swiper"><div class="swiper-wrapper">';
                $ct.find('.section-target').each((i, el) => {
                    let $section = $(el);

                    let slide = `
                        <div class="ct-slide swiper-slide">
                            <div class="slide-section">
                                <div class="ct-title">${$($titles.get(0)).text()}</div>
                                <div class="ct-body">${$section.html()}</div>
                            </div>
                    `;
                    $section.parents('.ct-row').find('.ct-cell').each((i, el) => {
                        if (i > 0) {
                            slide += `
                        <div class="slide-section">
                            <div class="ct-title">${$($titles.get(i)).text()}</div>
                            <div class="ct-body">${$(el).html()}</div>
                        </div>
                    `;
                        }
                    });
                    slide += '</div>';
                    html += slide;
                })

                html += '</div></div>'
                $ct.html(html);

                let swiper = new Swiper($ct.find('.swiper').get(0), {
                    slidesPerView: 1.3
                });
            })
        }
    }

    /**
     * Metodo che aspetta che tutte le immagini all'interno del container siano caricate prima di risolvere la promise.
     * E' disponibile un timeout opzionale per forzare la promise dopo un lasso di tempo massimo.
     *
     * @param $container
     * @param timeout
     * @returns {*}
     */
    waitImagesLoaded($container, timeout) {
        return new RSVP.Promise((resolve, reject) => {
            let imgCheckerInterval = setInterval(() => {
                let allLoaded = true;
                $container.find('img:not(.img-error)').each((i, img) => {
                    if (!img.complete || typeof img.naturalWidth == 'undefined' || img.naturalWidth == 0) {
                        allLoaded = false;
                    }
                })

                if (allLoaded) {
                    clearInterval(imgCheckerInterval);
                    resolve(true);
                }
            }, 250);

            if (timeout) {
                clearInterval(imgCheckerInterval);
                setTimeout(() => {
                    resolve(true);
                }, timeout)
            }
        });
    }
}

export default new IMUtils();
