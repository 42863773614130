class ExternalTrackingManager {
    constructor() {
    }

    trackVideoPlay(title) {
        if (!title) {
            title = 'undefined title';
        }
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'video',
            'video-played': title
        });
    }
}

export default new ExternalTrackingManager();
