import LandingUrlBuilder from "./LandingUrlBuilder";
import RSVP from "rsvp";

class SalesforceManager {
    constructor() {
    }

    traceScoolarshipRegistration(formData) {
        //Submit form con redirect url per callback
        let $form = $('#salesforce-form-register');

        this.fillCommon($form, formData);

        $form.find('[data-name="FIRST NAME"]').val(formData.firstname);
        $form.find('[data-name="LAST NAME"]').val(formData.lastname);
        $form.find('[data-name="EMAIL"]').val(formData.username);
        $form.find('[data-name="DATE OF BIRTH"]').val(formData.date);
        $form.find('[data-name="COUNTRY"]').val(formData.country);
        $form.find('[data-name="TELEPHONE"]').val("+" + formData.prefix + formData['phone_number']);

        $form.find('[data-name="PROFILATION"]').val(!formData.privacyProfiling ? '0' : '1');
        $form.find('[data-name="SEND EMAIL"]').val(!formData.privacy ? '0' : '1');
        $form.find('[data-name="CONTACT PHONE"]').val(!formData.privacy ? '0' : '1');
        $form.find('[data-name="Whatsapp OptIn"]').val(!formData['privacy-whatsapp'] ? '0' : '1');

        LandingUrlBuilder.updateUrl($form, $form.find('.returnUrl'));

        $form.submit();
    }

    traceScoolarshipRegistrationAol(formData) {
        //Submit form con redirect url per callback
        let $form = $('#salesforce-form-register');

        this.fillCommon($form, formData);

        $form.find('[data-name="FIRST NAME"]').val(formData.userData.firstname);
        $form.find('[data-name="LAST NAME"]').val(formData.userData.lastname);
        $form.find('[data-name="EMAIL"]').val(formData.userData.email);
        $form.find('[data-name="DATE OF BIRTH"]').val(formData.userData.otherdata.birthday);
        $form.find('[data-name="COUNTRY"]').val(formData.userData.otherdata.country);
        $form.find('[data-name="TELEPHONE"]').val(formData.userData.otherdata.prefix + formData.userData.otherdata.phone);

        $form.find('[data-name="PROFILATION"]').val(!formData.userData.otherdata.privacy1 ? '0' : '1');
        $form.find('[data-name="SEND EMAIL"]').val(!formData.userData.otherdata.privacy2 ? '0' : '1');
        $form.find('[data-name="CONTACT PHONE"]').val(!formData.userData.otherdata.privacy2 ? '0' : '1');
        $form.find('[data-name="Whatsapp OptIn"]').val(!formData.userData.otherdata.privacy3 ? '0' : '1');

        LandingUrlBuilder.updateUrl($form, $form.find('.returnUrl'));

        let submitData = new FormData($form[0]);
        submitData.set("customUserSource", 'AOL');
        submitData.set("sinaptoStudyArea", formData.userData.otherdata.subarea);

        return new RSVP.Promise((resolve, reject) => {
            $.post({
                url: $form.attr('action'),
                processData: false,
                contentType: false,
                data: submitData
            }).then((resp) => {
                resolve();
            }, (resp) => {
                reject();
            });
        });
    }

    traceScoolarshipSubscription(formData, avoidRetUrl) {
        //Submit form con redirect url per callback
        let $form = $('#salesforce-form-subscribe');

        this.fillCommon($form, formData);

        for (let i in formData.userDocs) {
            let fieldName = this.getDocFieldByName(i);
            $form.find(fieldName).val(formData.userDocs[i]);
        }

        $form.find('[data-name="FIRST NAME"]').val(formData.userData.firstname);
        $form.find('[data-name="LAST NAME"]').val(formData.userData.lastname);
        $form.find('[data-name="EMAIL"]').val(formData.userData.email);
        $form.find('[data-name="DATE OF BIRTH"]').val(formData.userData.otherdata.birthday);
        $form.find('[data-name="COUNTRY"]').val(formData.userData.otherdata.country);
        $form.find('[data-name="TELEPHONE"]').val(formData.userData.otherdata.prefix + formData.userData.otherdata.phone);

        if (!avoidRetUrl) {
            LandingUrlBuilder.updateUrl($form, $form.find('.returnUrl'));
        }

        $form.submit();
    }

    fillCommon($form, formData) {
        $form.find('[data-name="INTAKE"]').val(formData.intake);
        $form.find('[data-name="AREA OF STUDY"]').val(formData.subArea);
        $form.find('[data-name="SUBAREA"]').val("Fashion Business");
        $form.find('[data-name="COURSE"]').val(formData.courseName);
        $form.find('[data-name="PROGRAMME"]').val(formData.courseId).attr('data-custom-ty-programme', formData.degreeSfname);
        $form.find('[data-name="SCHOOL"]').val(formData.schoolSfname.toLowerCase());

        let location = formData.schoolSfname.toLowerCase().split(' ')[0];
        $form.find('[data-name="SCHOOL LOCATION"]').val(location.charAt(0).toUpperCase() + location.slice(1));
    }

    getDocFieldByName(name) {
        if (name == 'diploma_certificate' || name == 'last_diploma' || name == 'university_degree' || name == 'notarial_copy_high_school_diploma') {
            return '[data-name="diploma certificate"]';
        } else if (name == 'Certificato Voti \n' || name == 'transcript_of_grades') {
            return '[data-name="Transcript"]';
        } else if (name == 'copy_of_passport') {
            return '[data-name="copy of passport"]';
        } else if (name == 'personal_statement') {
            return '[data-name="personal statement scholarship request"]';
        } else if (name == 'reference_letter_1') {
            return '[data-name="Reference letter 1"]';
        } else if (name == 'reference_letter_2') {
            return '[data-name="Reference letter 2"]';
        } else if (name == 'curriculum_vitae') {
            return '[data-name="curriculum vitae"]';
        } else if (name == 'portfolio') {
            return '[data-name="Portfolio"]';
        } else if (name == 'Progetto Speciale \n') {
            return '[data-name="Project"]';
        } else if (name == 'six_creative_ideas') {
            return '[data-name="Six Creative Ideas"]';
        } else if (name == 'Video di presentazione \n') {
            return '[data-name="moodboard"]';
        } else if (name == 'english_language_certificate' || name == 'language_certificate' || name == 'english_language_certificate_postgraduate') {
            return '[data-name="English Language Certificate"]';
        }
    }
}

export default new SalesforceManager();
