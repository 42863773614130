import {SmartComponent} from "smart-component-js";

class WizardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "WizardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;

        this.init();
    }

    init() {
        this.updateUrlStepParam(null);
        this.originalResultHtml = this.$el.find('.wizard-step-result').html();

        this.$el.find('.wizard-restart').click((e) => {
            e.preventDefault();
            this.$el.removeClass('wizard-over');
            this.$el.find('.wizard-step-result').removeClass('active');
            this.$el.find('.wizard-title, .wizard-progress, .wizard-step-container').addClass('active');
            this.$el.find('.wizard-step .step-options input').prop('checked', false);
            this.$el.find('.next-step').removeClass('active');
            this.$el.find('.next-step').show(0);
            this.$el.find('.wizard-step-container .wizard-step.active').removeClass('active');
            this.$el.find('.wizard-step-container .wizard-step.step-' + 1).addClass('active');
            this.$el.find('.wizard-progress .wizard-progress-step.active').removeClass('active');
            this.$el.find('.wizard-progress .wizard-progress-step').eq(0).addClass('active');

            this.$el.find('.wizard-step-result').html(this.originalResultHtml);
            this.updateUrlStepParam(null);
        });

        this.$el.find('.wizard-close-icon, .wizard-close').click((e) => {
            e.preventDefault();
            if (!!document.referrer) {
                document.location.href = document.referrer;
            } else {
                document.location.href = KD_MARANGONI_SITE_CONSTANTS.HOME_URL;
            }
        });

        this.$el.find('.show-result').click((e) => {
            e.preventDefault();
            this.showResults();
        });

        this.$el.find('.wizard-step-container .wizard-step input').change(function () {
            var parent = $(this).parents('.step-options:first');

            if ($('input:checked', parent).length > 0) {
                $('.next-step').addClass('active');
            } else {
                $('.next-step').removeClass('active');
            }

            if (parent.hasClass('step-option-max-2')) {
                if ($('input:checked', parent).length >= 2) {
                    $('input:not(:checked)', parent).attr('disabled', true);
                    $('input:not(:checked)', parent).attr('disabled', true);
                } else {
                    $('input:not(:checked)', parent).removeAttr('disabled');
                    $('input:not(:checked)', parent).removeAttr('disabled');
                }
            }

        });

        this.$el.find('.next-step').click((e) => {
            let $clicked = $(e.currentTarget);
            e.preventDefault();

            this.goNext($clicked);
        });

        this.$el.on('click', '.search-result-item--corso', (e) => {
            let $clicked = $(e.currentTarget);
            document.location.href = $clicked.find('.action').attr('href');
        });

        this.$el.find('.back-step').click((e) => {
            let $clicked = $(e.currentTarget);
            e.preventDefault();

            if ($clicked.hasClass('active')) {
                this.goBack($clicked);
            }
        });

        this.$el.find('.go-to-anchor').click((e) => {
            let $clicked = $(e.currentTarget);
            e.preventDefault();

            this.$el.find('.wizard-title, .wizard-progress, .wizard-step-container').addClass('active');
            $('.wizard-step-recap').removeClass('active');
            this.$el.find('.next-step').show();
            this.goBack($clicked, parseInt($clicked.data('step')));
        });
    }

    goNext($clicked) {
        if ($clicked.hasClass('active')) {
            this.$el.find('.back-step').addClass('active');
            $clicked.removeClass('active');
            var activeStep = parseInt($('.wizard-step-container .wizard-step.active').data('step'));
            var nextStep = activeStep + 1;
            if ($('.wizard-step-container .wizard-step.step-' + nextStep).length <= 0) { // step finiti, mostra il risultato
                this.$el.find('.back-step').removeClass('active').hide();
                $('.wizard-step-container, .wizard-step').removeClass('active');
                $('.wizard-step-recap').addClass('active');
                $('.wizard-progress').removeClass('active');
                $clicked.hide(0);

                // fill the recap
                $('.wizard-step-container .wizard-step').each((i, el) => {
                    let $step = $(el);

                    var $index = parseInt($step.data('step'));
                    var $options = $('.step-options', $step);
                    var stepValue = "";

                    if ($options.hasClass('step-option-max-2')) {
                        if ($('input:checked', $options).length > 1) {
                            stepValue = this.getOptionLabel($('input:checked', $options).eq(0)) + $options.data('congiunzione') + this.getOptionLabel($('input:checked', $options).eq(1));
                        } else {
                            stepValue = this.getOptionLabel($('input:checked', $options));
                        }
                    } else {
                        stepValue = this.getOptionLabel($('input:checked', $options));
                    }

                    $('.wizard-step-recap span.input-' + $index).html(stepValue);
                });
            } else {
                if (activeStep == 1) {
                    this.manageVisibility();
                } else if (activeStep == 4) {
                    this.manageCampusVisibility();
                }
                $('.wizard-step-container .wizard-step.active').removeClass('active');
                $('.wizard-step-container .wizard-step.step-' + nextStep).addClass('active').find('input').change();
                $('.wizard-progress .wizard-progress-step.active').removeClass('active');
                $('.wizard-progress .wizard-progress-step').eq(nextStep - 1).addClass('active');
            }
            this.updateUrlStepParam(nextStep);
            $('.wizard-overflow').scrollTop(0);
        }
    }

    updateUrlStepParam(nextStep) {
        let lastStep = parseInt($('[data-step]').last().attr('data-step'));
        let currentStep = parseInt(location.search.split('step=')[1]);

        if (lastStep == currentStep && $('.step-1 input:checked').length > 0) {
            this.showResults('backToResults');
            return;
        }

        if (nextStep == null) {
            nextStep = parseInt($('.wizard-container .wizard-step.active').data('step'));
        }
        var url = new URL(location.href);
        var newUrl = url.href.replace(new RegExp('\\?step=[\\d]+'), '') + '?step=' + nextStep;
        var obj = {Url: newUrl};
        history.pushState(obj, '', newUrl);

        if (!!window._uxa) {
            window._uxa.push(['trackPageview', document.location.pathname]);
        }
    }

    goBack($clicked, nextStep) {
        if (!nextStep) {
            nextStep = parseInt($('.wizard-step-container .wizard-step.active').data('step')) - 1;
        }

        this.updateUrlStepParam(nextStep);
        this.$el.find('.back-step').toggleClass('active', nextStep != 1);

        $('.wizard-step-container .wizard-step.active').removeClass('active');
        $('.wizard-step-container .wizard-step.step-' + nextStep).addClass('active').find('input').change();
        $('.wizard-progress .wizard-progress-step.active').removeClass('active');
        $('.wizard-progress .wizard-progress-step').eq(nextStep - 1).addClass('active');
    }

    clearPreviousSteps() {
        let classToRemove = 'active';
        $('.wizard-step-recap').removeClass(classToRemove);
        $('.wizard-title').removeClass(classToRemove);
        $('.wizard-progress').removeClass(classToRemove);
        $('.wizard-step-container').removeClass(classToRemove);
        $('.wizard-step').removeClass(classToRemove);
        $('.next-step').hide();
        $('.prev-step').hide();
    }

    showResults(refresh) {
        this.loadResults().then((data) => {
            this.printResults(data);
            this.clearPreviousSteps();

            // mostra il risultato
            $('.wizard-step-result').addClass('active');
            $('.wizard').addClass('wizard-over');
            $('.courses-number b').text($('.search-result-container > div').length);
            if (data && data.body.length === 0) {
                let label = `${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.wizard.noresult"]}`;
                $('.no-courses div').html(label);
            }

            if (refresh != 'backToResults') {
                this.updateUrlStepParam();
            }

            if (data && data.body.length > 0) {
                $('.wizard .search-result-container').owlCarousel({
                    rtl: this.isRtl,
                    margin: 30,
                    nav: true,
                    navigation: true,
                    navText: ['<div class="wizard-result-prev"></div>', '<div class="wizard-result-next"></div>'],
                    responsive: {
                        0: {
                            items: 1,
                            margin: 15
                        },
                        550: {
                            items: 2
                        },
                        900: {
                            items: 3
                        }
                    }
                });
            }
        })
    }

    getOptionLabel($option) {
        if ($option.length > 1) {
            let html = '';

            $option.each((i, el) => {
                    html += `<li>${$(el).siblings('label').text()}</li>`;
                }
            );

            return '<ul>' + html + '</ul>';
        } else if ($option.siblings('label.multiple-labels').length > 0) {
            return $option.siblings('label.multiple-labels').find('span:not(.disabled)').text();
        } else {
            return $option.siblings('label').text();
        }
    }

    getArea() {
        let result = [];
        this.$el.find('.step-4 input:checked').each((i, el) => {
            let $el = $(el);
            if (!!parseInt($el.val())) {
                if ($el.val().indexOf(',') > 0) {
                    let vals = $el.val().split(',');
                    for (let i = 0; i < vals.length; i++) {
                        result.push(parseInt(vals[i]));
                    }
                } else {
                    result.push(parseInt($el.val()));
                }
            }
        });

        return result;
    }

    getCampus() {
        let result = [];
        this.$el.find('.step-5 input:checked').each((i, el) => {
            let $el = $(el);
            if (!!parseInt($el.val())) {
                if ($el.val().indexOf(',') > 0) {
                    let vals = $el.val().split(',');
                    for (let i = 0; i < vals.length; i++) {
                        result.push(parseInt(vals[i]));
                    }
                } else {
                    result.push(parseInt($el.val()));
                }
            }
        });
        return result;
    }

    getTypology() {
        let step1 = this.$el.find('.step-1 input:checked').val();
        let step2 = this.$el.find('.step-2 input:checked').val();
        let step3 = this.$el.find('.step-3 input:checked').val();
        let combo = step2 + step3;

        let stepValues = KD_WIZARD_TYPOLGY_MAPPING[step1];
        let result = [];
        if (!!stepValues[combo]) {
            result = stepValues[combo];
        } else if (!!stepValues[step3]) {
            result = stepValues[step3];
        }

        return result.filter(i => !!parseInt(i)).map(i => parseInt(i));
    }

    manageVisibility() {
        let step1 = this.$el.find('.step-1 input:checked').val();
        let hidingThings = KD_WIZARD_HIDE_TABLE[step1];

        let $options = this.$el.find('.step-2 .step-options .custom-option, .step-3 .step-options .custom-option');
        $options.show();
        if (!!hidingThings && hidingThings.length > 0) {
            $options.each((i, el) => {
                let $opt = $(el);
                let val = $opt.find('input').attr('value');

                $opt.toggle(hidingThings.indexOf(val) < 0);
            })
        }
        $options.each((i, el) => {
            let $opt = $(el);
            if ($opt.find('label span[data-label-dep="' + step1 + '"]').length > 0) {
                $opt.find('label span').addClass('disabled');
                $opt.find('label span[data-label-dep="' + step1 + '"]').removeClass('disabled');
            } else if ($opt.find('label span[data-label-dep="default"]').length > 0) {
                $opt.find('label span').addClass('disabled');
                $opt.find('label span[data-label-dep="default"]').removeClass('disabled');
            }
        })
    }

    manageCampusVisibility() {
        let visibleCampuses = [];
        this.$el.find('.step-4 input:checked').each((i, el) => {
            let n = $(el).attr('id').split('step-4-option-')[1];
            visibleCampuses = visibleCampuses.concat(KD_WIZARD_CAMPUS_HIDE_TABLE[n]);
        });

        this.$el.find('.step-5 [type="checkbox"]').prop('checked', false);
        this.$el.find('.step-5 .custom-option').hide();
        for (let i in visibleCampuses) {
            this.$el.find('.step-5 #step-5-option-' + visibleCampuses[i]).parents('.custom-option').show();
        }
    }

    loadResults() {
        let data = {
            idSite: this.siteId,
            locale: this.locale,
            idsTypology: this.getTypology(),
            idsArea: this.getArea(),
            idsCampus: this.getCampus()
        };
        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/course/wizard`, {
            method: 'POST'
        }, data);
    }

    printResults(data) {
        let html = '';

        if (data.body) {
            for (let i = 0; i < data.body.length; i++) {
                let c = data.body[i];
                if (c.subtitle === undefined) {
                    c.subtitle = "";
                }
                let lang = "EN";
                if (!!c.locale && c.locale.length > 0) {
                    lang = c.locale.split('_')[0].toUpperCase();
                }
                let url = c.url;
                if (!!url && url.length > 0) {
                    let arrUrl = c.url.split('#');
                    url = arrUrl[0] + "?itm_campaign=WIZARD_ALL_FDA_210000_other_" + lang + "_ALL";
                    if (!!arrUrl[1] && arrUrl[1].length > 0) {
                        url = url + "#" + arrUrl[1];
                    }
                }


                html += `
        
<div class="search-result-item--wrapper">
    <div class="search-result-item--corso">
        <img src="${c.desktopImage}" />
        <div class="content">
            <div class="title">
                ${c.title}
            </div>
            <div class="description">
                ${c.subtitle}
            </div>
            <div class="tags">
                <div class="tag">${c.typology}</div>
            </div>
            ${this.printCampus(c.campus)}
            <a href="${url}" class="action btn-mrng">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.findMore"]}</a>
        </div>
    </div>
</div>
        
        `;
            }
        }

        this.$el.find('.search-result-container').html(html);
    }

    printCampus(campusString) {
        let html = '';
        if (!!campusString && campusString.length > 0) {
            let campuses = campusString.split('|');
            html = '<div class="tags">';

            for (let i = 0; i < campuses.length; i++) {
                html += `
    <div class="tag">${campuses[i].trim()}</div>
        `;
            }

            html += '</div>';
        }

        return html;
    }
}

export default WizardComponent;