import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class ThankYouComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ThankYouComponent";
        super(element, parentComponent, params);

        $('body').addClass('negative-logo');
    }
}

export default ThankYouComponent;