import {SmartComponent} from "smart-component-js";
import CookieManager from "../business/ImCookieManager";

class ProjectDetailComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ProjectDetailComponent";

        super(element, parentComponent, params);

        this.init();
    }

    init() {
        window.dontTouchMyCookie = true;
        this.loadChildComponents();

        CookieManager.setGalleryCookie();
    }
}

export default ProjectDetailComponent;