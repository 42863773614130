import {SmartComponent} from "smart-component-js";

class MapComponentCn extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "MapComponentCn";
        super(element, parentComponent, params);
        this.$map = $(element);
        this.icon = "/fe-web/img/marangoni/icons/map-marker.png";
        this.init();
    }

    init() {

        console.log("map component cn");

        let that = this;
        let marker;
        let infoWindow;

        let $wtfu = $('.wtfu-map', this.$map);
        let cords = $wtfu.data('cords').split(",");
        let point = new BMap.Point(cords[1], cords[0]);
        let map = new BMap.Map($wtfu[0].id);

        map.enableScrollWheelZoom(true);
        map.enableKeyboard();

        map.addControl(new BMap.MapTypeControl({
            anchor: BMAP_ANCHOR_TOP_LEFT
        }));
        map.addControl(new BMap.NavigationControl({
            anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
            type: BMAP_NAVIGATION_CONTROL_SMALL
        }));

        let icon = new BMap.Icon(that.icon, new BMap.Size(50, 50));
        let opts = {
            width: 330,
            height: 150
        }

        let contentString = $('.map-marker-description', that.$map).html();
        infoWindow = new BMap.InfoWindow(contentString, opts);

        marker = new BMap.Marker(point, {icon: icon});
        map.centerAndZoom(point, 15);
        map.addOverlay(marker);

        marker.addEventListener("click", () => {
            map.openInfoWindow(infoWindow, map.getCenter());
        });
    }
}

export default MapComponentCn;