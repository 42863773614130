import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class ProjectsListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ProjectsListingComponent";
        super(element, parentComponent, params);
        this.form = $(element);
        this.type = element.dataset.type;
        this.siteId = element.dataset.siteId;
        this.locale = element.dataset.locale;
        this.modalItemsName = element.dataset.modalItemsName;
        this.labelReadMore = element.dataset.labelReadMore;

        this.page = 1;
        this.filterValues = [];

        this.init();
    }

    confirmModal() {
        let $checkedItems = $('[name="' + this.modalItemsName + '"]:checked');
        this.filterValues = [];
        if ($checkedItems.length > 0) {
            let label = "";
            for (var i = 0; i < $checkedItems.length; i++) {
                let $item = $($checkedItems[i]);
                label += $item.data("label");
                this.filterValues[i] = $item.val();

                if (i != $checkedItems.length - 1) {
                    label += ", "
                }
            }
            $(this.element).find(".input-placeholder-value.modal-trigger").text(label)
        } else {
            let label = $(this.element).find(".input-placeholder-value.modal-trigger").data("defaultText");
            $(this.element).find(".input-placeholder-value.modal-trigger").text(label)
        }
    }

    doAjaxCall() {
        let itemFilter = "";
        if (this.filterValues.length > 0) {

            this.filterValues.forEach((it) => {
                itemFilter += `&${this.modalItemsName}=${it}`;
            })
        }

        let qs = `site=${this.siteId}&locale=${this.locale}&page=${this.page}&typeId=${this.type}${itemFilter}`;

        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/search/projects/list?${qs}`, {
            method: 'GET',
        });
    }

    createResultItem(item) {
        var desc = item.truncatedDescription;
        if (desc.length > 99) {
            desc = desc.substring(0, 99);
            desc = desc + '...';
        }
        return `
            <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                        <div class="search-result-item--corso">
                            <img src="${item.thumbImage}" />
                            <div class="content">

                                <div class="title">
                                    ${item.title}
                                </div>
                                <div class="description">
                                    ${desc}
                                </div>
                                
                                ${this.printTagElements(item.courseDegreeLevel)}
                                ${this.printTagElements(item.country)}
                                ${this.printTagElements(item.course)}
                                
                                ${this.printHashtagElements(item.tags)}
                                
                                <a href="${item.url}" class="action btn-mrng">${this.labelReadMore}</a>
                            </div>
                        </div>
                    </div>`;
    }

    printTagElements(label) {
        if (label) {
            return `<div class="tags">
                    <div class="tag">${label}</div>
                </div>`;
        } else {
            return "";
        }

    }

    printHashtagElements(hashtagList) {
        if (hashtagList && hashtagList.length > 0) {
            let html = '<div class="tags">';
            for (var i = 0; i < hashtagList.length; i++) {
                html += `<div class="hashtag">
                &nbsp;&nbsp;#${hashtagList[i]}
               </div>`;
            }

            html += "</div>";
            return html;
        } else {
            return "";
        }

    }
}

export default ProjectsListingComponent;