import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class TeacherStaffPopupComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "TeacherStaffPopupComponent";
        super(element, parentComponent, params);

        this.$trigger = $(element);

        this.popupId = this.$trigger.data('popupId');

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/professor/course/list';
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.serviceData = {
            'site': this.siteId,
            'locale': this.locale,
            'area': this.$trigger.data('area'),
            'degreelevel': this.$trigger.data('degreelevel'),
            'campus': this.$trigger.data('campus'),
            'role': this.$trigger.data('role'),
        };

        this.$popup = false;
        this.details = {};

        this.init();
    }

    init() {
        this.$trigger.click(() => {
            if (!this.$popup) {
                this.loadPrintAndMakeCoffee();
            } else {
                this.$popup.show();
            }
        });
    }

    loadPrintAndMakeCoffee() {
        const urlParams = Object.entries(this.serviceData).reduce((serviceUrl, item) => {
            return `${serviceUrl}${serviceUrl === '' ? '' : '&'}${item[0]}=${item[1]}`
        }, '');
        const url = `${this.restBaseUrl}?${urlParams}`;

        youserxp.ajax(url, {
            method: 'GET',
        }).then(response => {
            this.printPopup(response.body);
        }).catch();

    }

    printPopup(data) {
        this.details = {};
        let list = '';

        for (let i in data) {
            let prof = data[i];
            this.details[prof.id] = prof;

            list += `<a class="teacher-item" data-prof-id="${prof.id}"><strong>${prof.surname}</strong> ${prof.name}</a>`;
        }

        let html = `
        <div class="teacher-staff-popup-wrapper modal-mrng" data-id="${this.popupId}">
            <div class="fake-header">
                <div class="logo"></div>
                <div class="close">
                    ${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.close"]}
                    <div class="cross"></div>
                </div>
            </div>
            <div class="detail"></div>
            <div class="list">${list}</div>
        </div>
        `;

        this.$popup = $(html);
        $('body').append(this.$popup);

        this.$popup.find('.close').click(() => {
            this.$popup.hide();
        });
        this.$popup.find('.list .teacher-item').click((e) => {
            let $item = $(e.currentTarget);

            this.$popup.find('.list .teacher-item.active').removeClass('active');
            $item.addClass('active');

            this.printDetail($item.data('profId'));

            if ($(window).width() <= 768) {
                this.$popup.scrollTop(0);
            }
        });
        this.$popup.find('.list .teacher-item:eq(0)').click();
        this.$popup.show();
    }

    printDetail(profId) {
        let profData = this.details[profId];

        let html = `<span class="title">${profData.surname} ${profData.name}</span>`;

        if (!!profData.campus) {
            html += `<span class="subtitle">${profData.campus}</span>`;
        }
        if (!!profData.area) {
            html += `<span class="subsubtitle">${profData.area}</span>`;
        }
        if (!!profData.courses) {
            html += `<div class="description"><ul>`;
            for (let i in profData.courses) {
                let crs = profData.courses[i];
                html += `<li>${crs.title}</li>`;
            }
            html += `</ul></div>`;
        }
        if (!!profData.url) {
            html += `<a class="link" href="${profData.url}" target="_blank">${KD_MARANGONI_SITE_TRANSLATIONS['marangoni.common.discoverMore']}</a>`;
        }


        this.$popup.find('.detail').html(html);
    }
}

export default TeacherStaffPopupComponent;