import {SmartComponent} from "smart-component-js";
import $ from "jquery";
import FormUtils from "../common/FormUtils";
import IMUtils from "../business/IMUtils";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class FormComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "FormComponent";
        super(element, parentComponent, params);
        this.form = $(element);
        this.prefixField = null;

        this.ipCountry = FormUtils.readFormIpCountry(this.form);

        this.$urlBackField = this.form.find('[name="ret_url"].returnUrl');

        this.init();
    }

    init() {
        if (this.form.find('#school:not(input[type="hidden"])').length > 0 && this.form.find('.schoolDependant').length > 0) {
            this.form.find('#school').change((e) => {
                let val = $(e.currentTarget).find('option:selected').attr('value').toLowerCase();
                FormUtils.updateSchoolDependantFields(val, this.form);
            });
        } else if (this.form.find('#campusSchoolParent').length > 0) {
            let val = this.form.find('#campusSchoolParent').val();
            FormUtils.updateSchoolDependantFields(val, this.form);
        }

        if (this.form.find(".btn-go-back").length > 0 && IMUtils.isMarangoniReferrer()) {
            this.form.find(".btn-go-back").show().click(() => {
                IMUtils.goBack();
            })
        }

        if (this.form.find("#school").length > 0 && this.form.find("#programme").length > 0) {
            let $programme = this.form.find("#programme");

            if (this.form.find("#school").is('input[type="hidden"]')) {
                let school = this.form.find("#school").val();
                this.schoolChanged(school, $programme);
            } else {
                this.form.find("#school").change((e) => {
                    FormUtils.showLoader();
                    FormUtils.resetSelect($programme);

                    let school = this.form.find("#school").val();
                    this.schoolChanged(school, $programme);
                });
            }

            this.form.find("#programme").change((e) => {
                let $programme = this.form.find("#programme");
                let $subarea = this.form.find("#subarea");

                FormUtils.resetSelect($subarea);

                if (!!$programme.val() && this.programmeData[$programme.val()]) {
                    let html = '';
                    for (let s in this.programmeData[$programme.val()]) {
                        let subarea = this.programmeData[$programme.val()][s];
                        html += `<option value="${subarea.value}">${subarea.label}</option>`;
                    }
                    $subarea.append(html);
                }
            });
        }
        if (this.form.find("#eventtype").length > 0) {
            this.form.find("#eventtype").change((e) => {
                let value = this.form.find("#eventtype").val();
                this.form.find("input[type='hidden'][name='00N20000003n7sg']").attr("value", value);
            });
        }

        this.form.submit((ev) => {

            if (!this.form.find('[name="recaptchaToken"]').val()) {
                if (FormUtils.validateForm(ev, this.form)) {
                    FormUtils.scrollToError(this.form);
                } else {
                    ev.preventDefault();
                    FormUtils.doCaptcha(this.form, this.$urlBackField);
                }

            }

        });

        FormUtils.addFormListeners(this.form);
    }

    schoolChanged(school, $programme) {
        if (!!school && school != 'null') {
            this.programmeData = false;
            FormUtils.loadProgramme(school).then((data) => {
                let html = '';

                for (let c in data) {
                    html += `<option value="${data[c].value}">${data[c].label}</option>`;

                    if (!!data[c].data) {
                        if (!this.programmeData) {
                            this.programmeData = {};
                        }

                        this.programmeData[data[c].value] = data[c].data;
                    }
                }
                $programme.append(html);
                if (this.form.find("#subarea-wrapper").length > 0) {
                    this.form.find("#subarea-wrapper").toggle(!!this.programmeData);
                }

                FormUtils.hideLoader();
            });
        }
    }
}

export default FormComponent;