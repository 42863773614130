import Cookies from "js-cookie";
import CookieManager from "../../common/business/CookieManager";

class ImCookieManager extends CookieManager {
    constructor() {
        super();
        this.GALLERY_COOKIE_NAME = "galleryPreviousUrl";
        this.CROSS_POPUP_COOKIE_NAME = "crossPopupCookie";
        this.SCHOLARSHIP_COOKIE_NAME = "PHPSESSID";
    }

    getGalleryCookie() {
        return Cookies.get(this.GALLERY_COOKIE_NAME);
    }

    setGalleryCookie() {
        Cookies.set(this.GALLERY_COOKIE_NAME, document.location.href);
    }

    eraseGalleryCookie() {
        Cookies.remove(this.GALLERY_COOKIE_NAME);
    }

    disableCrossPopup() {
        Cookies.set(this.CROSS_POPUP_COOKIE_NAME, true);
    }

    isCrossPopupEnabled() {
        return !Cookies.get(this.CROSS_POPUP_COOKIE_NAME);
    }

    getScholarshipCookie() {
        return Cookies.get(this.SCHOLARSHIP_COOKIE_NAME);
    }

    setScholarshipCookie(token) {
        Cookies.set(this.SCHOLARSHIP_COOKIE_NAME, token);
    }
}

export default new ImCookieManager();
