
import {SmartComponentManager} from 'smart-component-js';
import {SmartComponent} from 'smart-component-js';
SmartComponent.prototype.smart_destroy = function () {
    console.log(this.componentReferenceName + " destroyed");
    this.mutationObserver.disconnect();
    SmartComponentManager.removeComponentInstance(this._componentId);
    if (this.element.parentNode) {
        this.element.parentNode.removeChild(this.element);
    }
    /* if(this.element.isConnected){
         //ie 11 doesn't support remove method
         if(this.element.remove){
             this.element.remove();
         }else{
             this.element.parentElement.removeChild(this.element);
         }
     }*/

    // for all properties
    for (const prop of Object.getOwnPropertyNames(this)) {
        delete this[prop];
    }
};
export {SmartComponentManager,SmartComponent};