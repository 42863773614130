import {SmartComponent} from "smart-component-js";
import IMUtils from "../business/IMUtils";

class AiNewsCardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiNewsCardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.template = this.$el.data('template');

        this.init();
    }

    init() {
        // this.$el.on('ai-error', () => {
        //     this.$el.remove();
        //     this.$el.parents('.owl-carousel').trigger('reinit.owl.carousel');
        // });
        this.$el.on('ai-ready', () => {
            let aiData = this.$el.data('aiData');
            if (aiData != null) {
                let html = '';

                if (this.template == 'MAIN') {
                    html += this.printMainCard(aiData);
                } else {
                    html += this.printCard(aiData);
                }

                this.$el.html(html);
            }
        });
    }

    printCard(aiData) {
        let startDate = false;
        let formatter = false;
        let infoStartDate = false;

        if (!!aiData.date) {
            startDate = new Date(aiData.date);
            infoStartDate = aiData.infoStartDate;
            let options = {day: '2-digit', month: 'long', year: 'numeric'};

            if (aiData.description == 'EVENT') {
                options.hour = 'numeric';
                options.minute = 'numeric';
                options.literal = '-';
            }

            formatter = new Intl.DateTimeFormat(KD_MARANGONI_SITE_CONSTANTS.JS_LOCALE, options);
        }

        let campus;
        if (aiData.key.entityName == 'event') {
            campus = aiData.description3;
        }

        return `
            <div class="text-wrapper">
                ${!aiData.title ? '' : ('<div class="title">' + IMUtils.cutText(aiData.title, 75) + '</div>')}
                ${!aiData.subtitle ? '' : ('<div class="subtitle">' + IMUtils.cutText(aiData.subtitle, 79) + '</div>')}
            </div>
            ${!campus ? '' : ('<div class="subtitle">' + IMUtils.cutText(campus, 79) + '</div>')}
            <div class="type">${aiData.key.entityName == 'event' ? KD_MARANGONI_SITE_TRANSLATIONS['marangoni.newsEvent.EVENT'] : KD_MARANGONI_SITE_TRANSLATIONS['marangoni.newsEvent.NEWS']}</div>
            ${(!startDate || !formatter) ? '' : `
            <div class="date">
                ${formatter.format(startDate)}${!infoStartDate ? '' : ' ' + infoStartDate}
            </div>
            `}
            <a href="${aiData.url}" class="bordered-cta black">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>
            <img src="${!aiData.imageMobile ? 'https://be-stage.ggeedu.it/marangoni/entities/news/3Y_NEWS130x130.png' : aiData.imageMobile}" class="news-item-image" data-img-size="130x113" style="max-width: 130px;">
        `;
    }

    printMainCard(aiData) {
        let startDate = new Date(aiData.date);
        let infoStartDate = aiData.infoStartDate;
        let options = {day: '2-digit', month: 'long', year: 'numeric'};

        let formatter = new Intl.DateTimeFormat(KD_MARANGONI_SITE_CONSTANTS.JS_LOCALE, options);

        let campus;
        if (aiData.key.entityName == 'event') {
            campus = aiData.description3;
        }

        return `
            <picture class="quote-image">
                <source media="(max-width: 768px)" srcset="${!aiData.imageMobile ? (!aiData.image ? '/fe-web/img/marangoni/placeholder/Placeholder_News_Desktop.jpg' : aiData.image) : aiData.imageMobile}">
                <img src="${!aiData.image ? '/fe-web/img/marangoni/placeholder/Placeholder_News_Desktop.jpg' : aiData.image}" data-img-size="375x275" alt="Alt image">
            </picture>
            <div class="main-news-detail-content">
                <div class="title">${aiData.title}</div>
                ${!campus ? '' : ('<div class="subtitle">' + IMUtils.cutText(campus, 79) + '</div>')}
                ${!isNaN(startDate.getTime()) ? '' : `
                <div class="date">
                    <div class="date">${formatter.format(startDate)}${!infoStartDate ? '' : ' ' + infoStartDate}</div>
                </div>
                `}
                <a href="${aiData.url}" class="bordered-cta">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.findMore"]}</a>
            </div>
        `;
    }
}

export default AiNewsCardComponent;