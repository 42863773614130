class TrackerManager {
    constructor(params = {}) {
        if (!window.KDTrackerInstance) {
            this.init(params);
        }
    }

    init() {
        if (!!window.youserxp) {
            if (!window.KDTrackerInstance) {

                window.KDTrackerInstance = window.youserxp.tracker.getInstance({
                    trackingId: KD_COMMON_CONSTANTS.TRAKING_ID
                });

                window.KDTrackerReadyPromise = window.KDTrackerInstance.initializePromise;
            }
        } else {
            setTimeout(() => {
                this.init();
            }, 200);
        }
    }

    getClientId() {
        return window.KDTrackerReadyPromise.then(() => {
            return window.KDTrackerInstance.clientId;
        });
    }

    track(eventType, params) {
        return window.KDTrackerReadyPromise.then(() => {
            let p = $.extend({}, params);

            return window.KDTrackerInstance.send(eventType, p);
        })
    }

    // trackPageView() {
    // this.trackingTypes = ["LOGIN", "LOGOUT", "INSERT", "UPDATE", "DELETE", "COPY", "PAGE_VIEW", "APP_SCREEN_VIEW", "EVENT", "LOG", "ERROR"];
    // window.KDTrackerInstance.send("PAGE_VIEW", {params: 'params'}, "token")
    //     return window.KDTrackerInstance.send("PAGE_VIEW");
    // }
}


export default new TrackerManager();
