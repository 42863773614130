import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class ArticleListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ArticleListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/article';

        this.$el = $(element);
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.page = 1;
        this.filterValues = {
            campus: [],
            area: [],
            macroarea: [],
            year: 'latest'
        };

        this.init();
    }

    init() {
        $('.kd-modal-confirm-btn').click((e) => {
            let $modal = $(e.currentTarget).parents('.modal-mrng');
            this.confirmModal($modal);
            this.search();
        });

        $('#search').click(() => {
            this.search();
        })

        $('#loadMore').click(() => {
            this.loadMoreItems();
        });

        if (document.location.href.indexOf('category=') > 0) {
            let cat = document.location.href.split('category=')[1];
            cat = cat.split('&')[0];
            this.filterValues.category = cat;
        }

        this.search(true);
    }

    onModalConfirm($modal) {
        let $modalCategory = $modal.attr('id').split('_')[1];
        let $checkedItems = $modal.find(':checked');
        let applyFilter = $modalCategory == 'campus' ? this.filterValues.campus : ($modalCategory == 'area' ? this.filterValues.area : this.filterValues.macroarea);
        applyFilter.length = 0;
        let $modalTrigger = $(this.element).find(".input-placeholder-value.kd-" + $modalCategory + "-modal-trigger");
        let label = "";
        if ($checkedItems.length > 0) {
            for (let i = 0; i < $checkedItems.length; i++) {
                let $item = $($checkedItems[i]);
                label += $item.data("label");
                applyFilter[i] = $item.val();

                if (i != $checkedItems.length - 1) {
                    label += ", "
                }
            }
        } else {
            label = $modalTrigger.data("defaultText");
        }

        $modalTrigger.text(label);
    }

    createResultItem(item) {
        let title = item.title;
        let subtitle = item.subtitle;
        if (!!title && title.length > 75) {
            let purifier = `<div>${title}</div>`;
            title = $(purifier).text().substr(0, 73) + '...';
        }
        if (!!subtitle && subtitle.length > 79) {
            let purifier = `<div>${subtitle}</div>`;
            subtitle = $(purifier).text().substr(0, 77) + '...';
        }
        let campus = item.campus;
        return `
        
                <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                    <div class="news-item">
                        <div class="text-wrapper">
                            ${!title ? '' : `<div class="title">${title}</div>`}
                            ${!subtitle ? '' : `<div class="subtitle">${subtitle}</div>`}
                        </div>
                        ${!campus ? '' : `<div class="subtitle">${campus}</div>`}
                        ${!item.startDateTimestamp ? '' : `<div class="date">${this.printDate(item.startDateTimestamp, item.type)}</div>`}
                        <a href="${item.url}" class="bordered-cta black">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>
                        ${!item.thumbImage ? '<img src="/fe-web/img/marangoni/placeholder/newsthumb.png" class="news-item-image"/>' : `<img src="${item.thumbImage}" class="news-item-image"/>`}
                    </div>
                </div>
        
        `;
    }

    printDate(timestamp, type) {
        let timDate = new Date(timestamp);
        timDate = new Date(timDate.getTime() + (timDate.getTimezoneOffset() * 60000));

        let res = timDate.toLocaleDateString(this.jsLocale, {day: 'numeric', month: 'long'});
        res += ' - ';
        res += timDate.toLocaleTimeString(this.jsLocale, {hour: 'numeric', hour12: false, minute: 'numeric'});

        return res;
    }
}

export default ArticleListingComponent;