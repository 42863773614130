import {SmartComponent} from "smart-component-js";

class LatestTribuneComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "LatestTribuneComponent";
        super(element, parentComponent, params);
        this.siteId = element.dataset.siteId;
        this.locale = element.dataset.locale;

        this.$el = $(element);

        this.init();
    }

    init() {
        console.log('test from component')
    }
}

export default LatestTribuneComponent;