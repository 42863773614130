import {SmartComponent} from "smart-component-js";

class PopupVideoThumbsComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ContactToolComponent";
        super(element, parentComponent, params);
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.$el = $(element);
        this.$owl = this.$el.find('.owl-carousel');
        this.init();
    }

    carouselInit() {
        this.$owl.owlCarousel({
            items: 2.5,
            margin: 15,
            rtl: this.isRtl,
            nav: true,
            navigation: true,
            loop: true,
            dots: false,
            navText: ['<span class="am-prev"><img src="/fe-web/img/marangoni/icons/long-arrow-left-gray.svg" /></span>',
                '<span class="am-next"><img src="/fe-web/img/marangoni/icons/long-arrow-right-gray.svg" /></span>'],
        });
    }

    videoSrcManager() {
        let mainVideo = this.$el.find('.popup-main');
        $(document).on('click', '.popup-thumbs .start', (e) => {
            $(mainVideo).find('video source').attr('src', $(e.currentTarget).find('video source').attr('src'));
            $(mainVideo).find('video').attr('poster', $(e.currentTarget).find('video').attr('poster'));
            $(mainVideo).find('.title').html($(e.currentTarget).find('.title').html());
            $(mainVideo).find('.subtitle').html($(e.currentTarget).find('.subtitle').html());
            let videoUpdated = $(mainVideo).find('video')[0];
            $(videoUpdated).get(0).load();
        });
    }

    init() {
        this.videoSrcManager();
        this.carouselInit();
    }

}

export default PopupVideoThumbsComponent;