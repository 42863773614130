import {SmartComponent} from "smart-component-js";

class NoHeaderComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "NoHeaderComponent";
        super(element, parentComponent, params);

        this.$flyingCta = false;
        this.$flyerCta = false;
        this.$el = $(element);

        this.isOpen = false;

        this.init();
    }

    init() {
        $(document).scroll(() => {
            let top = $('html, body').scrollTop();
            this.$el.toggleClass('scrolled', top > 10);

            if (!!this.$flyingCta) {
                let flyCtaFly = this.$flyingCta.offset().top < ($('html, body').scrollTop() + parseInt(this.$el.height()));
                this.$flyingCta.css('visibility', flyCtaFly ? 'hidden' : 'visible');
                this.$flyerCta.toggle(flyCtaFly);
            }
        });

    }
}

export default NoHeaderComponent;