import {SmartComponent} from "smart-component-js";

class AiProjectCardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiProjectCardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        this.$el.on('ai-error', () => {
            this.$el.remove();
            this.$el.parents('.owl-carousel').trigger('reinit.owl.carousel');
        });
        this.$el.on('ai-ready', () => {
            let aiData = this.$el.data('aiData');
            if (aiData != null) {
                this.$el.html(`
                <img src="${aiData.image}" class="main-image"/>
                ${!aiData.title ? '' : `<div class="title">${aiData.title}</div>`}
                ${!aiData.subtitle ? '' : `<div class="author">${aiData.subtitle}</div>`}
                <a href="${aiData.url}" class="bordered-cta main-color">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.talentProjects.view"]}</a>
                `);
            }
        });
    }
}

export default AiProjectCardComponent;