import {SmartComponent} from "smart-component-js";
import RSVP from "rsvp";
import $ from "jquery";
import FormUtils from "../common/FormUtils";
import IMUtils from "../business/IMUtils";
import LandingUrlBuilder from "../business/LandingUrlBuilder";

class OpenLessonFormComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "OpenLessonFormComponent";
        super(element, parentComponent, params);
        this.form = $(element);
        this.prefixField = null;

        this.ipCountry = FormUtils.readFormIpCountry(this.form);

        this.dayLight = this.form.data('dayLight');
        this.timeContainer = this.form.data('timeContainer') || '';
        this.londonDayLight = this.form.data('londonDayLight');

        this.$urlBackField = this.form.find('[name="ret_url"].returnUrl');

        this.init();
    }

    init() {
        if (this.form.find('#phone').length > 0) {
            this.prefixField = intlTelInput(this.form.find('#phone')[0], {
                preferredCountries: ["it", "es", "gb", "us", "fr"],
                initialCountry: this.ipCountry,
                separateDialCode: true
            });

            this.updatePrefixDependencies();
            this.form.find('#phone').on("countrychange", () => {
                this.updatePrefixDependencies();
            });
        }
        this.form.on('change', 'select[data-child-valorization]', (e) => {
            let $opt = $(e.currentTarget).find('option:selected');
            $opt.parents('form').find($opt.data('valorizeChild')).val($opt.data('childValue')).change();
        });

        if (this.form.find('#school').length > 0 && this.form.find('.schoolDependant').length > 0) {
            this.form.find('#school').change((e) => {
                let val = $(e.currentTarget).find('option:selected').attr('value').toLowerCase();
                FormUtils.updateSchoolDependantFields(val, this.form);
            });
        } else if (this.form.find('#campusSchoolParent').length > 0) {
            let val = this.form.find('#campusSchoolParent').val();
            FormUtils.updateSchoolDependantFields(val, this.form);
        }

        if (this.form.find(".btn-go-back").length > 0 && IMUtils.isMarangoniReferrer()) {
            this.form.find(".btn-go-back").show().click(() => {
                IMUtils.goBack();
            })
        }

        if (this.form.find("#school").length > 0 && this.form.find("#programme").length > 0) {
            this.form.find("#school").change((e) => {
                FormUtils.showLoader();
                let $programme = this.form.find("#programme");
                let $subarea = this.form.find("#subarea");
                let $time = this.form.find("#time");
                FormUtils.resetSelect($programme);
                FormUtils.resetSelect($subarea);
                FormUtils.resetSelect($time);

                let school = this.form.find("#school").val();
                if (!!school && school != 'null') {
                    this.programmeData = false;
                    this.updateProgramme();
                }
                let startDateValue = this.form.find("#school").find(":selected").attr("data-start-date");
                let startDate = this.form.find("input[type='hidden'][name='00N20000003n7sq']");
                startDate.attr("value", startDateValue);
                let campus = this.form.find("input[type='hidden'][name='business_unit_code']");
                campus.attr("value", school);
            });

            this.form.find("#programme").change((e) => {
                let $programme = this.form.find("#programme");
                let $subarea = this.form.find("#subarea");
                let $time = this.form.find("#time");

                FormUtils.resetSelect($subarea);
                FormUtils.resetSelect($time);

                if (!!$programme.val() && this.programmeData[$programme.val()]) {
                    let html = '';
                    for (let s in this.programmeData[$programme.val()]) {
                        let subarea = this.programmeData[$programme.val()][s];
                        html += `<option value="${subarea.value}">${this.capitalizeAndToLower(subarea.label)}</option>`;
                    }
                    $subarea.append(html);
                }
            });

            this.form.find("#subarea").change((e) => {
                let $time = this.form.find("#time");
                $('.startDate').val('');

                this.updateTimeSelect();
            });

            this.form.find("#time").change((e) => {
                let $time = this.form.find("#time");
                let $type = this.form.find("#eventtype");
                if ($time.val() && $time.val().indexOf('-') > 0) {
                    $('.startDate').val($time.val().split('-')[0]);
                }
                FormUtils.resetSelect($type);
                this.updateLocationSelect();
            });

            this.form.find("#time").change((e) => {
                let startDateValue = this.form.find("#time").val().split(" ")[0].trim().split('/');
                let startTimeValue = this.form.find("#time").val().split(" ")[1].trim().split("-")[0].trim().split(".");
                let startDate = new Date();
                startDate.setDate(startDateValue[0]);
                startDate.setMonth(parseInt(startDateValue[1]));
                startDate.setFullYear(startDateValue[2]);

                let diff = 0;
                if (this.form.find("#school").val().indexOf('lond') >= 0) {
                    diff = !this.londonDayLight ? 0 : 1;
                } else {
                    diff = !this.dayLight ? 1 : 2;
                }
                startDate.setHours(parseInt(startTimeValue[0]) - diff);
                startDate.setMinutes(startTimeValue[1]);
                startDate.setSeconds(0, 0);

                this.form.find("input[type='hidden'][name='00N20000003n7sq']").attr("value", IMUtils.printDate(startDate));
            });

            this.form.find("#eventtype").change((e) => {
                let $input = this.form.find("#eventtype");
                this.form.find("input[type='hidden'][name='00N20000003n7sg']").attr("value", $input.val());
                if (!!$input.find('option:selected').data('campaignId')) {
                    this.form.find("[name='CORE_CRM_Campaign__c']").attr("value", $input.find('option:selected').data('campaignId'));
                }
            });
        }

        if (this.form.find("input[type='checkbox'][name='privacy']").length > 0 && this.form.find(".hiddenPrivacy").length > 0) {
            this.form.find("input[type='checkbox'][name='privacy']").change((e) => {
                this.form.find(".hiddenPrivacy").val($(e.currentTarget).is(':checked') ? '0' : '1');
            });
        }

        if (this.form.find("input[type='checkbox'][name='privacyProfiling']").length > 0 && this.form.find(".hiddenPrivacyProfiling").length > 0) {
            this.form.find("input[type='checkbox'][name='privacyProfiling']").change((e) => {
                this.form.find(".hiddenPrivacyProfiling").val($(e.currentTarget).is(':checked') ? '1' : '0');
            });
        }

        if (this.form.find("input[type='checkbox'][name='privacy-whatsapp']").length > 0 && this.form.find(".hiddenWhatsapp").length > 0) {
            this.form.find("input[type='checkbox'][name='privacy-whatsapp']").change((e) => {
                this.form.find(".hiddenWhatsapp").val($(e.currentTarget).is(':checked') ? '1' : '0');
            });
        }

        console.log("FormComponent");

        console.log(this.form);

        this.form.submit((ev) => {

            if (!this.form.find('[name="recaptchaToken"]').val()) {
                ev.preventDefault();
                if (FormUtils.validateForm(ev, this.form)) {
                    FormUtils.scrollToError(this.form);
                } else {
                    this.checkPlacesOnSubmit(ev).then((res) => {
                        if (res) {
                            FormUtils.doCaptcha(this.form, this.$urlBackField);
                        }
                    });
                }
            }
        });

        (this.form.find("input[type=email]")).keyup((event) => {
            if (FormUtils.validateEmail($(event.target).val())) {
                ($(event.target).parents('.input:first')).addClass('email-ok');
            } else {
                ($(event.target).parents('.input:first')).removeClass('email-ok');
            }
        });

        (this.form.find("input, textarea, select")).keyup((event) => {
            FormUtils.removeInputErrorClass($(event.target));
        });

        (this.form.find("input, textarea, select")).change((event) => {
            FormUtils.removeInputErrorClass($(event.target));
        });

    }

    updateTimeSelect() {
        let $programme = this.form.find("#programme");
        let $subarea = this.form.find("#subarea");
        let $option = $subarea.find('option:selected');
        // console.log($option.text().toUpperCase());
        let $time = this.form.find("#time");

        let val = $time.val();

        FormUtils.resetSelect($time);
        let html = '';
        for (let s in this.programmeData[$programme.val()]) {
            let subarea = this.programmeData[$programme.val()][s];
            if ($option.text().toUpperCase() === subarea.label.toUpperCase()) {
                for (let t in subarea.data) {
                    let timeValue = subarea.data[t];
                    html += `<option value="${timeValue.value}" ${(val && (timeValue.value == val)) ? 'selected="selected"' : ''}>${timeValue.label}</option>`;
                }
            }
        }
        $time.append(html);
    }

    checkPlacesOnSubmit(ev) {
        return new RSVP.Promise((resolve, reject) => {
            this.updateProgramme().then(() => {
                this.updateTimeSelect();
                this.updateLocationSelect();

                if (FormUtils.validateForm(ev, this.form)) {
                    FormUtils.scrollToError(this.form);
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });
    }

    updateLocationSelect() {
        let $programme = this.form.find("#programme");
        let $subarea = this.form.find("#subarea");
        let $option = $subarea.find('option:selected');
        let $time = this.form.find("#time");
        let $type = this.form.find("#eventtype");

        let val = $type.val();

        FormUtils.resetSelect($type);
        let html = '';
        for (let s in this.programmeData[$programme.val()]) {
            let subarea = this.programmeData[$programme.val()][s];
            if ($option.text().toUpperCase() === subarea.label.toUpperCase()) {
                for (let t in subarea.data) {
                    let timeValue = subarea.data[t];
                    if (timeValue.value === $time.val()) {
                        for (let l in timeValue.data) {
                            let location = timeValue.data[l];
                            html += `<option value="${location.value}" data-campaign-id="${location.data}" ${(val && (location.value == val)) ? 'selected="selected"' : ''}>${location.label}</option>`;
                        }
                    }
                }
            }
        }
        $type.append(html);
    }

    updateProgramme() {
        let school = this.form.find("#school").val();
        let $programme = this.form.find("#programme");
        return FormUtils.loadProgramme(school, this.timeContainer, true).then((data) => {
            let html = '';

            //Creo la struttura per gestire le tendine
            for (let c in data) {
                if (!!data[c].data) {
                    if (!this.programmeData) {
                        this.programmeData = {};
                    }

                    this.programmeData[data[c].value] = data[c].data;
                }
            }

            //Rimuovo le opzioni che portano ad un vicolo cieco.
            this.cleanEmptyProgramme();

            //Stampo solamente le opzioni di primo livello che esistono.
            for (let c in data) {
                if (!!this.programmeData[data[c].value]) {
                    let label = this.capitalizeAndToLower(data[c].label);
                    html += `<option value="${data[c].value}">${label}</option>`;
                }
            }
            $programme.append(html);
            this.form.find("#subarea-wrapper").toggle(!!this.programmeData);

            FormUtils.hideLoader();
        });
    }

    cleanEmptyProgramme() {
        for (let k in this.programmeData) {
            for (let i in this.programmeData[k]) {
                if (!this.programmeData[k][i].data || this.programmeData[k][i].data.length <= 0) {
                    delete this.programmeData[k][i];
                }
            }

            if (!this.programmeData[k] || this.programmeData[k].length <= 0) {
                delete this.programmeData[k];
            }
        }
    }

    updatePrefixDependencies() {
        this.form.find('[name="phone-code"]').val('+' + this.prefixField.getSelectedCountryData().dialCode);
        this.form.find('#country').val(this.prefixField.getSelectedCountryData().iso2.toUpperCase());
    }

    capitalizeAndToLower(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }
}

export default OpenLessonFormComponent;