import {SmartComponent} from "smart-component-js";

class ScholarshipDetailCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipDetailCtrl";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.init();
    }

    init() {
    }
}

export default ScholarshipDetailCtrl;