import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class NewsEventListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "NewsEventListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/news-event';

        this.$el = $(element);
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");

        this.page = 1;
        this.filterValues = {
            campus: [],
            year: 'latest',
            category: '',
            type: ''
        };

        this.init();
    }

    init() {
        $('.kd-modal-confirm-btn').click((e) => {
            let $modal = $(e.currentTarget).parents('.modal-mrng');
            this.confirmModal($modal);
            this.search();
        });

        $('#search').click(() => {
            this.search();
        })

        $('#loadMore').click(() => {
            this.loadMoreItems();
        });

        if (document.location.href.indexOf('category=') > 0) {
            let cat = document.location.href.split('category=')[1];
            cat = cat.split('&')[0];
            this.filterValues.category = cat;
        }

        this.readFilters();

        this.search(true);
    }

    onModalConfirm($modal) {
        if ($modal.is('#modal_campus')) {
            let $schoolCheckedItems = $modal.find('[name="campus"]:checked');
            this.filterValues.campus = [];
            let $schoolModalTrigger = $(this.element).find(".input-placeholder-value.kd-campus-modal-trigger");
            if ($schoolCheckedItems.length > 0) {
                let label = "";
                for (let i = 0; i < $schoolCheckedItems.length; i++) {
                    let $item = $($schoolCheckedItems[i]);
                    label += $item.data("label");
                    this.filterValues.campus[i] = $item.val();

                    if (i != $schoolCheckedItems.length - 1) {
                        label += ", "
                    }
                }
                $schoolModalTrigger.text(label)
            } else {
                let label = $schoolModalTrigger.data("defaultText");
                $schoolModalTrigger.text(label)
            }
        }
    }

    createResultItem(item) {
        let title = item.title;
        let subtitle = item.subtitle;
        if (!!title && title.length > 75) {
            let purifier = `<div>${title}</div>`;
            title = $(purifier).text().substr(0, 73) + '...';
        }
        if (!!subtitle && subtitle.length > 79) {
            let purifier = `<div>${subtitle}</div>`;
            subtitle = $(purifier).text().substr(0, 77) + '...';
        }
        let campus = item.campus;
        return `
        
                <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                    <div class="news-item">
                        <div class="text-wrapper">
                            ${!title ? '' : `<div class="title">${title}</div>`}
                            ${!subtitle ? '' : `<div class="subtitle">${subtitle}</div>`}
                        </div>
                        ${!campus ? '' : `<div class="subtitle">${campus}</div>`}
                        <div class="type">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.newsEvent." + item.type]}</div>
                        ${!item.startDateTimestamp ? '' : `<div class="date">${this.printDate(item.startDateTimestamp, item.type, item.infoStartDate)}</div>`}
                        <a href="${item.url}" class="bordered-cta black">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>
                        ${!item.thumbImage ? '<img src="/fe-web/img/marangoni/placeholder/newsthumb.png" class="news-item-image"/>' : `<img src="${item.thumbImage}" class="news-item-image"/>`}
                    </div>
                </div>
        
        `;
    }

    printDate(timestamp, type, infoStartDate) {
        let timDate = new Date(timestamp);
        timDate = new Date(timDate.getTime() + (timDate.getTimezoneOffset() * 60000));

        let res = timDate.toLocaleDateString(this.jsLocale, {day: 'numeric', month: 'long'});
        if (type === 'EVENT') {
            res += ' - ' + KD_MARANGONI_SITE_TRANSLATIONS['marangoni.common.from'] + ' ';
            res += timDate.toLocaleTimeString(this.jsLocale, {hour: 'numeric', hour12: false, minute: 'numeric'});
        }
        if (infoStartDate) {
            res += ' ';
            res += infoStartDate;
        }

        return res;
    }

    onBeforeAjaxCall(qs) {
        window.history.replaceState(null, document.title, '?qs=' + encodeURIComponent(qs.split('?')[1]));
        return qs;
    }

    readFilters() {
        let params = document.location.search;
        if (params.length > 1 && params.indexOf('qs=') >= 0) {
            let qs = params.split('qs=')[1];
            if (qs.indexOf('&') >= 0) {
                qs = qs.split('&')[0];
            }

            qs = decodeURIComponent(qs);

            let splitted = qs.split('&');
            let filterObj = {};

            for (let i in splitted) {
                let filter = splitted[i];
                if (filter.indexOf('=') > 0) {
                    filter = filter.split('=');

                    if (!filterObj[filter[0]]) {
                        filterObj[filter[0]] = [];
                    }
                    filterObj[filter[0]].push(filter[1]);
                }
            }

            if (!!filterObj['type'] && filterObj['type'].length == 1) {
                this.$el.find('.search-filters #modal_type select[name="type"]').val(filterObj['type'][0]);
                $('#modal_type .hoax-input-filter-options .item.selected').removeClass('selected');
                $('#modal_type .hoax-input-filter-options .item[data-value="' + filterObj['type'][0] + '"]').addClass('selected');
                this.confirmModal($('#modal_type'));
            }
            if (!!filterObj['year'] && filterObj['year'].length == 1) {
                this.$el.find('.search-filters #modal_year select[name="year"]').val(filterObj['year'][0]);
                $('#modal_year .hoax-input-filter-options .item.selected').removeClass('selected');
                $('#modal_year .hoax-input-filter-options .item[data-value="' + filterObj['year'][0] + '"]').addClass('selected');
                this.confirmModal($('#modal_year'));
            }
            if (!!filterObj['campus']) {
                for (let i in filterObj['campus']) {
                    let c = filterObj['campus'][i];
                    this.$el.find('.search-filters #modal_campus input[name="campus"][value*="' + c + '"]').prop('checked', true);
                }
                this.confirmModal($('#modal_campus'));
                // this.confirmCheckboxModal('campus');
            }
            if (!!filterObj['page']) {
                this.page = filterObj['page'][0];
            }

            this.search();
        }
    }
}

export default NewsEventListingComponent;