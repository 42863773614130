import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class BaseListingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "BaseListingComponent";
        super(element, parentComponent, params);

        this.avoidFirstSearc = false;
    }

    init() {
        $('.kd-modal-confirm-btn').click((e) => {
            let $modal = $(e.currentTarget).parents('.modal-mrng');
            this.confirmModal($modal);
            this.search();
        })

        $('#search').click(() => {
            this.search();
        })

        $('#loadMore').click(() => {
            this.loadMoreItems();
        });

        if (!!this.$el && this.$el.find('#eraseFilters').length > 0) {
            this.defaultFilters = $.extend(true, {}, this.filterValues);
            this.$el.find('#eraseFilters').click(() => {
                if (typeof this.onBeforeEraseFilters == 'function') {
                    this.onBeforeEraseFilters();
                }
                this.filterValues = $.extend(true, {}, this.defaultFilters);
                this.$el.find('.input-placeholder .modal-trigger').each((i, el) => {
                    let $mt = $(el);
                    $mt.text($mt.data('defaultText'));
                });

                this.search();
            });
        }

        if(!this.avoidFirstSearc) {
            this.search(true);
        }
    }

    confirmModal($modal) {
        let $select = $modal.find('select');
        let filterName = $select.attr('name');
        let filterValue = $select.val();
        this.filterValues[filterName] = filterValue;

        let $filterTrigger = this.$el.find('.modal-trigger.kd-' + filterName + '-modal-trigger');
        let filterLabel = '';
        if (!filterValue) {
            filterLabel = $filterTrigger.data('defaultText');
        } else {
            filterLabel = $select.find('option:selected').text();
        }
        $filterTrigger.text(filterLabel);

        if (typeof this.onModalConfirm == 'function') {
            this.onModalConfirm($modal, filterName, filterValue, filterLabel);
        }
    }

    search(firstInit) {
        this.page = 1;
        this.doAjaxCall().then((resp) => {
            let html = "";
            if (resp.body.result.length < 1) {
                let text = !this.customNoResultText ? KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.filterNoResults"] : this.customNoResultText;

                html += `
                <div class="no-result">${text}</div>
                `;
                $(this.element).find(".search-result-container").html(html);
            } else {
                for (var i = 0; i < resp.body.result.length; i++) {
                    html += this.createResultItem(resp.body.result[i]);
                }

                $(this.element).find(".search-result-container").html(html);
                $('#counterNumber').text(resp.body.totalRows);

                if (!firstInit && $(window).width() <= 768) {
                    let top = $(this.element).find(".search-result").offset().top;
                    top = top - $('header').height();
                    $('html, body').stop().animate({scrollTop: top}, 500, 'swing');
                }

                if ((this.page * resp.body.pageSize) < resp.body.totalRows) {
                    $(this.element).find("#loadMore").show();
                } else {
                    $(this.element).find("#loadMore").hide();
                }
            }
        });
    }

    loadMoreItems() {
        let prevPosition = $('html, body').scrollTop();

        this.page = this.page + 1;
        this.doAjaxCall().then((resp) => {
            let html = "";
            for (var i = 0; i < resp.body.result.length; i++) {
                html += this.createResultItem(resp.body.result[i]);
            }

            $(this.element).find(".search-result-container").append(html);
            if ((this.page * resp.body.pageSize) < resp.body.totalRows) {
                $(this.element).find("#loadMore").show();
            } else {
                $(this.element).find("#loadMore").hide();
            }

            $('html, body').scrollTop(prevPosition);
        });
    }

    doAjaxCall() {
        let qs = this.restBaseUrl + `?site=${this.siteId}&locale=${this.locale}&page=${this.page}`;
        for (let key in this.filterValues) {
            if (!!this.filterValues[key]) {
                if (typeof this.filterValues[key] == 'object') {
                    for (let i in this.filterValues[key]) {
                        qs += `&${key}=${this.filterValues[key][i]}`;
                    }
                } else {
                    qs += `&${key}=${this.filterValues[key]}`;
                }
            }
        }

        if (typeof this.onBeforeAjaxCall == 'function') {
            qs = this.onBeforeAjaxCall(qs);
        }

        return youserxp.ajax(qs, {
            method: 'GET',
        });
    }

    createResultItem(item) {
        return '' + item;
    }
}

export default BaseListingComponent;