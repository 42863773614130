import {SmartComponent} from "smart-component-js";

class AiHeroCardComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AiHeroCardComponent";
        super(element, parentComponent, params);

        this.$el = $(element);
        this.init();
    }

    init() {
        this.$el.on('ai-error', () => {
            if (this.$el.parent().is('.owl-item')) {
                this.$el.parent().remove();
            } else {
                this.$el.remove();
            }

            this.$el.parents('.owl-carousel').trigger('reinit.owl.carousel');
        });
        this.$el.on('ai-ready', () => {
            let aiData = this.$el.data('aiData');
            if (aiData != null) {
                this.$el.html(`
                
                <div class="container">
                    <div class="row">
                        <div class="col-sm-9 col-lg-6">
                            <div class="hero-hp-item-content">
                                ${!aiData.title ? '' : `<div class="title">${aiData.title}</div>`}
                                ${!aiData.subtitle ? '' : `<div class="description">${aiData.subtitle}</div>`}
                                ${!aiData.url ? '' : `<a href="${aiData.url}" class="bordered-cta">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}</a>`}
                            </div>
                        </div>
                    </div>
                </div>
                
                `);

                if (!!aiData.image) {
                    this.$el.css('background-image', 'url("' + aiData.image + '")');
                }
            }
        });
    }
}

export default AiHeroCardComponent;