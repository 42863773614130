import {SmartComponent} from "smart-component-js";

class DateMaskingComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.separator = '-';

        this.input = this.element;
        this.init();
    }

    init() {
        this.input.addEventListener('keydown', (e) => {
            if (e.keyCode != 8 && e.keyCode != 46) {
                if (isNaN(e.key) && e.key != this.separator) {
                    e.preventDefault();
                }

                let len = this.input.value.length;

                // If we're at a particular place, let the user type the slash
                // i.e., 12/12/1212
                if (len != 3 || len != 6) {
                    if (e.key == this.separator) {
                        e.preventDefault();
                    }
                }

                // If they don't add the slash, do it for them...
                if (len === 4 || len === 7) {
                    this.input.value += this.separator;
                }
            }
        });

        this.input.addEventListener('blur', (e) => {
            this.validateDate();
        });
    }

    validateDate() {
        let split = this.input.value.split(this.separator);

        let day = split[2];
        let month = split[1];
        let year = split[0];
        let res = false;
        if (!!parseInt(year) && parseInt(year) < 1900) {
            year = 1900;
        }
        if (!!day && !!month && !!year) {
            let adultTime = (new Date()).getTime() - 567648000000;
            let adultDate = new Date(adultTime);
            let vDate = new Date();
            vDate.setDate(parseInt(day) || adultDate.getDate());
            vDate.setMonth((isNaN(month) || parseInt(month) < 0) ? adultDate.getMonth() : (parseInt(month) - 1));
            vDate.setFullYear(parseInt(year) || adultDate.getFullYear());

            res = vDate.getTime() < adultTime;

            for (; vDate.getTime() > adultTime;) {
                vDate = new Date(vDate.getFullYear() - 1, vDate.getMonth(), vDate.getDate());
            }

            let newVal = '';
            newVal += (vDate.getFullYear()) + '-';
            newVal += (("0" + (vDate.getMonth() + 1)).slice(-2)) + '-';
            newVal += (("0" + vDate.getDate()).slice(-2));
            this.input.value = newVal;
        }

        return res;
    }
}

export default DateMaskingComponent;
