import {SmartComponent} from "smart-component-js";
import IMUtils from "../business/IMUtils";

class NavigationComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "NavigationComponent";
        super(element, parentComponent, params);

        this.$flyingCta = false;
        this.$flyerCta = false;
        this.$el = $(element);

        this.isOpen = false;

        this.init();
    }

    init() {
        if ($('.the-flying-cta').length > 0) {
            this.$flyingCta = $('.the-flying-cta:eq(0)');
            if (this.$flyingCta.is('[clone-me]')) {
                this.$flyerCta = $('<div class="flyer-cta" style="display:none;">');
                this.$flyerCta.append(this.$flyingCta.clone());
                if (!!this.$flyingCta.attr('clone-me') && $(this.$flyingCta.attr('clone-me')).length > 0) {
                    this.$flyerCta.append($(this.$flyingCta.attr('clone-me')).clone());
                }
                this.$el.find('.navigation-wrapper').prepend(this.$flyerCta);
                this.loadChildComponents(this);
            } else {
                this.$flyerCta = $(`<div class="flyer-cta" style="display:none;"><a href="${this.$flyingCta.attr('href')}" target="${this.$flyingCta.attr('target')}" class="btn-mrng">${this.$flyingCta.text()}</a></div>`);
                this.$el.find('.navigation-wrapper').prepend(this.$flyerCta);
            }
        }

        $(document).scroll(() => {
            let top = $('html, body').scrollTop();
            this.$el.toggleClass('scrolled', top > 10);

            if (!!this.$flyingCta) {
                let flyCtaFly = true;
                if (!$('body').is('.ab-test-active')) {
                    flyCtaFly = this.$flyingCta.offset().top < ($('html, body').scrollTop() + parseInt(this.$el.height()));
                    this.$flyingCta.css('visibility', flyCtaFly ? 'hidden' : 'visible');
                } else {
                    this.$flyingCta.css('visibility', 'visible');
                }
                this.$flyerCta.toggle(flyCtaFly);
            }
        });

        console.log('NavigationComponent');

        $('.language-toggler').on('click', function (e) {
            e.preventDefault();
            $('.navigation-row ul#language-ul').css('display', 'block');
            $('.navigation-row ul#navigation-ul').css('display', 'none');
            $('.menu-toggler').click();
        });

        $('.menu-toggler').on('click', (e) => {
            $('body').toggleClass('nav-open');
            this.isOpen = $('body').hasClass('nav-open');
            if (!this.isOpen) {
                $('.navigation-row ul#language-ul').css('display', 'none');
                $('.navigation-row ul#navigation-ul').css('display', 'block');
                var event = new CustomEvent("mrng-menu-close", {"detail": ""});
                document.dispatchEvent(event);
            } else {
                if (!IMUtils.isMobile() && this.$el.find('.navigation-row')[0].scrollHeight - this.$el.find('.navigation-row').height() > 10) {
                    this.$el.find('.navigation-row').addClass('hasScrollbar');
                }

                var event = new CustomEvent("mrng-menu-open", {"detail": ""});
                document.dispatchEvent(event);
            }
            var $active = $('.menu-toggler-label.active');
            $('.menu-toggler-label').not($active).addClass('active');
            $active.removeClass('active');
            $('.navigation-row ul.active').removeClass('active');
            $('.navigation-row li.active').removeClass('active');
            $('.back-navigation').removeClass('active');
        });

        $('.has-sub-menu > a').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.navigation-row').find('.active').removeClass('active');
            var li = $(this).parents('li');
            li.addClass('active');
            var ul = $(this).parents('.navigation-row').find('li.active > ul');
            ul.addClass('active');
            $('.back-navigation').addClass('active');
        });

        $('.back-navigation').on('click', function (e) {
            var $ul = $('.navigation-row ul.active').last();
            var $li = $('.navigation-row li.has-sub-menu.active').last();
            $ul.removeClass('active');
            $li.removeClass('active');
            if ($('.navigation-row ul.active').length <= 0) {
                $('.back-navigation').removeClass('active');
            }
        });

        this.$el.click((e) => {
            e.stopPropagation();
        })

        $(document).on('click', 'body', (e) => {
            if (this.isOpen && !e.isTrigger) {
                $('.menu-toggler').click();
            }
        });
    }
}

export default NavigationComponent;