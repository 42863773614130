import {SmartComponent} from "smart-component-js";
import ScholarshipFormManager from "../business/ScholarshipFormManager";
import PopupManager from "../business/PopupManager";
import $ from "jquery";
import IMUtils from "../business/IMUtils";

class ScholarshipResetPwdCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipResetPwdCtrl";
        super(element, parentComponent, params);

        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.localeMap = {
            'it_IT': 'ita',
            'en_GB': 'eng',
            'fr_FR': 'fra',
            'es_ES': 'esp',
            'pt_BR': 'prt',
            'zh_CN': 'chi'
        }
        this.$el = $(element);

        this.serviceLocale = this.localeMap[this.locale];
        this.$pageWrapper = $('.reset-pwd-wrapper');
        $('body').addClass('negative-logo');
        this.init();
    }

    validate($el) {
        if (!$el) {
            $el = this.$formWrp.find('.required');
        }
        $el.each((i, el) => {
            let $input = $(el);
            if (!$input.val()) {
                $input.parents('.input:eq(0)').addClass('error');
            } else {
                $input.parents('.input:eq(0)').removeClass('error');
                $('.password-rule').removeClass('error');
            }
        });
    }

    printResetPwdTemplate() {
        let resetPwdHtml = `
            <div class="reset-pwd-banner">
                <div class="reset-pwd-title">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.title"]}</div>
                <div class="reset-pwd-subtitle">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.subtitle"]}</div>
            </div>
            <div class="reset-pwd-form">
                <div class="form-wrp">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-wrp-inputs">
                                    <div class="input input-12 no-margin">
                                        <label for="password">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.scholarship.field.password"]}</label>
                                        <input type="password" name="password" class="required" aria-required="true">
                                            <div class="password-strength">
                                                <div class="password-title">
                                                    <span class="strength-title">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.pwdStrength"]}</span>
                                                    <span class="strength-value"></span>
                                                </div>
                                                <div class="password-rules">
                                                    <div class="password-rule check-char">
                                                        <span class="icon-rule"></span>
                                                        <span>${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.checkChar"]}</span>
                                                    </div>
                                                    <div class="password-rule check-num">
                                                        <span class="icon-rule"></span>
                                                        <span>${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.checkNum"]}</span>
                                                    </div>
                                                    <div class="password-rule check-length">
                                                        <span class="icon-rule"></span>
                                                        <span>${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.checkLength"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="input input-12 no-margin">
                                        <label for="repeatpassword">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.scholarship.field.password"]}</label>
                                        <input type="password" name="repeatpassword" class="required" aria-required="true">
                                        <div class="error-message">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="required-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 buttons-right">
                                <button class="btn-mrng" data-go-next>${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.popup.scholarship.resetpwd.title"]}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;

        this.$pageWrapper.append(resetPwdHtml);
    }

    validatePasswordRules(pwdStrength) {
        let pwdStrengthLabel = (pwdStrength == 0)
            ? `${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.pwdVeryWeak"]}`
            : (pwdStrength == 1)
                ? `${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.pwdBitWeak"]}`
                : (pwdStrength == 2)
                    ? `${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.pwdWeak"]}`
                    : `${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.form.pwd.pwdStrong"]}`;

        $('.strength-value').html('').html(pwdStrengthLabel);
        $('.password-title').attr('data-strength', pwdStrength);
    }

    validateCreatePassword(avoidRepeat) {
        let $repeat = this.$el.find('[name="repeatpassword"]');
        let $pwd = this.$el.find('[name="password"]');
        let pwd = $pwd.val();
        let $checkLength = this.$el.find('.check-length');
        let $checkChar = this.$el.find('.check-char');
        let $checkNum = this.$el.find('.check-num');
        let pwdStrength = 3;

        if (pwd.length < 8) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkLength.addClass('error');
            pwdStrength--;
        } else {
            $checkLength.removeClass('error');
        }
        if (!/\d/.test(pwd)) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkNum.addClass('error');
            pwdStrength--;
        } else {
            $checkNum.removeClass('error');
        }
        if (!/[a-z]/.test(pwd) || !/[A-Z]/.test(pwd)) {
            $pwd.parents('.input:eq(0)').addClass('error');
            $checkChar.addClass('error');
            pwdStrength--;
        } else {
            $checkChar.removeClass('error');
        }
        if (!avoidRepeat && $repeat.val() != $pwd.val()) {
            $repeat.parents('.input:eq(0)').addClass('error');
        }

        this.validatePasswordRules(pwdStrength);

        if ((pwd.length < 8) || (!/\d/.test(pwd)) || (!/[a-z]/.test(pwd) || !/[A-Z]/.test(pwd)) || ($repeat.val() != $pwd.val())) {
            return false;
        }

        return true;
    }

    init() {
        let token = IMUtils.getParameter(document.location.href, 'token');

        if (!token) {
            document.location.href = KD_MARANGONI_PAGE_CONSTANTS.scholarshipHome;
            return false;
        }

        this.printResetPwdTemplate();
        this.$el.on('change, keyup', '.required', (e) => {
            this.validate($(e.currentTarget));
        });
        this.$el.on('change, keyup', '[name="password"]', (e) => {
            this.validateCreatePassword(true);
        });
        $('.btn-mrng').on('click', () => {
            if (this.validateCreatePassword()) {
                ScholarshipFormManager.changePassword(token, this.$el.find('[name="password"]').val()).then(() => {
                    PopupManager.showPopup("resetPasswordDone", () => {
                        document.location.href = KD_MARANGONI_PAGE_CONSTANTS.scholarshipHome;
                        return false;
                    });
                }, () => {
                    document.location.href = KD_MARANGONI_PAGE_CONSTANTS.scholarshipHome;
                    return false;
                })
            }
        });
    }
}

export default ScholarshipResetPwdCtrl;