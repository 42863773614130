import {SmartComponent} from "smart-component-js";
import ScholarshipFormManager from "../business/ScholarshipFormManager";
import IMUtils from "../business/IMUtils";

class ScholarshipOptinCtrl extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ScholarshipOptinCtrl";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.localeMap = {
            'it_IT': 'ita',
            'en_GB': 'eng',
            'fr_FR': 'fra',
            'es_ES': 'esp',
            'pt_BR': 'prt',
            'zh_CN': 'chi'
        }
        this.serviceLocale = this.localeMap[this.locale];

        this.init();
    }

    init() {
        let token = IMUtils.getParameter(document.location.href, 'token');
        let sinapto_campaign_id = IMUtils.getParameter(document.location.href, 'campaign_id');

        if (!token) {
            document.location.href = KD_MARANGONI_PAGE_CONSTANTS.scholarshipHome;
            return false;
        }

        ScholarshipFormManager.doOptin(token, sinapto_campaign_id).then((formUrl) => {
            let retailId = IMUtils.getParameter(document.location.href, 'retail_id');
            let completeUrl = formUrl;
            if (!!retailId && retailId.length > 0) {
                completeUrl = IMUtils.appendParameter(formUrl, 'retailid', retailId);
            }
            this.$el.find('#scholarship-hp').attr('href', completeUrl).text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.popup.scholarship.optin.restore"]);
        }, (msg) => {
            if ("no-optin" == msg) {
                this.$el.find('.incipit').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.already.incipit"]);
                this.$el.find('.title').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.already.title"]);
                this.$el.find('.subtitle').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.already.subtitle"]);
            } else {
                this.$el.find('.incipit').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.error.incipit"]);
                this.$el.find('.title').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.error.title"]);
                this.$el.find('.subtitle').text(KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.landing.optin.error.subtitle"]);
            }
            return false;
        });
    }
}

export default ScholarshipOptinCtrl;