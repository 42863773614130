import {SmartComponent} from "smart-component-js";
import ExternalTrackingManager from "../business/ExternalTrackingManager";

class VideoComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "VideoComponent";
        super(element, parentComponent, params);
        this.video = $(element);
        this.labelPlay = element.dataset.labelPlay;
        this.labelPause = element.dataset.labelPause;
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.init();
    }

    init() {

        console.log("VideoComponent");

        this.video.on('ai-ready', () => {
            let aiData = this.video.data('aiData');
            if (aiData != null && aiData.key && aiData.key.entityId) {
                this.video.find('.video-hp-nav li[data-campus-id="' + aiData.key.entityId + '"]').click();
                this.video.find('.video-hp-item[data-id="' + aiData.key.entityId + '"]').attr('selected-by-ai', true);
            }
        });

        $(".video-hp-container", this.video).owlCarousel({
            items: 1,
            rtl: this.isRtl,
            navs: false,
            dots: false,
            onTranslate: (item) => {
                $('.video-hp-nav li.active', this.video).removeClass('active');
                $('.video-hp-nav li', this.video).eq(item.item.index).addClass('active');
                var items = $(".video-hp-container .video-hp-item", this.video).length;
                $(".video-hp-container .video-hp-item video", this.video).remove();
                $(".video-hp-content-play").removeClass("pause");
                $(".video-hp-content-play").html(this.labelPlay);
                if (items % 2 != 0) items--;
                var half = items / 2;
                var single = 100 / half;
                var firstProgress = (item.item.index + 1) * single;
                var secondProgress = (item.item.index + 1 - half) * single;
                if ($(window).width() > 768) {
                    $('.video-progress .first-half .progress', this.video).css('height', firstProgress + '%');
                    $('.video-progress .second-half .progress', this.video).css('height', secondProgress + '%');
                } else {
                    $('.video-progress .first-half .progress', this.video).css('width', firstProgress + '%');
                    $('.video-progress .second-half .progress', this.video).css('width', secondProgress + '%');
                }
                $('.video-progress-progress', this.video).text((item.item.index + 1) + '/' + $(".video-hp-container .video-hp-item").length);

                this.trackSlideView(item.item.index);
            }
        });

        $('.video-progress-progress', this.video).text('1/' + $(".video-hp-container .video-hp-item").length);
        $('.video-progress .first-half .progress', this.video).css('width', (100 / (($(".video-hp-container .video-hp-item").length / 2))) + "%");
        $('.video-progress .first-half .progress', this.video).css('height', (100 / (($(".video-hp-container .video-hp-item").length / 2))) + "%");

        var nav = "";
        $(".video-hp-container .video-hp-item", this.video).each(function (index) {
            nav += `<li class="${index == 0 ? 'active' : ''}" data-campus-id="${$(this).data('id')}">${$(this).data('nav')}</li>`;
        });

        $('.video-hp-nav', this.video).html('<ul>' + nav + '</ul>');

        $(".video-hp-nav li", this.video).click((e) => {
            var index = $(e.currentTarget).index();
            $('li.active', $(e.currentTarget).parents('ul:first')).removeClass('active');
            $(e.currentTarget).addClass('active');
            $(".video-hp-container").trigger('to.owl.carousel', index);
        });

        $(".video-hp-content-play", this.video).click((e) => {
            e.preventDefault();
            let trackPlay = false;
            if ($('video', ($(e.currentTarget).parents('.video-hp-item:first'))).length > 0) {
                $(".video-hp-content-play").removeClass("pause");
                $(".video-hp-content-play").html(this.labelPlay);
                $(".video-hp-container .video-hp-item video", this.video).remove();
                $(".video-hp .video-hp-item video").remove();
                return;
            } else {
                trackPlay = true;
            }
            $(".video-hp-content-play").addClass("pause");
            $(".video-hp-content-play").html(this.labelPause);
            var video = ($(e.currentTarget).parents('.video-hp-item:first')).data('video');
            var html = '<video autoplay="autoplay"><source src="' + video + '" type="video/mp4"></video>';
            $(e.currentTarget).parents('.video-hp-item:first').append(html);

            if (trackPlay) {
                let title;
                if ($(e.currentTarget).parents('.video-hp-item').find('.video-hp-content-title').length > 0) {
                    title = $(e.currentTarget).parents('.video-hp-item').find('.video-hp-content-title').text();
                } else {
                    title = video;
                }
                ExternalTrackingManager.trackVideoPlay(title);

            }
        });

        $('.video-progress .arrow-top, .video-progress .arrow-down', this.video).click(function () {
            if ($(this).hasClass('arrow-down')) {
                $(".video-hp-container").trigger('next.owl.carousel');
            } else {
                $(".video-hp-container").trigger('prev.owl.carousel');
            }
        });

        $('.video-hp-container a:not(.video-hp-content-play)').click((e) => {
            e.preventDefault();
            let $el = $(e.currentTarget);
            this.trackSlideClick($el);
            setTimeout(() => {
                document.location.href = $el.attr('href');
            }, 200);
        });

        this.trackSlideView();
    }

    trackSlideView() {
        setTimeout(() => {
            let $slide = this.video.find(".video-hp-container").find('.owl-item.active');
            let index = $slide.index() + 1;
            let bg = $slide.find('.bg .desktop').css('background-image') || '';
            bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
            bg = bg.substr(bg.lastIndexOf('/') + 1);
            if ($slide.find('.video-hp-item').is('[selected-by-ai]')) {
                bg = 'AI-' + bg;
            }
            console.log(index);
            window.dataLayer.push({
                'event': 'promoView',
                'ecommerce': {
                    'promoView': {
                        'promotions': [{
                            'id': 'scuole-' + index,
                            'name': bg,
                            'creative': $slide.find('.video-hp-content-title').text(),
                            'position': index
                        }]
                    }
                }
            });
        }, 200);
    }

    trackSlideClick($clicked) {
        let $slide = $clicked.parents('.owl-item');
        let index = $slide.index();
        let bg = $slide.find('.bg .desktop').css('background-image') || '';
        bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
        bg = bg.substr(bg.lastIndexOf('/') + 1);

        if ($slide.find('.video-hp-item').is('[selected-by-ai]')) {
            bg = 'AI-' + bg;
        }
        window.dataLayer.push({
            'event': 'promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [{
                        'id': 'scuole-' + index,
                        'name': bg,
                        'creative': $slide.find('.video-hp-content-title').text(),
                        'position': index
                    }]
                }
            }
        });
    }
}

export default VideoComponent;