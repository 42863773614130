import {SmartComponent} from "smart-component-js";
import IframeManager from "../business/IframeManager";
import $ from "jquery";
import FormUtils from "../common/FormUtils";

class CallMeBackInnerComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CallMeBackInnerComponent";
        super(element, parentComponent, params);

        this.init();
    }

    init() {
        this.prefixField = intlTelInput($('#phone')[0], {
            preferredCountries: ["it", "es", "gb", "us", "fr"],
            initialCountry: KD_MARANGONI_SITE_CONSTANTS.IP_COUNTRY,
            separateDialCode: true
        });

        this.updatePrefixDependencies();
        $('#phone').on("countrychange", () => {
            this.updatePrefixDependencies();
        });

        $('.returnUrl').val(document.location.href);
        $('#phone').keyup(() => {
            $('.input').toggleClass('error', $('#phone').val().length < 4);
            this.updatePrefixDependencies();
        });
        $('[name="privacy-gdpr"]').change(() => {
            $('.ctm-checkbox').toggleClass('error', !$('[name="privacy-gdpr"]').is(':checked'));
        });
        $('form').submit((e) => {
            if (!$('form').find('[name="recaptchaToken"]').val()) {
                if ($('#phone').val().length < 4 || !$('[name="privacy-gdpr"]').is(':checked')) {
                    e.stopPropagation();
                    e.preventDefault();

                    $('[name="privacy-gdpr"]').change();
                    $('#phone').keyup();
                } else {
                    e.preventDefault();
                    FormUtils.doCaptcha($('form'), null, () => {
                        $('form').submit();
                        IframeManager.sendEventToParent(IframeManager.eventNames.callmeback);
                    });
                }
            }
        });

        IframeManager.sendHeightToParent();
    }

    updatePrefixDependencies() {
        $('[name="phone-code"]').val('+' + this.prefixField.getSelectedCountryData().dialCode);
        $('.country').val(this.prefixField.getSelectedCountryData().iso2.toUpperCase());

        let phone = $('[name="phone-code"]').val() + $('[name="phone_number"]').val();
        $('[name="phone"]').val(phone);
    }
}

export default CallMeBackInnerComponent;