import MD5 from "crypto-js/md5";
import RSVP from "rsvp";

class ScholarshipFormManager {
    constructor() {
        this.hostname = KD_MARANGONI_SITE_CONSTANTS.SINAPTO_ENDPOINT
    }

    getLoggedUser() {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/loggedstudent',
                method: "GET",
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                if (!!resp.data && resp.data.status == 'loggedin') {
                    resolve(resp.data.userdata);
                } else {
                    reject(false);
                }
            }, () => {
                reject(false);
            });
        });
    }

    /**
     *
     * @param formData.username
     * @returns Promise
     * resolve-1     user exists and activated
     * resolve-2    user exists need optin
     * reject           user not found
     */
    checkUsername(formData) {
        let email = formData.username;

        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/check',
                method: "POST",
                data: {
                    email: email
                }
            }).then((resp) => {
                if (resp.data.msg == 'user_exists_scholarship' || resp.data.msg == 'user_exists_aoldirect' || resp.data.msg == 'user_exists_aoldirect_created') {
                    resolve(resp.data.active ? 1 : 2);
                } else {
                    reject();
                }
            }, () => {
                reject();
            });
        });
    }

    sendOptin(formData) {
        let email = formData.username;

        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/sendactivationemail',
                method: "POST",
                data: {
                    email: email
                }
            }).then((resp) => {
                resolve(true);
            }, () => {
                reject(false);
            });
        });
    }

    sendForgotPassword(formData) {
        let email = formData.username;

        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/sendforgotpasswordemail',
                method: "POST",
                data: {
                    email: email
                }
            }).then((resp) => {
                resolve(true);
            }, () => {
                reject(false);
            });
        });
    }

    changePassword(token, password) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/changepasswordbytoken',
                method: "POST",
                data: {
                    token: token,
                    passwordmd5: MD5(password).toString()
                }
            }).then((resp) => {
                resolve(true);
            }, () => {
                reject(false);
            });
        });
    }

    doOptin(token, sinapto_campaign_id) {
        let data = JSON.stringify({
            activationtoken: token,
            idSite: KD_COMMON_CONSTANTS.SITE,
            locale: KD_COMMON_CONSTANTS.LOCALE,
            sc_id: sinapto_campaign_id
        });
        return new RSVP.Promise((resolve, reject) => {
            $.post({
                url: KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/form/optin',
                contentType: 'application/json; charset=utf-8',
                data: data
            }).then((resp) => {
                if (resp.body) {
                    resolve(resp.body);
                }
            }, (resp) => {
                reject(resp.message);
            });
        });
    }

    /**
     *
     * @param formData.username
     * @param formData.password
     * @returns Promise
     */
    login(formData) {
        let password = MD5(formData.password).toString();
        let email = formData.username;

        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/login',
                method: "POST",
                xhrFields: {
                    withCredentials: true
                },
                data: {
                    email: email,
                    passwordmd5: password
                }
            }).then((resp) => {
                resolve(resp.data.userdata);
            }, () => {
                reject(false);
            });
        });
    }

    register(formData) {
        return new RSVP.Promise((resolve, reject) => {

            let language = KD_COMMON_CONSTANTS.LOCALE.split("_")[0];
            if (language == 'pt') {
                language = 'pt-br';
            }


            let data = {
                "email": formData.username,
                "passwordmd5": MD5(formData.password).toString(),
                "firstname": formData.firstname,
                "lastname": formData.lastname,
                "birthday": formData.date,
                "prefix": formData.prefix,
                "phone": formData['phone_number'],
                "country": formData.country,
                "school": formData.school,
                "subarea": formData.subArea,
                "privacy1": formData.privacy == 'on',
                "privacy2": formData['privacyProfiling'] == 'on',
                "privacy3": formData['privacy-whatsapp'] == 'on',
                "campaign_id": formData.campaign_id,
                "retail_id": formData.retail_id,
                "language_code": language
            };
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/register',
                method: "POST",
                data: data,
                xhrFields: {
                    withCredentials: true
                }
            }).then((data) => {
                resolve(data);
            }, () => {
                resolve(false);
            });
        });
    }

    setData(formData) {
        return new RSVP.Promise((resolve, reject) => {
            let data = {
                "birthday": formData.date
            };
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/setdata',
                method: "PATCH",
                data: data,
                xhrFields: {
                    withCredentials: true
                }
            }).then((data) => {
                resolve(data);
            }, () => {
                resolve(false);
            });
        });
    }

    /**
     *
     * @param idScholarship
     * @returns Promise
     * resolve-false : no subscriptions for this scholarship
     * resolve-object: first subscription-object for this scholarship
     */
    checkSubscription(idScholarship) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course/all',
                method: "GET",
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                let subscriptions = resp.data;
                if (!!subscriptions && subscriptions.length > 0) {
                    subscriptions = subscriptions.filter(s => s.scholarshipid == idScholarship);
                }

                resolve(subscriptions.length > 0 ? subscriptions : false);
            }, () => {
                resolve(false);
            });
        });
    }

    /**
     *
     * @param idScholarship
     * @param idCourse
     * @returns Promise
     * resolve - uniqid user-course-scholarship relation
     * reject - error
     */
    subscribe(idScholarship, idCourse) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course',
                method: "POST",
                data: {
                    "scholarshipid": idScholarship,
                    "courseid": idCourse
                },
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                if (!!resp.data && !!resp.data.scholarship && !!resp.data.scholarship.uniqid) {
                    resolve(resp.data.scholarship.uniqid);
                } else {
                    reject(false);
                }
            }, () => {
                reject(false);
            });
        });
    }

    /**
     *
     * @param uniqid
     * @returns Promise
     * return-false: error
     * return-object: map, key is short_name, value is doc user details
     */
    getRelationDocs(uniqid) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course/' + uniqid + '/file/list?check_remote_files=1',
                method: "GET",
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                let res = {};

                //Qui vengono processati i documenti recuperati, tutti i documenti senza un link dropbox vengono esclusi
                //I documenti in stato APPROVED sono prioritari e scavalcano eventuali duplicati
                for (let groupName in resp.data) {
                    for (let docName in resp.data[groupName]) {
                        let doc = resp.data[groupName][docName];
                        if (!!doc['dropbox_link']) {
                            if ((!!doc.status && doc.status.toLowerCase() == 'approved') || !res[docName] || res[docName].status != 'approved') {
                                res[docName] = doc;
                            }
                        }
                    }
                }
                resolve(res);
            }, () => {
                resolve(false);
            });
        });
    }

    /**
     *
     * @param idRelation
     * @param documentName
     * @param $file
     * @returns Promise
     * resolve-true: upload successfull
     * resolve-false: error
     */
    setDocument(idRelation, documentName, $file) {
        let data = new FormData();
        data.append('filename', $file[0].files[0]);
        data.append('shortname', documentName);

        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course/' + idRelation + '/file',
                method: "POST",
                data: data,
                enctype: 'multipart/form-data',
                processData: false,  // Important!
                contentType: false,
                cache: false,
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                if (!resp || !resp.data || !resp.data.uploaded) {
                    resolve(false);
                } else {
                    resolve(resp.data.uploaded);
                }
            }, () => {
                resolve(false);
            });
        });
    }

    resendOptIn(email) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/sendactivationemail',
                method: "POST",
                data: {
                    email: email
                }
            }).then((data) => {
                resolve(data);
            }, () => {
                resolve(false);
            });
        });
    }

    askResetPassword(email) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/sendforgotpasswordemail',
                method: "POST",
                data: {
                    email: email
                }
            }).then((data) => {
                resolve(data);
            }, () => {
                resolve(false);
            });
        });
    }

    resetPassword(token, pwd) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/changepasswordbytoken',
                method: "POST",
                data: {
                    "token": token,
                    "passwordmd5": pwd
                }
            }).then((data) => {
                resolve(data);
            }, () => {
                resolve(false);
            });
        });
    }

    skilltest(uid) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course/' + uid + '/skill_test',
                method: "GET",
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                if (!!resp && !!resp.data) {
                    let completed = false;
                    let url = '';
                    for (let i in resp.data) {
                        if (!completed) {
                            if (!!resp.data[i] && !!resp.data[i]['result_data']) {
                                for (let j in resp.data[i]['result_data']) {
                                    if (!!resp.data[i]['result_data'][j] && !!resp.data[i]['result_data'][j]['finishedSurvey']) {
                                        completed = true;
                                    }
                                }
                            }
                            if (!!resp.data[i] && !!resp.data[i]['link_data']) {
                                url = resp.data[i]['link_data']
                            }
                        }
                    }
                    if (completed) {
                        resolve(true);
                    } else if (url.length > 0) {
                        resolve(url);
                    } else {
                        resolve(true);
                    }
                }
                reject(false);
            }, () => {
                reject(false);
            });
        });
    }

    closeSubscription(uniqid) {
        return new RSVP.Promise((resolve, reject) => {
            $.ajax({
                url: this.hostname + '/scholarship/ws/student/course/' + uniqid,
                method: "PATCH",
                data: {
                    "status": "submitted"
                },
                xhrFields: {
                    withCredentials: true
                }
            }).then((resp) => {
                if (!resp.errors || resp.errors.length == 0) {
                    resolve(true);
                } else {
                    reject(false);
                }
            }, () => {
                reject(false);
            });
        });
    }

    /**
     *
     * @param template
     * @returns {string} template html ready to append
     */
    printTemplate(template) {
        if (template == 'username') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">1</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.username"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-12 no-margin">
                            <label for="username">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.username"]}</label>
                            <input type="text" name="username" class="required" aria-required="true">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-away>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'login') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">2</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.login"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-12 no-margin">
                            <label for="password">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.password"]}</label>
                            <input type="password" name="password" class="required" aria-required="true">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 forgot-wrapper">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                    <a href="javascript:void(0);" data-reset-password class="additional-info">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.forgotPassword"]}</a>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-back>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'createuser') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">3</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.createuser"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-12 no-margin">
                            <label for="username">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.username"]}</label>
                            <input type="text" name="username" class="required" aria-required="true">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                        <div class="input input-12 no-margin">
                            <label for="password">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.password"]}</label>
                            <input type="password" name="password" class="required" aria-required="true">
                                <div class="password-strength">
                                    <div class="password-title">
                                        <span class="strength-title">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.pwdStrength"]}</span>
                                        <span class="strength-value"></span>
                                    </div>
                                    <div class="password-rules">
                                        <div class="password-rule check-char">
                                            <span class="icon-rule"></span>
                                            <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.checkChar"]}</span>
                                        </div>
                                        <div class="password-rule check-num">
                                            <span class="icon-rule"></span>
                                            <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.checkNum"]}</span>
                                        </div>
                                        <div class="password-rule check-length">
                                            <span class="icon-rule"></span>
                                            <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.pwd.checkLength"]}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="input input-12 no-margin">
                            <label for="repeatpassword">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.password"]}</label>
                            <input type="password" name="repeatpassword" class="required" aria-required="true">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-back>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'register') {
            return `
        
         <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">4</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.register"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-6 no-margin">
                            <label for="firstname">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.firstname"]}</label>
                            <input type="text" name="firstname" class="required" aria-required="true" maxlength="40" onkeydown="return /[a-z ]/i.test(event.key)">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                        <div class="input input-6 no-margin">
                            <label for="lastname">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.lastname"]}</label>
                            <input type="text" name="lastname" class="required" aria-required="true" maxlength="80" onkeydown="return /[a-z ]/i.test(event.key)">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                        <div class="input input-6 no-margin">
                            <label for="date">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.date"]}</label>
                            <input type="text" name="date" class="required" aria-required="true" value="" component="DateMaskingComponent">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                        <div class="input input-6">
                            <label for="phone">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.phone"]}</label>
                            <input type="hidden" name="phone-code">
                            <input type="tel" name="phone_number" id="phone" autocomplete="off" class="required only-numbers" maxlength="11" aria-required="true" onpaste="return false;" ondrop="return false;" autocomplete="off"/>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                        <div class="input input-6 no-margin select-container">
                            <label for="intake">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</label>
                            <select name="intake" id="intake" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</div>
                        </div>
                        <div class="input input-6 no-margin select-container">
                            <label for="school">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</label>
                            <select name="school" id="school" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</div>
                        </div>
                        <div class="input input-6 no-margin select-container">
                            <label for="degree">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</label>
                            <select name="degree" id="degree" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</div>
                        </div>
                        <div class="input input-6 no-margin select-container">
                            <label for="course">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</label>
                            <select name="course" id="course" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</div>
                        </div>
                        <div class="input input-12">
                            <div class="row align-items-end declarations">
                                <div class="col-sm-8">
                                    <label class="ctm-checkbox whatsapp">
                                        <div class="ctm-checkbox-box">
                                            <input type="checkbox" name="privacy-whatsapp" aria-required="true">
                                            <span></span>
                                        </div>
                                        <span style="width: auto;"><img src="/fe-web/img/marangoni/icons/whatsapp.png" class="ico-whatsapp"></span>
                                        <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.whatsapp"]}</span>
                                    </label>
                                    <label class="ctm-checkbox">
                                        <div class="ctm-checkbox-box">
                                            <input type="checkbox" name="privacy-gdpr" class="required" aria-required="true"/>
                                            <span></span>
                                        </div>
                                        <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.gdpr"]}</span>
                                    </label>
                                    <label class="ctm-checkbox">
                                        <div class="ctm-checkbox-box">
                                            <input type="checkbox" name="privacy"/>
                                            <span></span>
                                        </div>
                                        <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.privacy"]}</span>
                                    </label>
                                    <label class="ctm-checkbox">
                                        <div class="ctm-checkbox-box">
                                            <input type="checkbox" name="privacyProfiling"/>
                                            <span></span>
                                        </div>
                                        <span>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.form.profiling"]}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-back>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'fillinfo') {
            return `
        
         <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">3</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.fillinfo"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-6 no-margin">
                            <label for="date">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.date"]}</label>
                            <input type="text" name="date" class="required" aria-required="true" value="" component="DateMaskingComponent">
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.required"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'subscribe') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">3</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.subscribe"]}</span>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-wrp-inputs">
                        <div class="input input-12 no-margin select-container">
                            <label for="intake">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</label>
                            <select name="intake" id="intake" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderIntake"]}</div>
                        </div>
                        <div class="input input-12 no-margin select-container">
                            <label for="school">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</label>
                            <select name="school" id="school" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCampus"]}</div>
                        </div>
                        <div class="input input-12 no-margin select-container">
                            <label for="degree">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</label>
                            <select name="degree" id="degree" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderDegree"]}</div>
                        </div>
                        <div class="input input-12 no-margin select-container">
                            <label for="course">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</label>
                            <select name="course" id="course" class="required" aria-required="true">
                                <option value="null" class="default-option">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</option>
                            </select>
                            <div class="fake-single-option"></div>
                            <div class="error-message">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.placeholderCourse"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span class="required-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.field.requiredFields"]}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
<!--                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-back>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>-->
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'documents') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">4</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.documents"]}</span>
                    </h3>
                    <div class="document-description">
                        ${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.description.documents"]}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="document-wrapper"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng btn-outline-negative btn-go-back" data-go-back>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.prev"]}</button>
                    <button class="btn-mrng" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        } else if (template == 'skilltest') {
            return `
        
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="circle-title">
                        <span class="circle">5</span>
                        <span class="circle-label">${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.title.skilltest"]}</span>
                    </h3>
                    <div class="document-description">
                        ${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.description.skilltest"]}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <iframe id="skilltest"></iframe>
                </div>
            </div>
            <div class="row">
                <div class="col-12 buttons-right">
                    <button class="btn-mrng disabled" data-go-next>${KD_MARANGONI_PAGE_TRANSLATIONS["marangoni.scholarship.button.next"]}</button>
                </div>
            </div>
        </div>
        
            `;
        }
    }
}

export default new ScholarshipFormManager();
