import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class PromoterListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "PromoterListingComponent";
        super(element, parentComponent, params);

        // /api/1.0/search/promoter/list?area=1&site=54&locale=it_IT&city=Modena&country=981
        // /api/1.0/search/promoter/filter?site=54&locale=it_IT
        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/promoter/list';
        this.restFilterBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/promoter/filter';
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");
        this.page = 1;

        this.$el = $(element);

        this.filterValues = {
            area: '',
            country: '',
            city: ''
        };

        this.filterData = {};
        this.countryData = {};

        this.loadFilters();

        this.avoidFirstSearc = true;

        this.init();
    }

    onModalConfirm($modal, filterName, filterValue, filterLabel) {
        if (filterName == 'area') {
            this.filterValues.country = '';
            this.filterValues.city = '';
            this.updatedAreaFilters(filterValue);
        }
        if (filterName == 'country') {
            this.updatedCountryFilters();
        }
    }

    // onBeforeEraseFilters() {
    //     this.$el.find('#country-input').addClass('disabled');
    //     this.$el.find('#city-input').addClass('disabled');
    // }

    updatedCountryFilters() {
        this.filterValues.city = '';

        if (!this.filterValues.country) {
            let $cityTrigger = this.$el.find('#city-input .modal-trigger');
            $cityTrigger.text($cityTrigger.data('defaultText'));
            this.$el.find('#city-input').addClass('disabled');
        } else {
            let $fakeOptionWrp = this.$el.find('#modal_city .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_city select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            for (let i in this.countryData) {
                if (this.countryData[i].id == this.filterValues.country) {
                    for (let j in this.countryData[i].cityList) {
                        let city = this.countryData[i].cityList[j];
                        $fakeOptionWrp.append(`<div class="item" data-value="${city}">${city}</div>`);
                        $optionWrp.append(`<option value="${city}">${city}</option>`);
                    }
                }
            }

            this.$el.find('#city-input').removeClass('disabled');
        }
    }

    updatedAreaFilters() {
        this.filterValues.country = '';
        this.countryData = {};

        if (!this.filterValues.area) {
            this.$el.find('#country-input').addClass('disabled');
        } else {
            let $countryTrigger = this.$el.find('#country-input .modal-trigger');
            $countryTrigger.text($countryTrigger.data('defaultText'));
            let $fakeOptionWrp = this.$el.find('#modal_country .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_country select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            for (let i in this.filterData) {
                if (this.filterData[i].id == this.filterValues.area) {
                    for (let j in this.filterData[i].countryList) {
                        let country = this.filterData[i].countryList[j];
                        $fakeOptionWrp.append(`<div class="item" data-value="${country.id}">${country.label}</div>`);
                        $optionWrp.append(`<option value="${country.id}">${country.label}</option>`);
                    }

                    this.countryData = this.filterData[i].countryList;
                }
            }

            this.$el.find('#country-input').removeClass('disabled');
        }

        this.updatedCountryFilters();
    }

    createResultItem(item) {
        let desc = '';
        if (!!item.city) {
            desc = item.city;
        }
        if (!item.external) {
            if (!!item.country) {
                if (!!desc) {
                    desc += ' - ';
                }
                desc += item.country;
            }
        }
        if (!!item.promoterArea) {
            if (!!desc) {
                desc += ' - ';
            }
            desc += item.promoterArea;
        }

        return `
        
                <div class="col-lg-4 col-md-6 search-result-item--wrapper">
                    <div class="news-item">
                        <div class="text-wrapper">
                            ${!item.officeName ? '' : `<div class="title">${item.officeName}</div>`}
                            ${!item.name ? '' : `<div class="title">${item.name}</div>`}
                        </div>
                        ${!desc ? '' : `<div class="subtitle desc"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoterListing.cityPlaceholder"]}</span><div class="info-value">${desc}</div></div>`}
                        ${!item.address ? '' : `<div class="subtitle address"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoter.address"]}</span><div class="info-value">${item.address}</div></div>`}
                        ${!item.other ? '' : `<div class="subtitle other-info"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.other"]}</span><div class="info-value">${item.other}</div></div>`}
                        ${!item.email ? '' : `<div class="subtitle email"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoter.email"]}</span><div class="info-value">${item.email}</div></div>`}
                        ${!item.skypeId ? '' : `<div class="subtitle skype"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoter.skype"]}</span><div class="info-value">${item.skypeId}</div></div>`}
                        ${!item.telFax ? '' : `<div class="subtitle tel"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoter.telFax"]}</span><div class="info-value">${item.telFax}</div></div>`}
                        ${!item.links ? '' : `<div class="subtitle links"><span class="info-label">${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.promoter.links"]}</span><div class="info-value">${item.links}</div></div>`}
                    </div>
                </div>
        
        `;
    }

    // onBeforeAjaxCall(qs) {
    //     let $surname = this.$el.find('#surname');
    //     if ($surname.length > 0 && $surname.val().length > 0) {
    //         qs += '&keyword=' + encodeURI($surname.val());
    //     }
    //     return qs;
    // }

    loadFilters() {
        let qs = this.restFilterBaseUrl + `?site=${this.siteId}&locale=${this.locale}`;

        return youserxp.ajax(qs, {
            method: 'GET',
        }).then((resp) => {
            if (!!resp && !!resp.body && !!resp.body.promoterAreaList) {
                this.filterData = resp.body.promoterAreaList;

                let $fakeOptionWrp = this.$el.find('#modal_area .hoax-input-filter-options');
                let $optionWrp = this.$el.find('#modal_area select');
                $fakeOptionWrp.find('.item:not(.default-item)').remove();
                $optionWrp.find('option:not(.default-item)').remove();

                for (let i in resp.body.promoterAreaList) {
                    let area = resp.body.promoterAreaList[i];
                    $fakeOptionWrp.append(`<div class="item" data-value="${area.id}">${area.name}</div>`);
                    $optionWrp.append(`<option value="${area.id}">${area.name}</option>`);
                }
            }
        });
    }
}

export default PromoterListingComponent;