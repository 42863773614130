import {SmartComponent} from "smart-component-js";
import CookieManager from "../business/ImCookieManager";
import debounce from "debounce-promise";

class CommonComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "CommonComponent";
        super(element, parentComponent, params);
        this.body = $(element);
        this.isRtl = KD_COMMON_CONSTANTS.IS_RTL;
        this.init();

        this.loadChildComponents(this);

        if (!window.dontTouchMyCookie) {
            //Cancello il cookie solamente se era già in pagina al caricamento (quindi se lo inserisco in questa pagina non viene piallato)
            CookieManager.eraseGalleryCookie();
        }
    }

    init() {

        console.log('CommonComponent');

        $(document).ready(() => {
            window.dataLayer = window.dataLayer || [];


            $(".split-column").each((index, element) => {

                let totalElements = $(element).find('.yxpTabWrapper .yxpTabBlock').length;
                let percentage = 1 / totalElements * 100;
                $(element).prepend(`
                    <div class="video-progress">
                        <div class="arrow-top"></div>
                        <div class="first-half">
                            <div class="progress" style="width: ${percentage * 2}%; height: 20%;"></div>
                        </div>
                        <div class="video-progress-progress">1/${totalElements}</div>
                        <div class="second-half">
                            <div class="progress"></div>
                        </div>
                        <div class="arrow-down"></div>
                    </div>
                `);

                $(element).find(".video-progress .arrow-top").click((e) => {
                    let $el = $(e.currentTarget);
                    let index = 0;
                    let $active = $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock.yxpTabActive');
                    $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock').each((idx, obj) => {
                        if ($(obj).is($active)) {
                            index = idx;
                        }
                    });
                    if (index > 0) {
                        let $tab = $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock:nth-child(' + index + ')');
                        if ($tab.length > 0) {
                            $tab.click();
                        }
                    }
                });

                $(element).find(".video-progress .arrow-down").click((e) => {
                    let $el = $(e.currentTarget);
                    let index = 0;
                    let $active = $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock.yxpTabActive');
                    $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock').each((idx, obj) => {
                        if ($(obj).is($active)) {
                            index = idx + 1;
                        }
                    });
                    let $tab = $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock:nth-child(' + (index + 1) + ')');
                    if ($tab.length > 0) {
                        $tab.click();
                    }


                });

                $(element).find('.yxpTabWrapper .yxpTabBlock').click((e) => {
                    let $el = $(e.currentTarget);
                    let items = $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock').length;
                    let totalItems = items;
                    //if(items%2 != 0) items--;
                    var half = items / 2;
                    var single = 100 / half;
                    let index = 0;
                    $el.parents(".split-column").find('.yxpTabWrapper .yxpTabBlock').each((idx, obj) => {
                        if ($(obj).is($el)) {
                            index = idx;
                        }
                    });
                    var firstProgress = (index + 1) * single;
                    var secondProgress = (index + 1 - half) * single;
                    $(element).find(".video-progress .first-half .progress").width(firstProgress + "%");
                    $(element).find(".video-progress .second-half .progress").width(secondProgress + "%");
                    $(element).find(".video-progress .video-progress-progress").html((index + 1) + "/" + totalItems);
                    let $tabWrapper = $el.parents(".split-column").find('.yxpTabWrapper');
                    let offsetBox = ($tabWrapper.width() - 200) / 2;
                    if (index > 0) {
                        let scrollLeft = (index * 200) - offsetBox;
                        $tabWrapper.scrollLeft(scrollLeft);
                    } else {
                        $tabWrapper.scrollLeft(0);
                    }
                });


            })

            this.debouncedMarangoniOwl = debounce(this.initMarangoniCarousel, 200);

            $(".mrng-carousel-container").each((i, el) => {
                let $carouselContainer = $(el);

                this.initMarangoniCarousel($carouselContainer);
                $carouselContainer.on('reinit.owl.carousel', () => {
                    this.debouncedMarangoniOwl($carouselContainer, true);
                });
            });

            $('.grid-view-item').click(function (e) {
                let currTarget = $(e.currentTarget);
                let idx = parseInt(currTarget.data("index"));
                $(".mrng-carousel-container").trigger('to.owl.carousel', idx);
                $('.gallery-detail .switch-view').toggleClass("grid");
                if ($('.gallery-detail .switch-view').hasClass("grid")) {
                    $('.slide-view').hide();
                    $('.grid-view').show();
                } else {
                    $('.slide-view').show();
                    $('.grid-view').hide();
                }
            });

            $('.gallery-detail .switch-view').click(function (e) {
                $(e.currentTarget).toggleClass("grid");
                if ($(e.currentTarget).hasClass("grid")) {
                    $('.slide-view').hide();
                    $('.grid-view').show();
                } else {
                    $('.slide-view').show();
                    $('.grid-view').hide();
                }
            });


            function setFilterHeight() {
                $('.hoax-input-filter-options').each(function () {
                    var height = ($(this).parents('.hoax-input-filter')).height() - 60;
                    $(this).css({'height': height, 'max-height': height});
                });
            }

            $(document).on('click', '.hoax-input-filter-options .item', function () {
                var $parent = $(this).parents('.hoax-input-filter-options:first');
                var $parentFilter = $(this).parents('.hoax-input-filter');
                var value = $(this).data('value');
                var selectName = $parent.data('id');
                console.log(selectName + ' - ' + value);
                if ($('select[name="' + selectName + '"] option:selected').length > 0) {
                    $('select[name="' + selectName + '"] option:selected').prop("selected", false);
                }
                $('select[name="' + selectName + '"] option[value="' + value + '"]').prop('selected', true);
                console.log($('select[name="country"]').val());
                $('.item.selected', $parent).removeClass('selected');
                $(this).addClass('selected');
            });

            $('.modal-trigger').click(function () {
                var target = $(this).data('modal');
                $(target).addClass('active');
                $('body').addClass('modal-opened');
                setFilterHeight();
            });

            $('.modal-mrng .close').click(function () {
                ($(this).parents('.modal-mrng')).removeClass('active');
                $('body').removeClass('modal-opened');
            });

            $('.hoax-input-filter-checkbox .confirm').click(function () {
                var $checkWrapper = ($(this).parents('.hoax-input-filter-checkbox:first'));
                var $options = $('.hoax-input-filter-options', $checkWrapper);
                var modal = ($(this).parents('.modal-mrng')).removeClass('active');
                var id = $options.data('id');
                $('body').removeClass('modal-opened');
                var result = "";
                var i = 0;
                $("input[type='checkbox']", $options).each(function () {
                    if ($(this).is(':checked')) {
                        var $parents = ($(this).parents('label:first'));
                        result += (i > 0 ? ', ' : '') + $parents.text()
                        i++;
                    }
                });
                if (result != "") {
                    $('.input-placeholder-value[data-id="' + id + '"]').text(result);
                } else {
                    $('.input-placeholder-value[data-id="' + id + '"]').text($('.input-placeholder-value[data-id="' + id + '"]').data('default-text'));
                }
            });

            $('.hoax-input-filter-select .confirm').click(function () {
                var $selectWrapper = ($(this).parents('.hoax-input-filter-select:first'));
                var $options = $('.hoax-input-filter-options', $selectWrapper);
                // var id = $options.data('id');
                var modal = ($(this).parents('.modal-mrng')).removeClass('active');
                $('body').removeClass('modal-opened');
                // var $select = $('select option:selected', $selectWrapper ).text();
                // $('.input-placeholder-value[data-id="'+id+'"]').text($select);
                // console.log('.input-placeholder-value[data-id="'+id+'"]');
            });

            $(".mrng-carousel-controllers-arrows button").click(function () {
                var parent = $('.owl-carousel', ($(this).parents('section:first')));
                if ($(this).hasClass('prev')) {
                    parent.trigger('prev.owl.carousel');
                } else {
                    parent.trigger('next.owl.carousel');
                }
            });

            if ($('.hero-hp-container').length > 0) {
                this.initHeroOwl();
                this.debouncedHeroOwl = debounce(this.initHeroOwl, 50);

                $(".hero-hp-container").on('reinit.owl.carousel', () => {
                    this.debouncedHeroOwl(true);
                });
            }

            $(".tab-corso a").click(function (e) {
                e.preventDefault();
                var target = $(this).attr('href');
                $(".tab-corso li.active").removeClass('active');
                ($(this).parents('li:first')).addClass('active');
                $(".tab-corso-item.active").removeClass('active');
                $(".tab-corso-item" + target).addClass('active').trigger('tab-corso-opened');
            });

            $('.button-tab a').click(function (e) {
                e.preventDefault();
                $('li.active', $(this).parents('.button-tab:first')).removeClass('active');
                ($(this).parents('li:first')).addClass('active');
                var target = $(this).attr('href');
                $('.tab-content.active', $(target).parents('.tabs-container:first')).removeClass('active');
                $(target).addClass('active');
            });

            $(".list-tab-link a").click(function (e) {
                e.preventDefault();
                var target = $(this).attr('href');
                $(".list-tab-link li.active").removeClass('active');
                ($(this).parents('li:first')).addClass('active');
                $(".list-tab-content-item.open").removeClass('open');
                $(".list-tab-content-item" + target).addClass('open');
            });

            $(".collapse-item .collapse-item-trigger").click(function () {
                var $parent = $(this).parents('.collapse-item:first');
                $parent.toggleClass('open');
                $('.collapse-item-content', $parent).stop().slideToggle(300);
            });

            $(".note-trigger").click(function () {
                $(this).siblings('.note-text').slideToggle(500);
            });

            if ($(".might-interested-in .button-container").length > 0) {
                if ($(".might-interested-in.black .button-container").length > 0) {
                    $(".might-interested-in.black .button-container").owlCarousel({
                        items: 3,
                        rtl: this.isRtl,
                        margin: 36,
                        responsive: {
                            0: {
                                items: 1.4,
                                margin: 36
                            },
                            768: {
                                items: 2,
                                margin: 36
                            },
                            1200: {
                                items: 3,
                                margin: 36
                            }
                        }
                    });
                } else {
                    $(".might-interested-in .button-container").owlCarousel({
                        items: 4,
                        rtl: this.isRtl,
                        margin: 36,
                        responsive: {
                            0: {
                                items: 1,
                                margin: 36
                            },
                            768: {
                                items: 3,
                                margin: 36
                            },
                            1200: {
                                items: 4,
                                margin: 36
                            }
                        }
                    });
                }
            }


            $(".might-interested-in .title-container .arrows button").click(function () {
                var parent = $('.owl-carousel', ($(this).parents('.might-interested-in:first')));
                if ($(this).hasClass('prev')) {
                    parent.trigger('prev.owl.carousel');
                } else {
                    parent.trigger('next.owl.carousel');
                }
            });

            $(".might-interested-in .arrows button").click(function () {
                var parent = $('.owl-carousel', ($(this).parents('.might-interested-in:first')));
                if ($(this).hasClass('prev')) {
                    parent.trigger('prev.owl.carousel');
                } else {
                    parent.trigger('next.owl.carousel');
                }
            });

            $('body').on('click', '.button-tab a', function (e) {
                e.preventDefault();
                $('li.active', $(this).parents('.button-tab:first')).removeClass('active');
                ($(this).parents('li:first')).addClass('active');
                var target = $(this).attr('href');
                $('.tab-content.active', $(target).parents('.tabs-container:first')).removeClass('active');
                $(target).addClass('active');
            });

            $(".number-list").each(function () {

                var half = Math.ceil($(this).find('li').length / 2);

                $(this).children().each(function (index) {
                    var indextext = (index + 1) < 10 ? ('0' + (index + 1)) : (index + 1);
                    $(this).html("<div>" + $(this).html() + "</div>");
                    $(this).prepend('<span class="li-number">' + indextext + '</span>');
                });

                if ($(this).hasClass('split-list')) {

                    var prev = 0;
                    var total = 0;
                    var parent = $(this);

                    $(this).children().each(function (index) {
                        var newindex = prev + (index + 1);
                        $(this).css({'order': newindex});
                        parent.find('li').eq(index + half).css({'order': newindex + 1});
                        if (index > half) return;
                    });

                }
            });

            if ($(".courses-carousel").length > 0) {
                $(".courses-carousel").owlCarousel({
                    items: 1,
                    rtl: this.isRtl
                });
            }

            if ($(".picasso-gallery").length > 0) {
                $(".picasso-gallery").owlCarousel({
                    items: 1,
                    rtl: this.isRtl
                });
            }

            if ($(".picasso-gallery-mobile").length > 0) {
                $(".picasso-gallery-mobile").owlCarousel({
                    items: 1,
                    margin: 15,
                    rtl: this.isRtl
                });
            }


        });

    }

    initMarangoniCarousel($carouselContainer, destroyFirst) {
        if (!!destroyFirst) {
            $carouselContainer.trigger('destroy.owl.carousel');
        }

        var count = $(".mrng-carousel-item", $carouselContainer).length;
        var $parent = $carouselContainer.parents('.mrng-carousel:first');
        $('.mrng-carousel-controllers-counters span', $parent).text(count);
        var dataItems = $parent.data('items');

        let parents = $carouselContainer.parents(".gallery-detail");
        if (parents.length > 0) {
            $carouselContainer.on('changed.owl.carousel', function (e) {
                $('.slider-nav span').html(e.item.index + 1);
            })
        }

        $carouselContainer.owlCarousel({
            items: dataItems !== undefined ? dataItems : 3,
            margin: 30,
            rtl: this.isRtl,
            smartSpeed:0,
            responsive: {
                0: {
                    items: 1,
                    margin: 10,

                },
                768: {
                    items: dataItems !== undefined ? dataItems : 3,
                    margin: 30
                }
            }


        });

    }

    initHeroOwl(destroyFirst) {
        if (!!destroyFirst) {
            $('.hero-hp-container').trigger('destroy.owl.carousel');
        }
        this.trackSlideView($('.hero-hp-container .hero-hp-item:eq(0)'));

        $(".hero-hp-container").owlCarousel({
            items: 1,
            rtl: this.isRtl,
            autoplay: true,
            loop: true,
            autoplayTimeout: 5000,
            animateOut: 'fadeOut',
            mouseDrag: false,
            onTranslated: (e) => {
                let $slide = $(e.target).find('.owl-item.active .hero-hp-item');
                this.trackSlideView($slide);
            },
            dots: true
        });

        $(document).on("click", ".hero-hp-container .hero-hp-item[data-href]", (e) => {
            e.preventDefault();
            let $el = $(e.currentTarget);
            this.trackSlideClick($el);
            setTimeout(() => {
                document.location.href = $el.data('href');
            }, 200);
        });
    }

    trackSlideView($slide) {
        if ($slide.is('[data-ai]') && 'COMPLETED' != $slide.data('aiStatus')) {
            $slide.on('ai-ready', () => {
                setTimeout(() => {
                    this.trackSlideView($slide);
                }, 200)
            });
        } else {
            let bg = $slide.css('background-image') || '';
            bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
            bg = bg.substr(bg.lastIndexOf('/') + 1);
            if ($slide.is('[data-ai]')) {
                bg = 'AI-' + bg;
            }
            let slideNumber = $slide.data('cardIndex');
            console.log(slideNumber);
            window.dataLayer.push({
                'event': 'promoView',
                'ecommerce': {
                    'promoView': {
                        'promotions': [{
                            'id': 'slide-' + slideNumber,
                            'name': bg,
                            'creative': $slide.find('.title').text(),
                            'position': slideNumber
                        }]
                    }
                }
            });
        }
    }

    trackSlideClick($slide) {
        let slideNumber = $slide.data('cardIndex');
        let bg = $slide.css('background-image');
        bg = bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
        bg = bg.substr(bg.lastIndexOf('/') + 1);
        if ($slide.is('[data-ai]')) {
            bg = 'AI-' + bg;
        }


        window.dataLayer.push({
            'event': 'promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [{
                        'id': 'slide-' + slideNumber,
                        'name': bg,
                        'creative': $slide.find('.title').text(),
                        'position': slideNumber
                    }]
                }
            }
        });
    }
}

export default CommonComponent;