import {SmartComponent} from "smart-component-js";
import IframeManager from "../business/IframeManager";

class IframeThankYouComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "IframeThankYouComponent";
        super(element, parentComponent, params);

        this.init();
    }

    init() {
        IframeManager.sendHeightToParent();
        IframeManager.sendEventToParent(IframeManager.eventNames.skilltest);
    }
}

export default IframeThankYouComponent;