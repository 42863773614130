import {SmartComponent} from "smart-component-js";

class ClampComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "ClampComponent";
        super(element, parentComponent, params);

        this.$el = $(element);

        this.height = this.$el.data('maxHeight');
        this.realHeight = false;
        this.inited = false;

        this.open = true;

        this.init();
    }

    init() {
        if (this.$el.is(':visible')) {
            this.startClamping();
        }

        this.$el.parents('.tab-corso-item').on('tab-corso-opened', () => {
            if (!this.inited) {
                this.startClamping();
            }
        });
    }

    startClamping() {
        this.inited = true;
        this.realHeight = this.$el.height();
        if (!!this.height && this.realHeight > this.height) {
            this.realHeight = this.$el.height() + 30;

            this.$el.addClass('clamped');
            this.$el.append(`
            <span class="declamperizer">
                ${KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]}
            </span>
            `);

            this.toggleClamp();
            this.$el.find('.declamperizer').click(() => {
                this.toggleClamp();
            });
        }
    }

    toggleClamp() {
        this.open = !this.open;
        this.$el.toggleClass('declamped', this.open);
        if (this.open) {
            this.$el.css('max-height', this.realHeight + 'px');
            this.$el.find('.declamperizer').text(KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.close"]);
        } else {
            this.$el.css('max-height', this.height + 'px');
            this.$el.find('.declamperizer').text(KD_MARANGONI_SITE_TRANSLATIONS["marangoni.common.readMore"]);
        }
    }
}

export default ClampComponent;