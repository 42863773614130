import {SmartComponent} from "smart-component-js";
import $ from "jquery";

class HighlightContainerComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "HighlightContainerComponent";
        super(element, parentComponent, params);

        this.element = element;
        this.$el = $(element);
        if (this.$el.find('#showAllHighlights').length > 0) {
            this.$el.find('#showAllHighlights').click(() => {
                this.$el.find('#showAllHighlights').hide();
                this.$el.find('.high-el:hidden').show();
            });
        }

        this.init();
    }

    initMrgnCarousel(){
        this.mrgnCarousel = this.element.querySelectorAll('.mrng-carousel .owl-carousel .mrng-carousel-item');
        this.controllers = this.element.querySelector('.mrng-carousel-controllers');
        const mediaQuery = window.matchMedia('(min-width: 768px)')

        if(this.mrgnCarousel.length <= 3 && mediaQuery.matches) {
            this.controllers.remove();
        }

        if(this.mrgnCarousel.length >= 2 && !mediaQuery.matches) {
            this.controllers.show();
        }
    }

    init(){
        this.initMrgnCarousel();
    }
}

export default HighlightContainerComponent;