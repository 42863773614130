import BaseListingComponent from "./BaseListingComponent";

import $ from "jquery";

class AlumniListingComponent extends BaseListingComponent {
    constructor(element, parentComponent, params) {
        params = params || {};
        params.className = "AlumniListingComponent";
        super(element, parentComponent, params);

        this.restBaseUrl = KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + '/search/alumni/list';
        this.siteId = KD_COMMON_CONSTANTS.SITE;
        this.locale = KD_COMMON_CONSTANTS.LOCALE;
        this.jsLocale = this.locale.replace("_", "-");
        this.page = 1;

        this.$el = $(element);

        this.filterValues = {
            seniorYear: '',
            country: '',
            campus: '',
            course: '',
            area: ''
        };

        this.coursesByArea = {};

        this.init();
    }

    onModalConfirm($modal, filterName, filterValue, filterLabel) {
        if (filterName == 'campus') {
            this.updateAreaCourses(filterValue);
        }
        if (filterName == 'area') {
            this.updateCourses(filterValue);
        }
    }

    onBeforeEraseFilters() {
        this.$el.find('#area-input').addClass('disabled');
        this.$el.find('#course-input').addClass('disabled');
        this.$el.find('#surname').val('');
    }

    updateAreaCourses(campus) {
        this.filterValues.area = '';
        this.filterValues.course = '';
        this.coursesByArea = {};

        let $areaTrigger = this.$el.find('#area-input .modal-trigger');
        $areaTrigger.text($areaTrigger.data('defaultText'));
        let $courseTrigger = this.$el.find('#course-input .modal-trigger');
        $courseTrigger.text($courseTrigger.data('defaultText'));

        if (!campus || campus == '') {
            this.$el.find('#area-input').addClass('disabled');
            this.$el.find('#course-input').addClass('disabled');
        } else {
            this.loadAreaCourses(campus).then((resp) => {
                if (!!resp.body && resp.body.length > 0) {
                    let $fakeOptionWrp = this.$el.find('#modal_area .hoax-input-filter-options');
                    let $optionWrp = this.$el.find('#modal_area select');
                    $fakeOptionWrp.find('.item:not(.default-item)').remove();
                    $optionWrp.find('option:not(.default-item)').remove();

                    for (let i = 0; i < resp.body.length; i++) {
                        let area = resp.body[i].area;
                        let courses = resp.body[i].courses;
                        this.coursesByArea[area.id] = courses;

                        $fakeOptionWrp.append(`<div class="item" data-value="${area.id}">${area.title}</div>`);
                        $optionWrp.append(`<option value="${area.id}">${area.title}</option>`);
                    }
                    this.$el.find('#area-input').removeClass('disabled');
                }
            });
        }
    }

    loadAreaCourses(campus) {
        let qs = `site=${this.siteId}&locale=${this.locale}&campus=${campus}`;

        return youserxp.ajax(KD_MARANGONI_SITE_CONSTANTS.API_ENDPOINT + `/search/alumni/area/course?${qs}`, {
            method: 'GET',
        });
    }

    updateCourses(area) {
        this.filterValues.course = '';

        let $courseTrigger = this.$el.find('#course-input .modal-trigger');
        $courseTrigger.text($courseTrigger.data('defaultText'));

        if (!area || area == '') {
            this.$el.find('#course-input').addClass('disabled');
        } else {
            let courses = this.coursesByArea[area];

            let $fakeOptionWrp = this.$el.find('#modal_course .hoax-input-filter-options');
            let $optionWrp = this.$el.find('#modal_course select');
            $fakeOptionWrp.find('.item:not(.default-item)').remove();
            $optionWrp.find('option:not(.default-item)').remove();

            for (let i = 0; i < courses.length; i++) {
                let course = courses[i];

                $fakeOptionWrp.append(`<div class="item" data-value="${course.id}">${course.title}</div>`);
                $optionWrp.append(`<option value="${course.id}">${course.title}</option>`);
            }

            this.$el.find('#course-input').removeClass('disabled');
        }
    }

    createResultItem(item) {
        if (!item.thumbImage) {
            item.thumbImage = '';
        }
        return `
            <a class="alumni-result-item" href="${item.url}">
                <div class="thumb-image" style="background-image: url('${item.thumbImage}');"></div>
                <div class="text-wrapper">
                    <span class="area">${item.area || 'nome area'}</span>
                    <span class="name">${item.name} ${item.surname}</span>
                </div>
            </a>
        `;
    }

    onBeforeAjaxCall(qs) {
        let $surname = this.$el.find('#surname');
        if ($surname.length > 0 && $surname.val().length > 0) {
            qs += '&keyword=' + encodeURI($surname.val());
        }
        return qs;
    }
}

export default AlumniListingComponent;