class IframeManager {
    constructor() {
        this.eventNames = {
            skilltest: 'iframe-skilltest-completed',
            callmeback: 'iframe-cmb-submitted'
        };
    }

    waitForIframeEvent(eventName, callback) {
        let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        let eventer = window[eventMethod];
        let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent, function (e) {
            let msg = e.data || e.message;
            msg = msg + '';
            if (!!msg && msg.indexOf(eventName) == 0) {
                callback();
            }
        });
    }

    sendEventToParent(eventName) {
        parent.postMessage(eventName, "*");
    }

    sendHeightToParent() {
        let containers = document.getElementsByClassName('container');
        for (var i = 0; i < containers.length; i++) {
            containers[i].style.minHeight = '0';
        }
        setInterval(function () {
            var body = document.body;

            var height = Math.max(body.scrollHeight, body.offsetHeight);
            var styles = window.getComputedStyle(body);
            var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

            parent.postMessage("iframe-height-" + Math.ceil(height + margin), "*");
        }, 500);
    }

    adjustIframeHeightOnContent(id) {
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        eventer(messageEvent, function (e) {
            let msg = e.data || e.message;
            msg = msg + '';
            if (!!msg && msg.indexOf('iframe-height-') == 0) {
                document.getElementById(id).height = msg.replace('iframe-height-', '') + 'px';
            }
        });
    }
}

export default new IframeManager();
